import React from 'react';
import MessageExample from './MessageExample';

export function promoPopup(popupFn, trackFn) {
  if (!popupFn || !trackFn)
    throw new Error('popupFn and trackFn are required.');
  trackFn('promo.show.messaging');
  return popupFn({
    layout: 'promo',
    title: 'Messages are a premium feature',
    contents: <MessageExample />,
    buttons: [
      {
        label: 'Start a chat',
        primary: true,
        callback: () => {
          trackFn('promo.chat.messaging');
          window.HubSpotConversations?.widget?.open();
        },
      },
    ],
    links: [{ label: 'No thanks.', callback: () => {} }],
  });
}

import MuiButton from '@mui/material/Button';
import { darken, lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import {
  black,
  darkGray,
  lightTan,
  primary,
  red500,
  teal900,
  white,
} from '../../core/colors';
import CircularProgress from '../CircularProgress/CircularProgress';
import FAIcon from '../Icon/FAIcon';

const useStyles = makeStyles({
  fontBold: {
    fontWeight: 'bold',
  },
  fontSmoothing: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  circularProgress: {
    color: 'inherit',
  },
});

const useButtonColors = makeStyles({
  primary: ({ inverted }) => ({
    backgroundColor: inverted ? white : primary,
    color: inverted ? primary : white,
    '&:hover': {
      backgroundColor: darken(inverted ? white : primary, 0.1),
    },
    '&:disabled': {
      color: inverted ? darken(white) : white,
    },
  }),
  teal: ({ inverted }) => ({
    backgroundColor: inverted ? white : teal900,
    color: ({ fontColor }) => (inverted ? fontColor || teal900 : white),
    '&:hover': {
      backgroundColor: darken(inverted ? white : teal900, 0.1),
    },
    '&:disabled': {
      color: inverted ? darken(white) : white,
    },
  }),
  red: ({ inverted }) => ({
    backgroundColor: inverted ? white : red500,
    color: inverted ? red500 : white,
    '&:hover': {
      backgroundColor: darken(inverted ? white : red500, 0.1),
    },
    '&:disabled': {
      color: inverted ? darken(white) : white,
    },
  }),
  lightTan: ({ inverted }) => ({
    backgroundColor: inverted ? darkGray : lightTan,
    color: inverted ? lightTan : darkGray,
    '&:hover': {
      backgroundColor: darken(lightTan, 0.1),
    },
    '&:disabled': {
      color: inverted ? darken(white) : white,
    },
  }),
  darkGray: ({ inverted }) => ({
    color: inverted ? white : darkGray,
    '&:hover': {
      backgroundColor: darken(inverted ? darkGray : white, 0.1),
    },
    '&:disabled': {
      color: inverted ? darken(darkGray) : darkGray,
    },
  }),
  white: ({ fontColor, inverted }) => ({
    color: inverted ? black : (fontColor ?? black),
    backgroundColor: inverted ? (fontColor ?? black) : white,
    '&:hover': {
      backgroundColor: lighten(inverted ? (fontColor ?? white) : black, 0.98),
    },
    '&:disabled': {
      color: inverted ? darken(darkGray) : darkGray,
    },
  }),
});

const useTextColors = makeStyles({
  root: ({ fontColor = darkGray }) => ({
    color: fontColor,
    '&:hover': {
      color: darken(fontColor, 0.1),
    },
    '&:disabled': {
      color: lighten(fontColor, 0.1),
    },
  }),
});

const useSizes = makeStyles({
  tiny: {
    padding: 0,
    fontSize: 12,
  },
  huge: {
    minHeight: 90,
    padding: '25px 30px',
    fontSize: 20,
  },
  normal: {
    minHeight: 50,
    padding: '12px 20px',
    fontSize: 16,
  },
  large: {
    minHeight: 70,
    padding: '20px 25px',
    fontSize: 16,
  },
});

const useSquared = makeStyles({
  squared: {
    minWidth: (props) => props.squareSize,
    width: (props) => props.squareSize,
    height: (props) => props.squareSize,
    verticalAlign: 'top',
    marginRight: 20,
    padding: 0,
  },
});

function Button({
  variant = 'contained',
  color = 'primary',
  classes = {},
  size = 'large',
  startIcon,
  fontColor,
  fontSmoothing,
  fontBold,
  label,
  backgroundColor,
  style,
  square,
  squareSize = 140,
  labelStyle,
  submitting,
  ...other
}) {
  const text = useTextColors({ fontColor });
  const colors = useButtonColors({
    fontColor,
    inverted: variant === 'inverted',
  });
  const sizes = useSizes();
  const s = useStyles();
  const squared = useSquared({ squareSize });
  const styles = {
    ...(style || {}),
    ...(backgroundColor ? { backgroundColor } : {}),
  };
  const colorClassName = variant === 'text' ? text.root : colors[color];

  const rootClassname = classes && classes.root;
  const sizeClassName = sizes[size];

  const startIconElement =
    typeof startIcon === 'string' ? (
      <FAIcon size={14} icon={startIcon} />
    ) : (
      startIcon
    );

  const actualFontSmoothing =
    typeof fontSmoothing === 'boolean' ? fontSmoothing : variant !== 'text';

  const actualFontBold =
    typeof fontBold === 'boolean' ? fontBold : variant !== 'text';

  return (
    <MuiButton
      {...other}
      variant={variant === 'text' ? 'text' : 'contained'}
      color={colorClassName ? undefined : color}
      size={sizeClassName ? undefined : size}
      startIcon={startIconElement}
      style={styles}
      classes={{
        ...classes,
        root: cx({
          [s.fontBold]: actualFontBold,
          [s.fontSmoothing]: actualFontSmoothing,
          [rootClassname]: !!rootClassname,
          [colorClassName]: !!colorClassName,
          [sizeClassName]: !!sizeClassName,
          [squared.squared]: !!square,
        }),
      }}
    >
      {submitting ? (
        <CircularProgress
          color="inherit"
          size={14}
          thickness={5}
          classes={{
            root: s.circularProgress,
          }}
        />
      ) : (
        label || other.children
      )}
    </MuiButton>
  );
}

export default Button;

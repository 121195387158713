import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import MemberRequest from './MemberRequest';
import { approveGroupMember, denyGroupMember } from '../../actions/group';
import Button from '../Button/Button';

const pageSize = 3;

function MemberRequests({
  memberRequests,
  approveGroupMember,
  denyGroupMember,
  onApprove,
  onDeny,
}) {
  const [pageNum, setPageNum] = useState(0);

  const first = pageNum * pageSize;
  const last = first + pageSize;
  const hasPrevious = first > 0;
  const hasNext = last < memberRequests.length;
  const page = memberRequests.slice(first, last);

  const handleApprove = useCallback(async (m) => {
    await approveGroupMember(m);
    if (onApprove) onApprove(m);
  }, []);

  const handleDeny = useCallback(async (m) => {
    await denyGroupMember(m);
    if (onDeny) onDeny(m);
  }, []);

  return (
    <div>
      {(hasPrevious || hasNext) && (
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setPageNum(pageNum - 1)}
            size="mini"
            disabled={!hasPrevious}
          >
            Previous
          </Button>
          <Button
            onClick={() => setPageNum(pageNum + 1)}
            size="mini"
            disabled={!hasNext}
          >
            Next
          </Button>
        </div>
      )}
      {page.map((m) => (
        <MemberRequest
          key={m.id}
          user={m.user}
          membersSet="this group"
          onApprove={() => handleApprove(m)}
          onDeny={() => handleDeny(m)}
        />
      ))}
    </div>
  );
}

MemberRequests = connect(undefined, {
  approveGroupMember,
  denyGroupMember,
})(MemberRequests);

export default MemberRequests;

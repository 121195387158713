import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { isEmailValid, isPhoneValid, normalizeSpace } from '../../core/util';
import { TextField, PhoneInput, Checkbox, PasswordText } from '../FormAdapters';
import Button from '../Button';
import Link from '../Link';
import AuthLayout from '../AuthLayout';
import { useApp } from 'hooks/useAppContext';
import { borderColor, darkGray, sand } from '../../core/colors';
import { formatAuthPath, isValidPassword } from '../../core/auth';
import { getTracking } from '../../core/tracking';
import { signup } from '../../core/login';
import { policies } from '../../core/agreements';
import history from '../../core/history';
import {
  ErrEmailAlreadyExists,
  ErrPhoneAlreadyExists,
} from '../../core/address';
import { updateUser } from '../../actions/user';
import { joinNetwork } from '../../actions/internalNetwork';
import { addGroupMember } from '../../actions/group';
import { hasOnlyLetters } from '../../reducers/utils';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  checkbox: {
    paddingTop: 2,
  },
  checkboxLabel: {
    alignItems: 'flex-start',
  },
  button: {
    fontWeight: 600,
    padding: 22,
    marginTop: 30,
  },
  login: {
    color: darkGray,
    textAlign: 'center',
  },
  loginDivider: {
    backgroundColor: borderColor,
    margin: '40px 0 30px',
  },
  joinNetwork: {
    padding: 20,
    border: `1px solid ${borderColor}`,
    backgroundColor: sand,
    textAlign: 'center',
    lineHeight: 1.25,
  },
  agreements: {
    marginTop: 15,
    textAlign: 'left',
  },
});

function Join({
  viewer,
  domain,
  updateUser,
  joinNetwork,
  addGroupMember,
  isExpertSignup,
}) {
  const navigate = useNavigate();
  const s = useStyles();

  const handleSubmit = useCallback(
    async (values) => {
      const now = new Date();
      const agreements = Object.keys(values).map((name) => {
        return {
          policy: name,
          accepted: values[name],
          updated_at: now,
        };
      });

      await updateUser({ id: viewer.id, agreements });

      if (isExpertSignup) {
        await joinNetwork(domain.subdomain);

        navigate('/network');
        return;
      }

      await addGroupMember(domain.group.id, {
        role: 'member',
        userId: viewer.id,
      });

      navigate(`/team/${domain.subdomain}`);
    },
    [viewer, domain]
  );

  const validate = useCallback((values) => {
    const errors = {};

    if (domain.agreements) {
      domain.agreements.forEach((a) => {
        if (!values[a.policy_code]) {
          errors[a.policy_code] = 'Required';
        }
      });
    }

    return errors;
  }, []);

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit }) => {
        return (
          <>
            <form onSubmit={(e) => handleSubmit(e)} className={s.joinNetwork}>
              You are already logged into your OnFrontiers account.
              {domain.agreements && domain.agreements.length > 0 && (
                <div className={s.agreements}>
                  <br />
                  {domain.agreements.map((a) => (
                    <Field
                      key={a.policy_code}
                      type="checkbox"
                      component={Checkbox}
                      classes={{ root: s.checkbox }}
                      labelClasses={{ root: s.checkboxLabel }}
                      FormControlProps={{ margin: 'none' }}
                      name={a.policy_code}
                      label={
                        <span>
                          I have read and agree with&nbsp;
                          <Link newTab to={a.policy_url}>
                            {a.policy_label}
                          </Link>
                          .
                        </span>
                      }
                    />
                  ))}
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                classes={{ root: s.button }}
              >
                Join
              </Button>
            </form>

            <Divider className={s.loginDivider} />

            <div className={s.login}>
              Not {viewer.first_name}?&nbsp;
              <Link
                to={`/logout?next=/${`${domain.subdomain ? domain.subdomain + '/' : ''}login/expert`}`}
              >
                Log out
              </Link>{' '}
              to join or sign into your own account
            </div>
          </>
        );
      }}
    </Form>
  );
}

Join = connect(undefined, {
  updateUser,
  joinNetwork,
  addGroupMember,
})(Join);

function Signup({
  viewer,
  domain,
  tags,
  signupType,
  nextUrl,
  signinLink,
  signinNextUrl,
  invite,
  defaultEmail,
  title,
  shortAboutText,
  longAboutText,
  children,
}) {
  const { graphql } = useApp();
  const touPol = policies['terms-of-use'];
  const privacyPol = policies.privacy;
  const expertPartPol = policies['expert-participation-agreement'];

  const isExpertSignup = signupType === 'expert';
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const s = useStyles();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return Promise.resolve();
    }

    const token = await executeRecaptcha('signup');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const initialValues = useMemo(
    () => ({
      email: defaultEmail,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      const tracking = getTracking() || {};
      try {
        const now = new Date();
        await signup(
          graphql,
          {
            recaptchaToken,
            subdomain: domain && domain.subdomain,
            email: values.email,
            phone: values.phone,
            password: values.password,
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            signup_type: signupType,
            landing_url: tracking.landing,
            invite,
            tags: tags ? (Array.isArray(tags) ? tags : [tags]) : [],
            login: signupType === 'expert' || !domain?.group?.enforce_2fa,
            agreements: [
              {
                policy: touPol.apiKey,
                accepted: !!values.accept_privacy_and_terms_of_use,
                updated_at: now,
              },
              {
                policy: privacyPol.apiKey,
                accepted: !!values.accept_privacy_and_terms_of_use,
                updated_at: now,
              },
              ...expertAgreements.map((a) => ({
                policy: a.policy_code,
                accepted: !!values[`accept_${a.policy_code}`],
                updated_at: now,
              })),
            ],
          },
          nextUrl || undefined
        );
      } catch (e) {
        handleReCaptchaVerify();
        const evaluator = e?.rawError?.[0]?.message || e?.message;
        switch (evaluator) {
          case ErrEmailAlreadyExists.message:
            return { email: 'Email address is already in use' };
          case ErrPhoneAlreadyExists.message:
            return { phone: 'Phone number is already in use' };
          case 'invalid first name':
            return { first_name: 'Invalid name' };
          case 'invalid last name':
            return { last_name: 'Invalid name' };
          default:
            throw e;
        }
      }
    },
    [
      isExpertSignup,
      handleReCaptchaVerify,
      recaptchaToken,
      nextUrl,
      domain,
      invite,
      tags,
    ]
  );

  const expertAgreements = useMemo(() => {
    return isExpertSignup
      ? [
          {
            policy_code: expertPartPol.apiKey,
            policy_label: expertPartPol.name,
            policy_url: expertPartPol.url,
          },
          ...((domain && domain.agreements) || []),
        ]
      : [];
  }, [isExpertSignup, domain]);

  const validate = useCallback((values) => {
    const errors = {};

    if (!(values.first_name || '').trim()) {
      errors.first_name = 'Required';
    }

    if (values.first_name && !hasOnlyLetters(values.first_name)) {
      errors.first_name = 'Only letters allowed';
    }

    if (!(values.last_name || '').trim()) {
      errors.last_name = 'Required';
    }

    if (values.last_name && !hasOnlyLetters(values.last_name)) {
      errors.last_name = 'Only letters allowed';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmailValid(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (isExpertSignup && !values.phone) {
      errors.phone = 'Required';
    }

    if (values.phone && !isPhoneValid(values.phone)) {
      errors.phone = 'Phone must be valid';
    }

    if (!isValidPassword(values.password)) {
      errors.password = 'Password does not meet the requirements';
    }

    if (!values.accept_privacy_and_terms_of_use) {
      errors.accept_privacy_and_terms_of_use = 'Required';
    }

    expertAgreements.forEach((a) => {
      const fieldName = `accept_${a.policy_code}`;
      if (!values[fieldName]) {
        errors[fieldName] = 'Required';
      }
    });

    return errors;
  }, []);

  const showJoin = viewer && viewer.id && !!domain;

  return (
    <AuthLayout
      headerChildren={children}
      domain={domain}
      title={title}
      shortAboutText={shortAboutText}
      longAboutText={longAboutText}
    >
      {showJoin ? (
        <Join viewer={viewer} domain={domain} isExpertSignup={isExpertSignup} />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          subscription={{
            submitting: true,
            values: true,
            initialValues: true,
            touched: true,
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={(e) => handleSubmit(e)}>
                <Field
                  component={TextField}
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  placeholder="Please provide your full first name"
                  required={!isExpertSignup}
                  autoFocus
                  changeOnBlur={false}
                  format={normalizeSpace}
                />

                <Field
                  component={TextField}
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Please provide your full last name"
                  required={!isExpertSignup}
                  changeOnBlur={false}
                  format={normalizeSpace}
                />

                <Field
                  component={PhoneInput}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  showExampleOnError
                />

                <Field
                  component={TextField}
                  changeOnBlur={false}
                  name="email"
                  label="Email"
                  variant="outlined"
                  inputProps={{ autoComplete: 'off' }}
                  required={!isExpertSignup}
                />

                <Field
                  component={PasswordText}
                  name="password"
                  label="Password"
                  variant="outlined"
                  showHelp
                  inputProps={{ autoComplete: 'new-password' }}
                  required={!isExpertSignup}
                />

                <div className={s.agreements}>
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    classes={{ root: s.checkbox }}
                    labelClasses={{ root: s.checkboxLabel }}
                    FormControlProps={{ margin: 'none' }}
                    name="accept_privacy_and_terms_of_use"
                    label={
                      <span>
                        I have read and agree to OnFrontiers{' '}
                        <Link newTab to={privacyPol.url}>
                          {privacyPol.name}
                        </Link>{' '}
                        &amp;{' '}
                        <Link newTab to={touPol.url}>
                          {touPol.name}
                        </Link>
                        .
                      </span>
                    }
                  />
                  {expertAgreements.map((a) => (
                    <Field
                      key={a.policy_code}
                      type="checkbox"
                      component={Checkbox}
                      classes={{ root: s.checkbox }}
                      labelClasses={{ root: s.checkboxLabel }}
                      FormControlProps={{ margin: 'none' }}
                      name={`accept_${a.policy_code}`}
                      label={
                        <span>
                          I have read and agree with&nbsp;
                          <Link newTab to={a.policy_url}>
                            {a.policy_label}
                          </Link>
                          .
                        </span>
                      }
                    />
                  ))}
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!recaptchaToken && executeRecaptcha}
                  classes={{ root: s.button }}
                >
                  Join
                </Button>

                <Divider className={s.loginDivider} />

                <div className={s.login}>
                  Already have an account?
                  <Link
                    to={formatAuthPath({
                      to: signinLink || '/login',
                      domain,
                      signupType,
                      invite,
                      next: signinNextUrl || nextUrl,
                    })}
                  >
                    {' '}
                    Sign in
                  </Link>
                </div>
              </form>
            );
          }}
        </Form>
      )}
    </AuthLayout>
  );
}

export default Signup;

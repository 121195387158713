import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { Select } from '../FormAdapters/FormAdapters';

const CountryType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export default class SelectLocation extends Component {
  static propTypes = {
    countries: PropTypes.arrayOf(CountryType),
    value: PropTypes.oneOfType([CountryType, PropTypes.arrayOf(CountryType)]),
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
  };

  render() {
    const { id, input, meta, countries = [], ...other } = this.props;

    const value = this.props.value || (input && input.value) || [];

    return (
      <Select
        autocomplete
        input={input}
        meta={meta}
        fullWidth
        openOnFocus
        options={countries}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        // Show all countries at all times
        limit={null}
        rawValueOnChange
        value={value}
        selectOnExactMatchInMultiMode
        {...other}
      />
    );
  }
}

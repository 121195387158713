import React, { createContext, useContext } from 'react';
import { Store, AnyAction } from 'redux';

interface Context {
  pathname: string;
  query: object;

  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<any, AnyAction> & {
    dispatch: AnyAction;
  };
  // GraphQL client (Lokka)
  // https://github.com/kadirahq/lokka
  graphql: object;

  // Like window.location but works on server as well
  location: object;

  // Device capabilities (only used on server)
  userAgent: object;
  capabilities: object;

  sendBird: object;
  voipCarrier: object;
  permissions: object;
  apiWebSocket: object;
}

type AppContextData = Context;

interface AppProviderProps {
  children: React.ReactNode;
  context: Context;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

const AppProvider: React.FC<AppProviderProps> = ({ children, context }) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

function useApp(): AppContextData {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useApp must be user within an AppProvider');
  }

  return context;
}

export { AppProvider, useApp };

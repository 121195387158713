import { colors } from '../../tailwind/colors';
// Colors
// https://projects.invisionapp.com/d/main#/console/11241705/348769977/preview
export const white = '#FFFFFF';
export const black = '#000000';
export const teal500 = '#28BEAF';
export const teal900 = '#005755';
export const orange500 = '#FFA21D';
export const red500 = '#FB3544';
export const darkGray = '#787878';
export const darkBrown = '#2D2D2D';
export const lightTan = '#E6E6D7';
export const lightBlue = '#CCDEEC';
export const mediumBlue = '#6895B8';
export const darkBlue = '#28465A';
export const darkGreen = '#00646E';
export const sand = '#F2F2EB';
export const porpita = '#2389C1';
export const primary = colors.primary.main;
export const primaryLight = colors.primary.light;
export const primaryDark = colors.primary.dark;

// Off palette colors
export const grey50 = '#f6f7f7';
export const grey100 = '#E5EBE9';
export const grey300 = '#ECECEC';
export const skype = '#00AFF0';
export const linkedIn = '#0077B5';
export const borderColor = 'rgba(0, 0, 0, 0.2)';
export const borderColorHex = '#D2D2D2'; // equivalent to borderColor
export const highlightedExpert = '#F2F7F8';
export const aboutEmployeeRole = '#999999';
export const emailContentTitle = '#4A4A4A';
export const whiteTransparent = 'rgba(255, 255, 255, 0.3)';
export const blue = '#00BCD4';

export default {
  primary: colors.primary.main,
  primaryLight: colors.primary.light,
  primaryDark: colors.primary.dark,
  white,
  black,
  teal500,
  teal900,
  orange500,
  red500,
  darkGray,
  darkBrown,
  lightTan,
  lightBlue,
  mediumBlue,
  darkBlue,
  darkGreen,
  sand,
  porpita,
  grey300,
  skype,
  linkedIn,
  borderColor,
  borderColorHex,
  highlightedExpert,
  aboutEmployeeRole,
  emailContentTitle,
  whiteTransparent,
};

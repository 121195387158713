import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { TextField } from '../../components/FormAdapters/FormAdapters';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import ProjectSettingsMenu from '../../components/EditProjectMembers/SettingsMenu';
import EditIcon from '../../components/EditIcon';
import EditDialog from '../../components/EditDialog/EditDialog';
import MediaQuery from '../../components/MediaQuery';
import MemberList from '../../components/MemberList';
import Link from '../../components/Link';
import Button from '../../components/Button/Button';
import ExpertRequestPreview from '../../components/ExpertRequestPreview';
import MemberRequests from '../../components/MemberRequests/Project';
import SelectGroup from '../../components/SelectGroup';
import Markdown from '../../components/Markdown';
import { SCREEN_XS } from '../../core/muiTheme';
import { notify } from '../../actions/ui';
import { saveProject } from '../../actions/project';
import { sortMembers } from '../../core/project';
import s from './ProjectDetails.module.scss';
import { normalizeSpace } from '../../core/util';
import { useApp } from 'hooks/useAppContext';

const dialogs = {
  name: {
    title: 'Edit Name',
    component() {
      return (
        <Field
          id="projectName"
          component={TextField}
          name="name"
          fullWidth
          label="Name this project"
        />
      );
    },
  },
  tracking_code: {
    title: 'Edit Tracking Code',
    component() {
      return (
        <Field
          id="projectTrackingCode"
          component={TextField}
          inputProps={{ maxLength: 60 }}
          name="tracking_code"
          fullWidth
          label="Tracking Code"
          multiline
          onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
          parse={(v) => normalizeSpace(v)}
        />
      );
    },
  },
  group: {
    title: 'Edit Team',
    fetch: SelectGroup.fetch,
    component() {
      return <Field name="group.id" limit={10} component={SelectGroup} />;
    },
  },
};

function ProjectDetails(props) {
  const { store } = useApp();
  const [editSections, setEditSections] = useState({});

  const { viewer, project, notify } = props;

  const openDialog = async (name) => {
    const dialog = dialogs[name];

    if (dialog.fetch) await store.dispatch(dialog.fetch({ viewer }));
    setEditSections((sections) => ({
      ...sections,
      [name]: true,
    }));
  };

  const closeDialog = (key, message, error) => {
    setEditSections((sections) => ({
      ...sections,
      [key]: false,
    }));

    if (message) {
      notify(message, error ? 'error' : 'success');
    }
  };

  if (!project) return <div />;

  const members = sortMembers(project.members);
  const membersToShow = members.filter((m) => m.state === 'active');
  const membersToEdit = members.filter((m) =>
    ['active', 'denied'].includes(m.state)
  );
  const memberRequests = members.filter((m) => m.state === 'awaiting_approval');

  const none = <span className={s.none}>None</span>;

  return (
    <LayoutPage showNav selected="expert_requests">
      <ConsultationsStarting />
      <div className={s.header}>
        <div className={s.title}>
          <div className={s.titleText}>
            {project.name}
            <EditIcon
              onClick={() => openDialog('name')}
              style={{ marginLeft: 10, verticalAlign: 'middle' }}
            />
          </div>
          <div className={s.members}>
            <MemberList members={membersToShow} />
            <ProjectSettingsMenu
              showDelete={project.permissions.includes('delete')}
              project={project}
              members={membersToEdit}
            />
          </div>
        </div>
        {viewer.admin && (
          <div className={s.field}>
            <div className={s.fieldLabel}>Team:</div>
            <div className={s.fieldValue}>
              {project.group ? (
                <Link to={project.group.html_url}>{project.group.name}</Link>
              ) : (
                none
              )}
            </div>
            <EditIcon
              onClick={() => openDialog('group')}
              style={{ marginLeft: 10 }}
            />
          </div>
        )}
        <div className={s.field}>
          <div className={s.fieldLabel}>Tracking Code:</div>
          <div className={s.fieldValue}>{project.tracking_code || none}</div>
          <EditIcon
            onClick={() => openDialog('tracking_code')}
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>

      <div className={s.memberRequests}>
        <MemberRequests
          projectId={project.id}
          memberRequests={memberRequests}
        />
      </div>

      <div className={s.requests}>
        {project.expert_requests.map((er) => (
          <ExpertRequestPreview
            className={s.request}
            key={er.id}
            request={er}
            showState
            showContextActions
            showDeleteAction
            showCloseAction
          />
        ))}
      </div>

      <Link
        to={`/request_expert?project_id=${project.id}`}
        style={{ display: 'inline-block', marginTop: 20 }}
      >
        <Button color="lightTan" size="large">
          Add Expert Request
        </Button>
      </Link>

      <Markdown body={project.summary} />

      {Object.keys(dialogs).map((dialog) => (
        <EditProject
          key={dialog}
          initialValues={project}
          open={editSections[dialog]}
          onClose={(message, error) => {
            closeDialog(dialog, message, error);
          }}
          {...dialogs[dialog]}
        />
      ))}
    </LayoutPage>
  );
}

export default connect(
  (state, ownProps) => {
    return {
      viewer: state.viewer,
      project: (
        state.projects.default.edges.find(
          (e) => e.node.id === ownProps.projectId
        ) || {}
      ).node,
    };
  },
  {
    notify,
  }
)(ProjectDetails);

class EditProject extends PureComponent {
  handleSubmit = async () => {
    const { formValues, onClose, reset, saveProject } = this.props;

    try {
      const data = await saveProject({
        ...formValues,
        group_id: formValues.group ? formValues.group.id : '',
      });
      if (data) {
        reset();
        onClose('Project updated.', false);
      }
    } catch (e) {
      onClose('An error occurred when updating the project', true);
    }
  };

  handleReset = () => this.props.reset();

  handleCloseRequest = () => this.props.onClose();

  render() {
    const {
      onClose,
      reset,
      submitOnClick,
      handleSubmit,
      component,
      array,
      change,
      isMobileVersion,
      children,
      ...props
    } = this.props;

    return (
      <EditDialog
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.onReset}
        onClose={this.handleCloseRequest}
        {...props}
      >
        <MediaQuery maxWidth={SCREEN_XS}>
          {(isMobileVersion) =>
            component
              ? React.createElement(component, {
                  array,
                  change,
                  isMobileVersion,
                  onClick: submitOnClick
                    ? handleSubmit(this.handleSubmit)
                    : undefined,
                })
              : children
          }
        </MediaQuery>
      </EditDialog>
    );
  }
}

EditProject = reduxForm({
  form: 'editProject',
  enableReinitialize: true,
})(EditProject);

EditProject = connect(
  (state, ownProps) => {
    const form = state.form.editProject;
    return {
      formValues: form ? form.values : {},
      validate: ownProps.component.validate,
    };
  },
  {
    saveProject,
  }
)(EditProject);

import { inputClasses } from '@mui/material/Input';
import { inputBaseClasses } from '@mui/material/InputBase';
import { formControlClasses } from '@mui/material/FormControl';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import theme from 'theme';

const searchInputNs = 'search-input';

export const searchInputClasses = {
  hasSearchValue: `${searchInputNs}__has-search-value`,
};

const { hasSearchValue } = searchInputClasses;

const styles = {
  textField: {
    [`&.${formControlClasses.root}`]: {
      [`& .${formHelperTextClasses.root}`]: {
        marginLeft: 0,
        fontSize: '12px',
      },
    },
  },
  searchInput: {
    [`& .${inputClasses.root}.${inputBaseClasses.root}`]: {
      '&::after, &::before, &:hover::before': {
        border: 0,
      },
      [`& .${inputClasses.input}, & .fa`]: {
        color: theme.palette.grey[500],
        fontSize: '14px',
        lineHeight: '18px',
      },
      '& .fa': {
        fontWeight: '100',
        lineHeight: '18px',
        paddingRight: '0px',
        fontSize: '22px',
      },
      [`& .${inputClasses.input}`]: {
        fontSize: '16px',
        lineHeight: '18px',
      },
    },
    [`&.${hasSearchValue}`]: {
      [`& .${inputClasses.root}.${inputBaseClasses.root}`]: {
        '& .fa': {
          color: theme.palette.grey[400],
        },
        [`& .${inputClasses.input}`]: {
          color: theme.palette.grey[500],
        },
      },
    },
  },
};
export default styles;

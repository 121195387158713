export const screens = {
  sm: 640,
  md: 768,
  'desktop-nav': 860,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const screensForConfig = {
  sm: `${screens.sm}px`,
  md: `${screens.md}px`,
  'desktop-nav': `${screens['desktop-nav']}px`,
  lg: `${screens.lg}px`,
  xl: `${screens.xl}px`,
  '2xl': `${screens['2xl']}px`,
};

export const mq = {
  smd: `(max-width: ${screens.sm - 1}px)`,
  sm: `(min-width: ${screens.sm}px)`,
  mdd: `(max-width: ${screens.md - 1}px)`,
  md: `(min-width: ${screens.md}px)`,
  lgd: `(max-width: ${screens.lg - 1}px)`,
  lg: `(min-width: ${screens.lg}px)`,
  xld: `(max-width: ${screens.xl - 1}px)`,
  xl: `(min-width: ${screens.xl}px)`,
  '2xld': `(max-width: ${screens['2xl'] - 1}px)`,
  '2xl': `(min-width: ${screens['2xl']}px)`,
};

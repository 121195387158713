import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Menu from '@mui/material/Menu';
import Avatar from 'components/Avatar';
import { ChevronDown } from 'react-feather';

export const UserMenu = ({
  children,
  fullName,
  avatarPictureUrl,
  open,
  onOpenChange,
}: PropsWithChildren<{
  fullName: string;
  avatarPictureUrl?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}>) => {
  const previousOpenState = useRef(open);
  const [isOpen, setIsOpen] = useState(open ?? false);
  const [anchorElUser, setAnchorElUser] = useState<
    (EventTarget & Element) | null
  >(null);

  const handleOpenUserMenu = (event: React.MouseEvent) => {
    setAnchorElUser(event.currentTarget);
    setIsOpen(true);
    onOpenChange?.(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setIsOpen(false);
    onOpenChange?.(false);
  };

  useEffect(() => {
    if (open !== previousOpenState.current) {
      setIsOpen(!!open);
      previousOpenState.current = !!open;
    }
  }, [open]);

  return (
    <>
      <button
        data-testid="of-header-avatar"
        className={clsx(
          'my-5 ml-auto hidden max-h-[36px] items-center gap-8 rounded-sm border border-light-primary px-12 py-6 xl:flex',
          'hover:bg-gray-50',
          {
            'bg-gray-100': isOpen,
          }
        )}
        onClick={handleOpenUserMenu}
        aria-controls={isOpen ? 'of-user-menu' : undefined}
        aria-haspopup="true"
      >
        <Avatar
          fullName={fullName}
          src={avatarPictureUrl ?? ''}
          sx={{ width: 24, height: 24 }}
        />
        <span className="access-navbar-item hidden max-w-[150px] truncate text-14 font-semibold xl:block xl:max-w-[200px]">
          {fullName}
        </span>
        <ChevronDown
          className={clsx('h-16 w-16 transition-transform duration-100', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      <Menu
        sx={{ mt: '44px' }}
        id="of-user-menu"
        anchorEl={anchorElUser}
        classes={{
          paper: 'w-[200px] !top-0',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleCloseUserMenu}
      >
        {children}
      </Menu>
    </>
  );
};

export default UserMenu;

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import { dismissConsultationReview } from '../../actions/consultation';
import { hidePopup } from '../../actions/ui';
import { SCREEN_MD } from '../../core/muiTheme';
import Footer from '../Footer';
import MediaQuery from '../MediaQuery';
import Newsletter from '../Newsletter/Newsletter';
import ReviewConsultation from '../ReviewConsultation';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import AccessHeader from './AccessHeader';
import s from './Layout.module.scss';
import Popup from './Popup';
import Search from './Search';

function Layout({
  showFooter = true,
  showReviewConsultation = true,
  showNewRequest = true,
  showNewsletter = false,
  viewer,
  showNav,
  call,
  selected,
  verticalCenter,
  children,
  ui,
  footerStyle,
  contentClassName,
  hideSearch,
  unreviewedConsultations,
  dismissedConsultationReviews,
  dismissConsultationReview,
  headerBorder,
  marketingLinks,
  style,
}) {
  const updateIntercomLauncher = () => {
    if (window.Intercom) {
      const width = window.document.body.clientWidth;
      window.Intercom('update', { hide_default_launcher: width <= SCREEN_MD });
    }
  };

  useEffect(() => {
    updateIntercomLauncher();
    window.addEventListener('resize', updateIntercomLauncher);
    return () => {
      window.removeEventListener('resize', updateIntercomLauncher);
    };
  }, []);

  const shouldShowNav = showNav && viewer.id;
  const shouldShowNewRequest = showNewRequest && ui.userContext !== 'expert';

  const is404 = window.location.pathname === '/404';
  const unreviewed = unreviewedConsultations.edges.find(
    (e) => !dismissedConsultationReviews.includes(e.node.id)
  );
  const expert = unreviewed && unreviewed.node.expert;
  const requester = unreviewed && unreviewed.node.requester;
  const isViewerExpert = expert && expert.id === viewer.id;
  const userToReview = unreviewed && (isViewerExpert ? requester : expert);

  return (
    <MediaQuery maxWidth={SCREEN_MD}>
      {() => {
        return (
          <div className={s.root} style={style}>
            <AccessHeader
              SearchComponent={!hideSearch && Search}
              shouldShowNewRequest={shouldShowNewRequest}
            />
            <div
              className={cx(s.content, {
                [s.verticalCenter]: verticalCenter,
                [contentClassName]: !!contentClassName,
              })}
            >
              {children}
            </div>

            {showNewsletter && <Newsletter />}

            {showFooter && <Footer style={footerStyle} is404={is404} />}

            {ui.popup && <Popup open {...ui.popup} />}

            {showReviewConsultation && unreviewed && (
              <ReviewConsultation
                open
                consultationLink={!userToReview}
                form="globalReviewConsultation"
                consultation={unreviewed.node}
                user={userToReview}
                onCancel={() =>
                  dismissConsultationReview(unreviewed.node.id, false)
                }
                isViewerExpert={isViewerExpert}
              />
            )}

            <SnackbarNotification />
          </div>
        );
      }}
    </MediaQuery>
  );
}

Layout.propTypes = {
  user: PropTypes.object,
  showNav: PropTypes.bool,
  showFooter: PropTypes.bool,
  showNewsletter: PropTypes.bool,
  showReviewConsultation: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  verticalCenter: PropTypes.bool,
  viewer: PropTypes.object,
  showNewRequest: PropTypes.bool,
};

Layout = connect(
  (state) => ({
    viewer: state.viewer,
    ui: state.ui,
    call: state.call,
    dismissedConsultationReviews: state.consultationReviews.dismissed,
    unreviewedConsultations: state.consultations.unreviewed,
  }),
  {
    hidePopup,
    dismissConsultationReview,
  }
)(Layout);

export default function LayoutWrapper(props) {
  const loaderData = useLoaderData();
  let data = {};
  if (loaderData) {
    const { key, ...rest } = loaderData;
    data = rest;
  }
  return <Layout {...props} {...data} />;
}

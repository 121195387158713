import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import { Button as MaterialButton, Paper } from '@mui/material';

import Customers from '../../components/Customers';
import Layout from '../../components/MarketingLayout/MarketingLayout';
import Button from '../../components/Button/Button';
import MediaQuery from '../../components/MediaQuery';
import Newsletter from '../../components/Newsletter';
import Testimonial from '../../components/Testimonial';
import MarketingSection from '../../components/MarketingSection';
import ClientIntake from '../../components/ClientIntake';
import HowItWorks from '../../components/HowItWorks';
import FAIcon from '../../components/Icon/FAIcon';
import FeatureTile from '../../components/FeatureTile/FeatureTile';
import FeaturedExperts from '../../components/FeaturedExperts';
import SplashHero from '../../components/MarketingLayout/SplashHero';
import { EngagementsClient } from '../../components/Engagements';
import {
  darkBrown,
  teal500,
  darkGreen,
  sand,
  darkGray,
  white,
  black,
} from '../../core/colors';
import { SCREEN_SM, SCREEN_MD } from '../../core/muiTheme';
import s from './Platform.module.scss';

function GrowNetwork({ onActionClick }) {
  return (
    <MarketingSection darkBlue style={{ paddingBottom: 0 }}>
      <div className={s.grow}>
        <div className={s.growContent}>
          <h2 className={s.growTitle}>Grow your internal expert network</h2>
          <div className={s.growText}>
            Experts you meet on OnFrontiers can be freely re-engaged across your
            organization as suits your business needs. We can even integrate
            your experts to create a holistic expertise management solution that
            enhances your competitive advantage.
          </div>
          <MaterialButton
            style={{
              color: teal500,
              fontSize: 20,
              textTransform: 'none',
            }}
            onClick={onActionClick}
          >
            Learn More →
          </MaterialButton>
        </div>
        <div className={s.growImage}>
          <img alt="" src={require('./connections.svg')} />
        </div>
      </div>
    </MarketingSection>
  );
}

function Benefits({ onActionClick }) {
  return (
    <MarketingSection darkBlue padding={60}>
      <Grid container>
        <Grid item sm={12} md={12} lg={4}>
          <div className={s.benefits}>
            <h2 className={s.benefitsTitle}>
              Benefits of the OnFrontiers platform
            </h2>
            <Button size="large" onClick={onActionClick}>
              Request a Demo
            </Button>
          </div>
        </Grid>
        <Grid item container md={12} lg={8}>
          {' '}
          {/* AC: cannot set spacing on this grid. See comment on Benefits2 */}
          <Grid container spacing={4}>
            <Benefit icon="private" title="Privacy & Anonymity">
              Projects are hidden from public view, and details about you and
              your firm are hidden from Experts unless you choose to share them.
            </Benefit>
            <Benefit icon="checklist" title="Compliance Checks">
              Conflict evaluation and confidentiality provisions make
              OnFrontiers a safe place to engage on commercially sensitive
              topics.
            </Benefit>
            <Benefit icon="cogbrain" title="Knowledge Management">
              Easily access your go-to experts and data from past expert
              engagements completed by you or your teammates.
            </Benefit>
            <Benefit icon="handshake" title="Satisfaction Guarantee">
              We’ll happily match you with another Expert if you feel that your
              request wasn’t adequately fulfilled, or give you a refund.
            </Benefit>
          </Grid>
        </Grid>
      </Grid>
    </MarketingSection>
  );
}

function Benefit(props) {
  return (
    <FeatureTile
      sm={12}
      md={6}
      lg={6}
      iconProps={{ fill: teal500 }}
      titleStyle={{ color: white, fontSize: 22, margin: '20px 0' }}
      textStyle={{ color: white }}
      {...props}
    />
  );
}

function Benefits2({ onActionClick }) {
  return (
    <MarketingSection
      padding={60}
      titleMargin={60}
      preTitle="Platform benefits"
      title="Standard with every plan"
    >
      <Grid container>
        <Grid item container md={12} lg={6}>
          {' '}
          {/* AC: cannot set spacing on this grid otherwise the negative margin will affect the sibling Grid with the testimonial  */}
          <Grid container spacing={4}>
            <Benefit2
              icon="profilepuzzle"
              title="Get experts matched to your spec"
            >
              Experts are custom matched and screened based on highly
              specialized criteria that goes beyond what is on an expert’s CV or
              public profile
            </Benefit2>
            <Benefit2 icon="checklist" title="Engage safely and efficiently">
              Our compliance platform, anonymity options, and privacy settings,
              give you control you need to engage your network securely
            </Benefit2>
            <Benefit2 icon="handshake" title="Unlock valuable relationships">
              Experts you engage through OnFrontiers may be re-engaged directly
              by you and your colleagues without additional introductory fees or
              restrictions
            </Benefit2>
            <Benefit2
              icon="profiledisplay"
              title="Build and share your expert network"
            >
              Experts and data from projects are added to a knowledge base that
              can be shared across your organization or with partners to capture
              new opportunities
            </Benefit2>
            <Benefit2
              icon="chat"
              title="Collaborate effectively and efficiently"
            >
              Secure messaging, payments, call recordings, and transcripts are
              just a few of the features that help you work faster and smarter
            </Benefit2>
            <Benefit2
              icon="support"
              title="Get the support you need to succeed"
            >
              A research manager supports every project and a dedicated account
              manager helps teams deploy OnFrontiers to achieve their goals
            </Benefit2>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={12}
          lg={6}
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <MediaQuery maxWidth={SCREEN_MD}>
            {(isMd) => (
              <Testimonial
                pictureNextToName
                picture={require('./testimonial4.png')}
                name="Bobby Patel"
                title="Associate Principal at CrossBoundary"
                pictureSize={60}
                iconColor={darkGreen}
                style={{
                  marginTop: isMd ? 40 : 0,
                  padding: 40,
                  // AC: We should use column offset instead of dealing with maxWidth or padding manually
                  // https://github.com/mui-org/material-ui/issues/11251
                  maxWidth: isMd ? 'initial' : 455,
                  backgroundColor: sand,
                  boxSizing: 'border-box',
                }}
                contentStyle={{ flexDirection: 'column', width: '100%' }}
              >
                I learned a ton speaking with OnFrontiers experts on a topic for
                which there was very little information online and through desk
                research.
              </Testimonial>
            )}
          </MediaQuery>
        </Grid>
      </Grid>

      <Button size="large" onClick={onActionClick} style={{ marginTop: 50 }}>
        Let’s Find a Plan for You
      </Button>
    </MarketingSection>
  );
}

function Benefit2(props) {
  return (
    <FeatureTile
      xs={12}
      sm={6}
      md={4}
      lg={6}
      titleStyle={{ fontSize: 14 }}
      textStyle={{ fontSize: 14, color: darkGray }}
      {...props}
    />
  );
}

function SimilarOrganizations({ onActionClick }) {
  return (
    <MarketingSection
      style={{ backgroundColor: sand }}
      title="Learn how similar organizations are using OnFrontiers"
      titleStyle={{
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 'normal',
        color: black,
      }}
    >
      <Grid container spacing={4}>
        <SimilarOrganization
          icon="landmark"
          title="Government Contractors & NGOs"
          onClick={onActionClick}
        />
        <SimilarOrganization
          icon="briefcase"
          title="Management Consulting Firms"
          onClick={onActionClick}
        />
        <SimilarOrganization
          icon="chart-line"
          title="Private Equity"
          onClick={onActionClick}
        />
        <SimilarOrganization
          icon="industry-alt"
          title="Trade & Industry Groups"
          onClick={onActionClick}
        />
        <SimilarOrganization
          icon="hands-usd"
          title="Development Finance Institutions"
          onClick={onActionClick}
        />
        <SimilarOrganization
          icon="globe-europe"
          title="Multinational Corporations"
          onClick={onActionClick}
        />
      </Grid>
    </MarketingSection>
  );
}

function SimilarOrganization({ icon, title, onClick }) {
  return (
    <Grid item container xs={12} md={6} lg={4}>
      <Paper onClick={onClick} className={s.organization}>
        <div className={s.organizationIcon}>
          <FAIcon iconSet="fal" icon={icon} color={darkGreen} size={30} />
        </div>
        <div className={s.organizationTitle}>{title}</div>
      </Paper>
    </Grid>
  );
}

class Platform extends PureComponent {
  state = {
    clientIntakeOpen: false,
  };

  openClientIntake = () => this.setState({ clientIntakeOpen: true });

  closeClientIntake = () => this.setState({ clientIntakeOpen: false });

  render() {
    return (
      <MediaQuery maxWidth={SCREEN_SM}>
        {(isSm) => (
          <Layout>
            <SplashHero
              backgroundColor={darkBrown}
              titleStyle={{ maxWidth: 990 }}
              title="Automation for the knowledge economy."
              text="As the pace and complexity of work increases, faster access to the right expertise can give you an edge."
              testimonial={{
                name: 'Jason Schwarz',
                title: 'Senior Director at Tetra Tech DPK',
                picture: require('./testimonial1.png'),
                children: `On a 45 minute call the OnFrontiers expert was able to quickly orient us on
                   the key issues and reform actors, which allowed us to move forward efficiently
                   with our capture planning and ultimately win the proposal.`,
              }}
            >
              <div className={s.actions}>
                <Button
                  size="large"
                  onClick={this.openClientIntake}
                  className={s.becomeAnExpert}
                  style={{ minWidth: 213 }}
                >
                  Request a Demo
                </Button>
              </div>
            </SplashHero>

            <HowItWorks />

            <MarketingSection padding={50} darkGreen>
              <Testimonial
                vertical={isSm}
                dark
                picture={require('./testimonial2.png')}
                name="Carla Legros"
                title="Project Manager at Dalberg Global Development Advisors"
                pictureSize={84}
                iconColor={teal500}
              >
                As soon as we saw the project and understood the budget we had
                available, we knew we wanted to use OnFrontiers. Even if we had
                been able to go into the field, I’m not convinced that we would
                have been able to get the insights we would have needed without
                the experts OnFrontiers provided.
              </Testimonial>
            </MarketingSection>

            <Benefits onActionClick={this.openClientIntake} />

            <MarketingSection
              padding={60}
              preTitle="Flexible engagement types"
              title="Experts on any topic, in any market, in under 48 hours."
            >
              <EngagementsClient />
            </MarketingSection>

            <MarketingSection padding={50} darkGreen>
              <Testimonial
                vertical={isSm}
                dark
                picture={require('./testimonial3.png')}
                name="James Legerme"
                title="Senior Recruitment Manager at Chemonics International"
                pictureSize={84}
                iconColor={teal500}
              >
                For OnFrontiers to identify Chadian health supply chain experts
                that are also from Chad speaks volumes about their services.
                These experts were able to not only speak about the technical
                area, but were capable of providing insight on how it relates to
                the local context.
              </Testimonial>
            </MarketingSection>

            <Benefits2 onActionClick={this.openClientIntake} />

            <GrowNetwork onActionClick={this.openClientIntake} />

            <FeaturedExperts profiles={this.props.profiles} />

            <Customers />

            <Newsletter />

            <SimilarOrganizations onActionClick={this.openClientIntake} />

            <ClientIntake
              open={this.state.clientIntakeOpen}
              onClose={this.closeClientIntake}
            />
          </Layout>
        )}
      </MediaQuery>
    );
  }
}

export default Platform;

import { gql } from '@apollo/client';
import actionTypes from '../core/ActionTypes';
import { basicAuth } from '../core/api';

const { AUTH__UPDATE, BASIC_AUTH__UPDATE, USER__UPDATE_INFORMATION } =
  actionTypes;

export const otpTypes = Object.freeze({
  sms: 'sms',
  app: 'app',
  email: 'email',
});

export function setBasicAuth(userId, password) {
  return async (dispatch) => {
    dispatch({
      type: BASIC_AUTH__UPDATE,
      basicAuthentication: basicAuth(userId, password),
    });
  };
}

export function createOtpAuthConfiguration(method, address) {
  return async (dispatch, getState, { graphql }) => {
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication)
      : graphql;
    const { createOtpAuthConfiguration: otpAuthUri } = await client.mutate(
      `($method: String!, $address: String) {
        createOtpAuthConfiguration(method: $method, address: $address)
    }`,
      { method, address }
    );
    dispatch({ type: AUTH__UPDATE, otpAuthUri });
    return otpAuthUri;
  };
}

export function removeOtpAuth(userId, password) {
  return async (dispatch, getState, { graphql }) => {
    const { removeOtpAuth } = await graphql.mutate(
      `(
      $userId: String, $password: String!
    ) {
      removeOtpAuth(user_id: $userId, password: $password)
    }`,
      { userId, password }
    );
    return removeOtpAuth;
  };
}

export function disableOtpAuth(userId) {
  return async (dispatch, getState, { graphql }) => {
    const { disableOtpAuth } = await graphql.mutate(
      `(
      $userId: String
    ) {
      disableOtpAuth(user_id: $userId)
    }`,
      { userId }
    );
    return disableOtpAuth;
  };
}

export function authenticateOtp(otp) {
  return async (dispatch, getState, { graphql }) => {
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication)
      : graphql;
    const { authenticateOtp } = await client.mutate(
      `($otp: String!) {
      authenticateOtp(otp: $otp)
    }`,
      { otp }
    );
    return authenticateOtp;
  };
}

export function sendOtpSms({ email, password }) {
  return async (dispatch, getState, { graphql }) => {
    const client = graphql.fromAuth(basicAuth(email, password));
    const { sendOtpSms } = await client.mutate(`{
      sendOtpSms
    }`);
    return sendOtpSms;
  };
}

export function sendOtp(email, password) {
  return async (dispatch, getState, { graphql }) => {
    const client = graphql.fromAuth(basicAuth(email, password));
    const { sendOtp } = await client.mutate(gql`
      mutation sendOtp {
        sendOtp
      }
    `);
    return sendOtp;
  };
}

export function otpQrCodeBase64() {
  return async (dispatch, getState, { graphql }) => {
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication)
      : graphql;
    const { otpQrCodeBase64: qrCodeBase64 } = await client.query(`query {
      otpQrCodeBase64
    }`);
    return qrCodeBase64;
  };
}

export function setPassword(userId, currentPass, newPass) {
  return async (dispatch, getState, { graphql }) => {
    const { setPassword: user } = await graphql.mutate(
      `(
      $userId: String
      $currentPass: String
      $newPass: String!
    ) {
      setPassword(
        user_id: $userId
        current_password: $currentPass
        new_password: $newPass
      ) {
        id
        has_password
        password_expiry {
          expiry
          expired
          expiring
        }
      }
    }`,
      { userId, currentPass, newPass }
    );

    if (user) {
      dispatch({
        type: USER__UPDATE_INFORMATION,
        user,
      });

      return setPassword;
    }
  };
}

export function resetPassword(token, newPass) {
  return (dispatch, getState, { graphql }) => {
    return graphql.mutate(
      `(
      $token: String!
      $newPass: String!
    ) {
      resetPassword(
        token: $token
        new_password: $newPass
      )
    }`,
      { token, newPass }
    );
  };
}

export function unlockAccount(userId) {
  return async (dispatch, _, { graphql }) => {
    const { unlockAccount } = await graphql.mutate(
      `
    ($user_id: String!) {
      unlockAccount(user_id: $user_id)
    }
  `,
      { user_id: userId }
    );

    dispatch({
      type: USER__UPDATE_INFORMATION,
      user: {
        id: userId,
        locked: !unlockAccount,
      },
    });

    return unlockAccount;
  };
}

export function requestPasswordReset(email) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
      $email: String!
    ) {
      requestPasswordReset(email: $email)
    }`,
      { email }
    );
}

export function getOtpConfig(email, password) {
  return async (dispatch, getState, { graphql }) => {
    const client = graphql.fromAuth(basicAuth(email, password));
    const {
      otpConfiguration: { method, address, maskedAddress },
    } = await client.query(`query {
      otpConfiguration {
        method
        address
        maskedAddress
      }
    }`);
    return { method, maskedAddress, address };
  };
}

/* istanbul ignore file */
/* tslint:disable */

export type CurrentUserType = {
  OF_ID: string | null;
  EMAIL: string | null;
  NAME: string | null;
  BROWSER: string | null;
  LOCATION: string | null;
};

export const CurrentUser: CurrentUserType = {
  OF_ID: null,
  EMAIL: null,
  NAME: null,
  BROWSER: null,
  LOCATION: null,
};

export function segmentTracking(
  eventName: string,
  properties?: Record<string, unknown>
) {
  const { analytics } = window;

  if (!analytics || !analytics.identify) {
    return;
  }

  analytics.track(eventName, properties);
}

import React from 'react';
import { connect } from 'react-redux';
import Dialog from '../Dialog';
import EmptyMessage from '../EmptyMessage';
import FAIcon from '../Icon/FAIcon';
import { white } from '../../core/colors';
import { track } from '../../actions/tracking';
import Button from '../Button/Button';

class InternalNetworkSharePromoDialog extends React.Component {
  handleClick = () => {
    const { track, clickEventTrack = 'promo.click.internal_network.share' } =
      this.props;
    track(clickEventTrack);
    window.HubSpotConversations?.widget?.open();
  };

  componentDidMount() {
    const {
      open,
      track,
      showEventTrack = 'promo.show.internal_network.share',
    } = this.props;
    if (open && showEventTrack) track(showEventTrack);
  }

  componentDidUpdate(oldProps) {
    const {
      open,
      track,
      showEventTrack = 'promo.show.internal_network.share',
    } = this.props;
    if (!oldProps.open && open && showEventTrack) track(showEventTrack);
  }

  render() {
    const {
      track,
      clickEventTrack = 'promo.click.internal_network.share',
      showEventTrack = 'promo.show.internal_network.share',
      ...other
    } = this.props;

    return (
      <Dialog promo {...other}>
        <EmptyMessage
          border={false}
          style={{ padding: 20 }}
          titleStyle={{ color: white, fontSize: 20 }}
          bodyStyle={{ color: white, fontSize: 16 }}
          icon={
            <FAIcon
              iconSet="fal"
              icon="sitemap"
              size={30}
              color="rgba(255, 255, 255, .52)"
            />
          }
          title="Share your internal network"
          body={
            <React.Fragment>
              Share experts with partners and other organizations on
              OnFrontiers.
              <br />
              Contact our support team to know more.
            </React.Fragment>
          }
          action={
            <Button
              onClick={this.handleClick}
              fontColor={white}
              backgroundColor="rgba(255, 255, 255, .2)"
              style={{ backgroundColor: 'transparent' }}
            >
              More Info
            </Button>
          }
        />
      </Dialog>
    );
  }
}

InternalNetworkSharePromoDialog = connect(undefined, {
  track,
})(InternalNetworkSharePromoDialog);

export default InternalNetworkSharePromoDialog;

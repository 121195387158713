import moment from 'moment';
import pluralize from 'pluralize';

type MomentInputWithoutUndefined = Omit<moment.MomentInput, 'undefined'>;

export function calculateDateDiff(date: moment.MomentInput): [number, string] {
  const current = moment(new Date());
  const reference = moment(date);

  if (!date || !reference.isValid()) {
    return [0, 'years'];
  }

  const years = current.diff(reference, 'years');
  if (years) {
    return [years, 'years'];
  }

  const months = current.diff(reference, 'months');
  if (months) {
    return [months, 'months'];
  }

  return [0, 'years'];
}

export function sortableDate(
  date: MomentInputWithoutUndefined,
  reverse: boolean
): Date {
  let momentDate = moment(date);
  // push null to last position
  if (!momentDate.isValid()) {
    momentDate = moment().set('year', reverse ? 2000 : 2100);
  }
  return momentDate.toDate();
}

export const isAfter = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  orEqual = false
) => {
  const _start = moment(start, 'MM-YYYY');
  const _end = moment(end, 'MM-YYYY');

  if (_start.isValid() && _end.isValid()) {
    if (orEqual) {
      return _end.diff(_start, 'days') >= 0;
    }
    return _end.diff(_start, 'days') > 0;
  }

  return true;
};

export const safeFormatDate = (
  date: MomentInputWithoutUndefined,
  emptyText = '',
  pattern = 'MM/YYYY'
) => {
  const dateObj = moment(date, 'YYYY/MM/DD');
  if (!date || date === 'null' || !dateObj.isValid()) {
    return emptyText;
  }
  return dateObj.format(pattern);
};

export const diffBetweenDates = (
  startTime: moment.MomentInput,
  endTime: moment.MomentInput
) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);

  const years = endDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  const months = endDate.diff(startDate, 'months');

  // If End Date is equal to the Start date, we want to count as 1 month.
  if (years === 0 && months === 0) {
    return { years: 0, months: 1 };
  }

  return { years, months };
};

export const beautifulRangeDate = (
  startTime: moment.MomentInput,
  endTime: moment.MomentInput,
  skipDiff?: boolean
): string => {
  if (!startTime && !endTime) {
    return '';
  }

  let startDate = '';
  if (startTime) {
    startDate = moment(startTime).format('YYYY');
  }

  let endDate = 'Present';
  if (endTime) {
    endDate = moment(endTime).format('YYYY');
  }

  let displayDiff = '';

  if (startTime && !skipDiff) {
    const endDate = endTime || new Date();
    const { years, months } = diffBetweenDates(startTime, endDate);

    const displayYears =
      years > 0 ? `${years} ${pluralize('year', years)}` : '';
    const displayMonths =
      months > 0 ? `${months} ${pluralize('month', months)}` : '';

    displayDiff =
      displayYears || displayMonths
        ? '(' + [displayYears, displayMonths].filter((v) => v).join(', ') + ')'
        : '';
  }

  return `${startDate || 'Missing'} - ${endDate} ${displayDiff}`.trim();
};

export const sortStartDateMostRecent = (
  a: { start_date?: moment.MomentInput },
  b: { start_date?: moment.MomentInput }
) => {
  return isAfter(moment(a.start_date), moment(b.start_date)) ? 1 : -1;
};

type DateProperty =
  | 'date'
  | 'issueDate'
  | 'expirationDate'
  | 'publishedDate'
  | 'startDate'
  | 'endDate';

export const reverseSortByPrimaryAndSecondaryDate =
  ({
    primaryDate,
    secondaryDate,
    deprioritizeMissing,
  }: {
    primaryDate: DateProperty;
    secondaryDate?: DateProperty;
    deprioritizeMissing?: boolean;
  }) =>
  (
    a: Partial<Record<DateProperty, moment.Moment | null>>,
    b: Partial<Record<DateProperty, moment.Moment | null>>
  ) => {
    // Compare primary date first
    if (a[primaryDate] && !b[primaryDate]) {
      return deprioritizeMissing ? -1 : 1;
    }

    if (!a[primaryDate] && b[primaryDate]) {
      return deprioritizeMissing ? 1 : -1;
    }

    const aPrimaryDate = moment(a[primaryDate], 'MM-YYYY');
    const bPrimaryDate = moment(b[primaryDate], 'MM-YYYY');
    const primaryDiff = aPrimaryDate.diff(bPrimaryDate, 'days');

    if (primaryDiff > 0) {
      return -1;
    }

    if (primaryDiff < 0) {
      return 1;
    }

    if (!secondaryDate) {
      return 0;
    }

    // Primary dates equal, compare secondary date
    if (a[secondaryDate] && !b[secondaryDate]) {
      return deprioritizeMissing ? -1 : 1;
    }

    if (!a[secondaryDate] && b[secondaryDate]) {
      return deprioritizeMissing ? 1 : -1;
    }

    const aSecondaryDate = moment(a[secondaryDate], 'MM-YYYY');
    const bSecondaryDate = moment(b[secondaryDate], 'MM-YYYY');
    const secondaryDiff = aSecondaryDate.diff(bSecondaryDate, 'days');

    return secondaryDiff > 0 ? -1 : secondaryDiff < 0 ? 1 : 0;
  };

export const displayWithInDaysOrDate = (
  date: string | null,
  days: number,
  currentLabel: string
) =>
  Math.abs(moment(date).diff(moment().toDate(), 'days')) < days
    ? currentLabel
    : moment(date).format('MM/YYYY');

type DaysPerUnits = 'year' | 'month' | 'week';
type DaysPer = {
  name: string;
  value: number;
};

const DAYS_PER: Record<DaysPerUnits, DaysPer> = {
  year: {
    name: 'year',
    value: 365,
  },
  month: {
    name: 'month',
    value: 30,
  },
  week: {
    name: 'week',
    value: 7,
  },
};

export const calculatePeriod = (daysPer: DaysPer, days: number) => {
  return { period: daysPer.name, value: Math.ceil(days / daysPer.value) };
};

export const calculateExperienceTimeDisplay = (days: number) => {
  if (days >= DAYS_PER.year.value) {
    return calculatePeriod(DAYS_PER.year, days);
  }

  if (days >= DAYS_PER.month.value) {
    return calculatePeriod(DAYS_PER.month, days);
  }

  if (days >= DAYS_PER.week.value) {
    return calculatePeriod(DAYS_PER.week, days);
  }

  return null;
};

import { gql } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import ActionTypes from '../core/ActionTypes';
import { shouldResetCollection } from '../core/util';
import { notify } from './ui';

const {
  PROJECT__LIST_LOADING,
  PROJECT__LIST_LOADED,
  PROJECT__LIST,
  PROJECT__LIST_INVALIDATE,
  PROJECT__UPDATE,
  PROJECT__ADD_MEMBER,
  PROJECT__UPDATE_MEMBER,
  PROJECT__REMOVE_MEMBER,
  PROJECT__DELETE,
} = ActionTypes;

export function fetchProjects({
  cursor,
  collection = 'default',
  pageSize = 10,
  openOnly,
  memberOnly,
  groupId,
}) {
  return async (dispatch, getState, { graphql }) => {
    const reset = !cursor;
    const projects = getState().projects[collection];
    if (reset && !shouldResetCollection(projects, pageSize)) return projects;

    dispatch({ type: PROJECT__LIST_LOADING, collection });

    try {
      const data = await graphql.query(
        gql`
          query fetchProjects(
            $cursor: String
            $pageSize: Int!
            $openOnly: Boolean
            $memberOnly: Boolean
            $groupId: String
          ) {
            projects(
              after: $cursor
              first: $pageSize
              open_only: $openOnly
              member_only: $memberOnly
              group_id: $groupId
            ) {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                node {
                  id
                  name
                  expert_requests {
                    id
                    created_at
                    name
                    state
                    archived
                    stats {
                      candidates_count(states: [matched, verified, vetting])
                      calls
                    }
                    html_url
                    permissions
                  }
                  members {
                    id
                    role
                    state
                    user {
                      id
                      name
                      first_name
                      last_name
                      html_url
                      picture_url
                    }
                  }
                  group {
                    id
                    name
                  }
                  html_url
                }
              }
            }
          }
        `,
        {
          cursor,
          pageSize,
          openOnly,
          memberOnly,
          groupId,
        }
      );

      const page = data.projects;
      dispatch({
        type: PROJECT__LIST,
        collection,
        ...page,
        reset,
      });
    } catch (error) {
      dispatch(notify('An error occurred when loading the projects.', 'error'));
      Sentry.captureException(error);
    } finally {
      dispatch({ type: PROJECT__LIST_LOADED, collection });
    }
  };
}

export function invalidateFetchedProjectsCache() {
  return async (dispatch) => dispatch({ type: PROJECT__LIST_INVALIDATE });
}

export function fetchProject(id) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.query(
      `
      query fetchProject(
        $id: String!
      ) {
        project(
          id: $id
        ) {
          id
          name
          summary
          tracking_code
          group {
            id
            name
            html_url
          }
          expert_requests {
            id
            created_at
            name
            state
            archived
            stats {
              candidates_count(states: [matched, verified, vetting])
              calls
            }
            html_url
            permissions
          }
          members {
            id
            role
            state
            email
            user {
              id
              name
              first_name
              last_name
              html_url
              picture_url
            }
          }
          html_url
          permissions
        }
      }
    `,
      { id }
    );

    const { project } = data;
    if (!project) return;

    dispatch({
      type: PROJECT__UPDATE,
      project,
    });

    return project;
  };
}

export function saveProject(project) {
  return async (dispatch, getState, { graphql }) => {
    const mutation = project.id ? 'updateProject' : 'createProject';
    const data = project;
    const requiredStringOnCreate = project.id ? 'String' : 'String!';

    const { [mutation]: result } = await graphql.mutate(
      `
      (
        ${data.id ? '$id: String!' : ''}
        ${'name' in data ? '$name: String' : ''}
        ${'group_id' in data ? `$group_id: ${requiredStringOnCreate}` : ''}
        ${'tracking_code' in data ? '$tracking_code: String' : ''}
      ) {
        ${mutation} (
          ${project.id ? 'id: $id' : ''}
          ${'name' in data ? 'name: $name' : ''}
          ${'group_id' in data ? 'group_id: $group_id' : ''}
          ${'tracking_code' in data ? 'tracking_code: $tracking_code' : ''}
        ) {
          id
          name
          tracking_code
          group {
            id
            name
            html_url
          }
          members {
            id
            role
            state
            email
            user {
              id
              name
              first_name
              last_name
              html_url
              picture_url
            }
          }
        }
      }
    `,
      data
    );

    dispatch({
      type: PROJECT__UPDATE,
      project: result,
    });

    return getState().projects.default.edges.find(
      (e) => e.node.id === result.id
    ).node;
  };
}

export function addProjectMember(projectId, { user, role }) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      $projectId: String!
      $userId: String!
      $role: ProjectRole!
    ) {
      addProjectMember(
        project_id: $projectId
        user_id: $userId
        role: $role
      ) {
        id
        email
        state
        user {
          id
        }
      }
    }`,
      { projectId, userId: user.id, role }
    );

    if (result.addProjectMember) {
      const { id } = result.addProjectMember;

      dispatch({
        type: PROJECT__ADD_MEMBER,
        projectId,
        member: {
          id: result.addProjectMember.id,
          state: result.addProjectMember.state,
          user,
          role,
        },
      });

      return id;
    }
  };
}

export function requestAddProjectMember({ projectId, expertRequestId }) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      $projectId: String
      $expertRequestId: String
    ) {
      requestAddProjectMember(
        project_id: $projectId
        expert_request_id: $expertRequestId
      ) {
        id
        email
        user {
          id
        }
      }
    }`,
      { projectId, expertRequestId }
    );

    return result.requestAddProjectMember;
  };
}

export function awaitingProjectMembershipApproval({
  projectId,
  expertRequestId,
}) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query membershipApproval(
      $projectId: String
      $expertRequestId: String
    ) {
      awaitingProjectMembershipApproval(
        project_id: $projectId
        expert_request_id: $expertRequestId
      )
    }`,
      { projectId, expertRequestId }
    );

    return result.awaitingProjectMembershipApproval;
  };
}

export function updateProjectMember(projectId, member) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
    $id: String!
    $role: ProjectRole
    $state: ProjectMemberState
  ) {
    updateProjectMember(
      id: $id
      role: $role
      state: $state
    ) {
      id
      email
      role
      state
      user {
        id
      }
    }
  }`,
        member
      )
      .then((result) =>
        dispatch({
          type: PROJECT__UPDATE_MEMBER,
          projectId,
          member: {
            ...member,
            updated_by: result.updateProjectMember.updated_by,
          },
        })
      );
}

export function removeProjectMember(projectId, member) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
    $id: String!
  ) {
    removeProjectMember(id: $id) {
      id
      email
      user {
        id
      }
    }
  }`,
        { id: member.id }
      )
      .then(() =>
        dispatch({
          type: PROJECT__REMOVE_MEMBER,
          projectId,
          member,
        })
      );
}

export function fetchProjectNames({
  openOnly = true,
  memberOnly = false,
} = {}) {
  return async (dispatch, getState, { graphql }) => {
    const { viewer } = getState();
    if (!viewer || !viewer.id) return;

    const collection = 'names';
    const projects = getState().projects[collection];
    if (!shouldResetCollection(projects, 1000)) return projects;

    const data = await graphql.query(
      `query fetchProjects(
        $openOnly: Boolean
        $memberOnly: Boolean
      ) {
        projects(
          first: 200
          open_only: $openOnly
          member_only: $memberOnly
        ) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              name
              tracking_code
              group {
                id
              }
            }
          }
        }
      }`,
      {
        openOnly,
        memberOnly,
      }
    );

    const page = data.projects;

    dispatch({
      type: PROJECT__LIST,
      collection,
      reset: true,
      ...page,
    });

    return page;
  };
}

export function deleteProject(id) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `($id: String!) {
      deleteProject(id: $id) {
        id
      }
    }`,
      { id }
    );

    if (data && data.deleteProject) {
      dispatch({
        type: PROJECT__DELETE,
        id,
      });
    }

    return data;
  };
}

import React from 'react';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import styles from './styles';
import clsx from 'clsx';
import { FAIcon } from 'components/Icon';
import { InputProps } from './types';
import { dateMask, monthMask } from './masks';

const useStyles = makeStyles(styles);

const InputMask = React.forwardRef<IMask.MaskElement, InputBaseComponentProps>(
  function TextMaskCustom(props: InputBaseComponentProps, ref) {
    const { onChange, onBlur, mask, name, definitions, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        definitions={definitions}
        inputRef={ref}
        lazy={false}
        onAccept={(value: unknown) => {
          onBlur?.({
            target: { name, value },
          } as React.FocusEvent<HTMLInputElement>);
          return onChange?.({
            target: { name, value },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        overwrite
      />
    );
  }
);

const Input = React.forwardRef<HTMLDivElement | null, InputProps>(
  (
    {
      className,
      icon,
      iconPosition,
      iconLight,
      iconClick,
      adornmentPosition,
      mask,
      margin,
      definitions,
      label,
      required,
      abbreviateRequired,
      inputProps,
      ...rest
    },
    ref
  ): JSX.Element => {
    const classes = useStyles();

    const classProps = clsx(className, classes.textField);

    const props: TextFieldProps = {
      ...rest,
    };
    props.InputProps = props.InputProps || {};

    if (icon) {
      const iconAdornment = (
        <InputAdornment position={iconPosition || 'start'}>
          <FAIcon onClick={iconClick} icon={icon} light={iconLight} />
        </InputAdornment>
      );

      if (adornmentPosition === 'start') {
        props.InputProps = {
          ...props.InputProps,
          startAdornment: iconAdornment,
        };
      } else {
        props.InputProps = {
          ...props.InputProps,
          endAdornment: iconAdornment,
        };
      }
    }

    if (mask) {
      props.InputProps = {
        ...props.InputProps,
        inputComponent: InputMask,
      };

      props.inputProps = {
        ...props.inputProps,
        mask,
        definitions,
      };

      if (mask === Date) {
        props.inputProps = {
          ...props.inputProps,
          ...dateMask,
        };
      }

      if (mask === 'month') {
        props.inputProps = {
          ...props.inputProps,
          ...monthMask,
        };
      }
    }

    const labelParts = [
      label,
      required ? (abbreviateRequired ? '(req)' : '(required)') : null,
    ];
    const formattedLabel = labelParts.filter((v) => v).join(' ');

    return (
      <TextField
        ref={ref}
        className={classProps}
        margin={margin}
        data-testid="of-input"
        InputLabelProps={{ required: false }} // handling required styling ourselves
        label={formattedLabel}
        inputProps={{ ...inputProps, required }}
        {...props}
      />
    );
  }
);

export type { InputProps };

Input.displayName = 'Input';

export default Input;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '../FormAdapters/FormAdapters';

const NameableType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export default class SelectNameable extends Component {
  static propTypes = {
    multiple: PropTypes.bool,
    options: PropTypes.arrayOf(NameableType),
    value: PropTypes.oneOfType([NameableType, PropTypes.arrayOf(NameableType)]),
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
  };

  render() {
    const {
      id,
      input,
      meta,
      multiple = false,
      options = [],
      ...other
    } = this.props;
    const value = this.props.value || (input && input.value) || [];

    return (
      <Select
        autocomplete
        input={input}
        meta={meta}
        multiple={multiple}
        fullWidth
        openOnFocus
        options={options}
        getOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id}
        isOptionEqualToValue={(option, value) => option === value}
        // Show all requests at all times
        limit={null}
        rawValueOnChange
        value={value}
        {...other}
      />
    );
  }
}

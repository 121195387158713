import { loginAs } from '../../core/login';
export default {
  subdomain: '*',
  path: '/loginas',

  async action({ store, graphqlClient, query }) {
    const { next, user } = query;

    await loginAs(graphqlClient, store, user, next);
    return null;
  },
};

/**
 * Copyright © 2016-present OnFrontiers. All rights reserved.
 */

/**
 * Flux/Redux action types
 * http://redux.js.org/docs/basics/Actions
 *
 * Please, stick to the following convention:
 *
 *     <AREA>__<ACTION>_<TOPIC>
 *
 * For example:
 *
 *     USER_PROFILE__UPLOAD_PICTURE
 *
 * Action verb examples:
 *
 *     ADD    - add to the collection
 *     REMOVE - remove from the collection
 *     SET    - set a new value, replace an existing one if any
 *     TOGGLE - change from true to false and vise versa
 */
export default {
  LOADING__SET_PROGRESS: 'LOADING__SET_PROGRESS',
  //
  // Project
  // ---------------------------------------------------------------------------
  PROJECT__LIST: 'PROJECT__LIST',
  PROJECT__LIST_LOADING: 'PROJECT__LIST_LOADING',
  PROJECT__LIST_LOADED: 'PROJECT__LIST_LOADED',
  PROJECT__LIST_INVALIDATE: 'PROJECT__LIST_INVALIDATE',
  PROJECT__UPDATE: 'PROJECT__UPDATE',
  PROJECT__ADD_MEMBER: 'PROJECT__ADD_MEMBER',
  PROJECT__UPDATE_MEMBER: 'PROJECT__UPDATE_MEMBER',
  PROJECT__REMOVE_MEMBER: 'PROJECT__REMOVE_MEMBER',
  PROJECT__DELETE: 'PROJECT__DELETE',

  //
  // Expert Request
  // ---------------------------------------------------------------------------
  EXPERT_REQUEST__UPDATE: 'EXPERT_REQUEST__UPDATE',
  EXPERT_REQUEST__DELETE: 'EXPERT_REQUEST__DELETE',
  EXPERT_REQUEST__ADD_CANDIDATE: 'EXPERT_REQUEST__ADD_CANDIDATE',
  EXPERT_REQUEST__FETCHED_INIT_CANDIDATES:
    'EXPERT_REQUEST__FETCHED_INIT_CANDIDATES',
  EXPERT_REQUEST__FETCHED_MORE_CANDIDATES:
    'EXPERT_REQUEST__FETCHED_MORE_CANDIDATES',
  EXPERT_REQUEST__FETCHING_CANDIDATES: 'EXPERT_REQUEST__FETCHING_CANDIDATES',
  EXPERT_REQUEST__REMOVE_CANDIDATE: 'EXPERT_REQUEST__REMOVE_CANDIDATE',
  EXPERT_REQUEST__UPDATE_CANDIDATE: 'EXPERT_REQUEST__UPDATE_CANDIDATE',
  EXPERT_REQUEST__LIST: 'EXPERT_REQUEST__LIST',
  EXPERT_REQUEST__LIST_LOADING: 'EXPERT_REQUEST__LIST_LOADING',
  EXPERT_REQUEST__LIST_LOADED: 'EXPERT_REQUEST__LIST_LOADED',

  //
  // Profile Address
  // ---------------------------------------------------------------------------
  ADDRESS__SET_ADDRESS: 'ADDRESS__SET_ADDRESS',
  ADDRESS__REMOVE_ADDRESS: 'ADDRESS__REMOVE_ADDRESS',

  //
  // User Profile
  // ---------------------------------------------------------------------------
  USER__UPDATE_INFORMATION: 'USER__UPDATE_INFORMATION',
  USER__DELETE: 'USER__DELETE',

  //
  // Domains
  // ---------------------------------------------------------------------------
  DOMAIN__UPDATE: 'DOMAIN__UPDATE',

  //
  // Groups
  // ---------------------------------------------------------------------------
  GROUP__BATCH_ADD: 'GROUP__BATCH_ADD',
  GROUP__UPDATE: 'GROUP__UPDATE',
  GROUP__REMOVE_MEMBER: 'GROUP__REMOVE_MEMBER',
  GROUP__REMOVE_MEMBER_AWAITING: 'GROUP__REMOVE_MEMBER_AWAITING',
  GROUP__ADD_MEMBER: 'GROUP__ADD_MEMBER',
  GROUP__UPDATE_MEMBER: 'GROUP__UPDATE_MEMBER',
  GROUP__LIST_LOADING: 'GROUP__LIST_LOADING',
  GROUP__LIST_LOADED: 'GROUP__LIST_LOADED',

  //
  // Networks
  // ---------------------------------------------------------------------------
  NETWORK__UPDATE_EXPERT: 'NETWORK__UPDATE_EXPERT',

  //
  // Profiles
  // ---------------------------------------------------------------------------
  PROFILE__LIST_LOADING: 'PROFILE__LIST_LOADING',
  PROFILE__LIST: 'PROFILE__LIST',
  PROFILE__LIST_RESET: 'PROFILE__LIST_RESET',
  PROFILE__LIST_LOADED: 'PROFILE__LIST_LOADED',
  PROFILE__COUNT_LOADING: 'PROFILE__COUNT_LOADING',
  PROFILE__COUNT: 'PROFILE__COUNT',
  PROFILE__DELETE: 'PROFILE__DELETE',
  PROFILE__UPDATE: 'PROFILE__UPDATE',
  PROFILE__SET_CSV_PREVIEW: 'PROFILE__SET_CSV_PREVIEW',
  PROFILE__UPDATE_EXPERIENCE: 'PROFILE__UPDATE_EXPERIENCE',
  PROFILE__REMOVE_EXPERIENCE: 'PROFILE__REMOVE_EXPERIENCE',
  PROFILE__UPDATE_EDUCATION: 'PROFILE__UPDATE_EDUCATION',
  PROFILE__REMOVE_EDUCATION: 'PROFILE__REMOVE_EDUCATION',
  PROFILES__FETCH: 'PROFILES__FETCH',

  //
  // Compliance Training
  // ---------------------------------------------------------------------------
  // ...
  COMPLIANCE_TRAINING__ANSWER_QUESTION: 'COMPLIANCE_TRAINING__ANSWER_QUESTION',
  COMPLIANCE_TRAINING__COMPLETE_STEP: 'COMPLIANCE_TRAINING__COMPLETE_STEP',

  //
  // Consultation
  // ---------------------------------------------------------------------------
  CONSULTATION__ADD_REGISTRANT: 'CONSULTATION__ADD_REGISTRANT',
  CONSULTATION__BATCH_ADD: 'CONSULTATION__BATCH_ADD',
  CONSULTATION__UPDATE: 'CONSULTATION__UPDATE',
  CONSULTATION__DELETE: 'CONSULTATION__DELETE',
  CONSULTATION__LIST_LOADING: 'CONSULTATION__LIST_LOADING',
  CONSULTATION__LIST_LOADED: 'CONSULTATION__LIST_LOADED',
  CONSULTATION__CLEAR: 'CONSULTATION__CLEAR',
  CONSULTATION__DISMISS_REVIEW: 'CONSULTATION__DISMISS_REVIEW',
  CONSULTATION__RESET_COLLECTION: 'CONSULTATION__RESET_COLLECTION',

  //
  // Billing
  // ---------------------------------------------------------------------------
  BILLING__FETCH_ACCOUNT: 'BILLING__FETCH_ACCOUNT',
  BILLING__FETCH_PRICES: 'BILLING__FETCH_PRICES',

  //
  // Blog
  // ---------------------------------------------------------------------------
  BLOG__BATCH_ADD: 'BLOG__BATCH_ADD',
  BLOG__FETCH: 'BLOG__FETCH',

  //
  // Call
  // ---------------------------------------------------------------------------
  CALL__UPDATE: 'CALL__UPDATE',

  //
  // Messaging
  // ---------------------------------------------------------------------------
  MESSAGING__CHANNELS_LOADING: 'MESSAGING__CHANNELS_LOADING',
  MESSAGING__CHANNELS_LOADED: 'MESSAGING__CHANNELS_LOADED',
  MESSAGING__FETCH_CHANNELS: 'MESSAGING__FETCH_CHANNELS',
  MESSAGING__FETCH_CHANNEL: 'MESSAGING__FETCH_CHANNEL',
  MESSAGING__FETCH_UNREAD_CHANNELS: 'MESSAGING__FETCH_UNREAD_CHANNELS',
  MESSAGING__FETCH_UNREAD_COUNT: 'MESSAGING__FETCH_UNREAD_COUNT',
  MESSAGING__EVENT_UNREAD_COUNT: 'MESSAGING__EVENT_UNREAD_COUNT',
  MESSAGING__MESSAGES_LOADING: 'MESSAGING__MESSAGES_LOADING',
  MESSAGING__MESSAGES_LOADED: 'MESSAGING__MESSAGES_LOADED',
  MESSAGING__FETCH_PREVIOUS_MESSAGES: 'MESSAGING__FETCH_PREVIOUS_MESSAGES',
  MESSAGING__FETCH_NEW_MESSAGES: 'MESSAGING__FETCH_NEW_MESSAGES',
  MESSAGING__ADD_MESSAGE: 'MESSAGING__ADD_MESSAGE',
  MESSAGING__MESSAGE_SENDING: 'MESSAGING__MESSAGE_SENDING',
  MESSAGING__MESSAGE_SENT: 'MESSAGING__MESSAGE_SENT',
  MESSAGING__OFFLINE: 'MESSAGING__OFFLINE',
  MESSAGING__UPDATE_TEXT_INPUT: 'MESSAGING__UPDATE_TEXT_INPUT',
  MESSAGING__CHANNEL_CREATING: 'MESSAGING__CHANNEL_CREATING',
  MESSAGING__CHANNEL_CREATED: 'MESSAGING__CHANNEL_CREATED',
  MESSAGING__CHANNEL_FETCHING: 'MESSAGING__CHANNEL_FETCHING',
  MESSAGING__CHANNEL_FETCHED: 'MESSAGING__CHANNEL_FETCHED',
  MESSAGING__DELETE_CHANNEL: 'MESSAGING__DELETE_CHANNEL',

  //
  // Message Templates
  // ---------------------------------------------------------------------------
  MESSAGE_TEMPLATES__UPDATE: 'MESSAGE_TEMPLATES__UPDATE',
  MESSAGE_TEMPLATES__LOADING: 'MESSAGE_TEMPLATES__LOADING',
  MESSAGE_TEMPLATES__LIST: 'MESSAGE_TEMPLATES__LIST',
  MESSAGE_TEMPLATES__LOADED: 'MESSAGE_TEMPLATES__LOADED',

  //
  // App Notification
  // ---------------------------------------------------------------------------
  APP_NOTIFICATION__LIST: 'APP_NOTIFICATION__LIST',
  APP_NOTIFICATION__REMOVE: 'APP_NOTIFICATION__REMOVE',

  //
  // Landing Page
  // ---------------------------------------------------------------------------
  LANDING_PAGE__UPDATE: 'LANDING_PAGE__UPDATE',
  LANDING_PAGE__REMOVE: 'LANDING_PAGE__REMOVE',
  LANDING_PAGE__BATCH_ADD: 'LANDING_PAGE__BATCH_ADD',

  //
  // Expert Showcase Page
  // ---------------------------------------------------------------------------
  EXPERT_SHOWCASE__UPDATE: 'EXPERT_SHOWCASE__UPDATE',
  EXPERT_SHOWCASE__REMOVE: 'EXPERT_SHOWCASE__REMOVE',
  EXPERT_SHOWCASE__BATCH_ADD: 'EXPERT_SHOWCASE__BATCH_ADD',
  EXPERT_SHOWCASE_PROFILES__UPDATE: 'EXPERT_SHOWCASE_PROFILES__UPDATE',
  EXPERT_SHOWCASE_CATEGORY__UPDATE: 'EXPERT_SHOWCASE_CATEGORY__UPDATE',
  EXPERT_SHOWCASE_SECTION__UPDATE: 'EXPERT_SHOWCASE_SECTION__UPDATE',
  EXPERT_SHOWCASE_CATEGORY__REMOVE: 'EXPERT_SHOWCASE_CATEGORY__REMOVE',
  EXPERT_SHOWCASE_SECTION__REMOVE: 'EXPERT_SHOWCASE_SECTION__REMOVE',

  //
  // Misc
  // ---------------------------------------------------------------------------
  COUNTRIES__FETCH_SUCCESS: 'COUNTRIES__FETCH_SUCCESS',
  COUNTRIES__FETCH_ERROR: 'COUNTRIES__FETCH_ERROR',
  SECTOR__BATCH_ADD: 'SECTOR__BATCH_ADD',
  SECTOR__REMOVE: 'SECTOR__REMOVE',
  LOAD_FROM_LOCAL_STORAGE: 'LOAD_FROM_LOCAL_STORAGE',

  UI__SHOW_MESSAGE: 'UI__SHOW_MESSAGE',
  UI__HIDE_MESSAGE: 'UI__HIDE_MESSAGE',
  UI__SHOW_POPUP: 'UI__SHOW_POPUP',
  UI__HIDE_POPUP: 'UI__HIDE_POPUP',
  UI__SET_USER_CONTEXT: 'UI__SET_USER_CONTEXT',
  UI__SAVE_USER_VIEW: 'UI__SAVE_USER_VIEW',

  URL__SHORTENED: 'URL__SHORTENED',

  LEGAL__FETCH: 'LEGAL__FETCH',

  PERMISSION__SET: 'PERMISSION__SET',

  ACTIVITY__MERGE: 'ACTIVITY__MERGE',

  SEARCH: 'SEARCH',
  SEARCH_SELECT_PROFILE: 'SEARCH_SELECT_PROFILE',
  SEARCH_CLEAR_PROFILE_SELECTION: 'SEARCH_CLEAR_PROFILE_SELECTION',

  SUGGEST: 'SUGGEST',
  SUGGEST_LOADING: 'SUGGEST_LOADING',

  TRACKING_LOG__CREATE: 'TRACKING_LOG__CREATE',

  SITE_SETTINGS__FETCH: 'SITE_SETTINGS__FETCH',
  SITE_SETTINGS__UPDATE: 'SITE_SETTINGS__UPDATE',

  SAVED_SEARCHES__FETCH: 'SAVED_SEARCHES__FETCH',

  AUTH__UPDATE: 'AUTH__UPDATE',
  BASIC_AUTH__UPDATE: 'BASIC_AUTH__UPDATE',

  VIEWER__RESET: 'VIEWER__RESET',
};

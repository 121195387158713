import queryString from 'query-string';

const apiPrefix = '/wp-json/wp/v2/';

class BlogApi {
  constructor(url) {
    this.url = url;
  }

  async fetch(resource) {
    const fullPath = this.url + apiPrefix + resource;
    const resp = await fetch(fullPath);
    const body = await resp.json();
    if (resp.ok) return body;
    throw body;
  }

  media = (id) => this.fetch(`media/${id}`);

  user = (id) => this.fetch(`users/${id}`);

  tags = (options) => this.fetch(`tags?${queryString.stringify(options)}`);

  categories = (options) =>
    this.fetch(`categories?${queryString.stringify(options)}`);

  listPosts = (options) =>
    this.fetch(`posts?${queryString.stringify(options)}`);

  listPages = (options) =>
    this.fetch(`pages?${queryString.stringify(options)}`);
}

export default BlogApi;

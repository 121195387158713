import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import Body from '../../components/Body';
import Layout from '../../components/Layout';
import Button from '../../components/Button/Button';
import Customers from '../../components/Customers';
import RegionList from '../../components/RegionList';
import SectorList from '../../components/SectorList';
import ConfirmationRow from '../../components/Wizard/ConfirmationRow';
import LongText from '../../components/LongText';
import FAIcon from '../../components/Icon/FAIcon';
import MediaQuery from '../../components/MediaQuery';
import Link from '../../components/Link';
import EmptyMessage from '../../components/EmptyMessage/EmptyMessage';
import ReferFriend from '../../components/ReferFriend/ReferFriend';
import FaqLink from '../../components/Forms/Profile/FaqLink';
import { isOpportunityType, isCallType } from '../../actions/expertRequest';
import { darkGreen, sand, white } from '../../core/colors';
import { queryPart } from '../../core/util';

import { SCREEN_MD } from '../../core/muiTheme';
import s from './ExpertRequestPublic.module.scss';
import { useLoaderData } from 'react-router-dom';

function ExplainerBox({ expertRequest, viewer, tags }) {
  const isSignedIn = viewer && viewer.id;

  return (
    <div className={s.explainerBox}>
      <FAIcon iconSet="fal" icon="lightbulb" size={50} color={white} />

      <div className={s.explainerBoxBody}>
        <div className={s.explainerBoxTitle}>
          {`${
            isOpportunityType(expertRequest.er_type)
              ? 'Would you like to be considered for this opportunity?'
              : `Consultations like this typically pay $250/hr. ${
                  !isSignedIn
                    ? '1st calls usually result in additional calls.'
                    : ''
                }`
          }`}
        </div>
        <div className={s.explainerBoxText}>
          {isSignedIn ? (
            <div>
              {isOpportunityType(expertRequest.er_type)
                ? 'If it seems like the opportunity below would be a fit, click “I’m Interested” below.'
                : 'Most first calls result in several additional consultation calls.'}
            </div>
          ) : (
            <div>
              OnFrontiers is a next generation peer-to-peer learning platform
              that provides easy access to Experts around the globe. Chances
              are, you probably have significant experience with a particular
              sector, company, or region.
              <br />
              <br />
              We receive many opportunities just like this one every day from
              our clients and we often recruit top talent from external networks
              to meet their needs. Becoming an OnFrontiers expert is free and
              the application takes about{' '}
              {isOpportunityType(expertRequest.er_type)
                ? '10 minutes'
                : '5-7 minutes'}
              .
            </div>
          )}
        </div>
        <Link
          to={`${expertRequest.public_html_url}/request_add${queryPart({
            t: tags,
          })}`}
        >
          <Button size="normal" style={{ marginTop: 10 }}>
            I’m Interested
          </Button>
        </Link>
      </div>
    </div>
  );
}

function FAQ({ erType }) {
  const isCall = isCallType(erType);
  const articleUrlPrefix = 'https://support.onfrontiers.com/en/articles/';

  // FAQ component hidden as per OT-502
  return (
    <div className={s.faq} style={{ display: 'none' }}>
      <h3>Expert Consultation FAQ</h3>

      <div className={s.faqList}>
        <FaqLink
          title="Will this engagement conflict with my current employment?"
          url={`${articleUrlPrefix}5698180-will-this-call-conflict-with-my-current-employment`}
        />

        <FaqLink
          title="What is the time commitment?"
          url={`${articleUrlPrefix}5698184-what-is-the-time-commitment`}
          disable={!isCall}
        />

        <FaqLink
          title="Will the call be recorded?"
          url={`${articleUrlPrefix}5698188-will-the-call-be-recorded`}
          disable={!isCall}
        />

        <FaqLink
          title="How will I be paid?"
          url={`${articleUrlPrefix}705853-how-do-i-get-paid-as-an-onfrontiers-expert`}
        />

        <FaqLink
          title="How much will I be paid?"
          url={`${articleUrlPrefix}1346873-how-much-will-i-get-paid-where-do-i-set-my-rate`}
          disable={!isCall}
        />

        <FaqLink
          title="How will I be connected to the client?"
          url={`${articleUrlPrefix}5698189-how-will-i-be-connected-to-the-client`}
          disable={!isCall}
        />
      </div>
    </div>
  );
}

function IconText({ icon, text, style }) {
  return (
    !!text && (
      <div className={s.iconText} style={style}>
        <FAIcon
          icon={icon}
          size={24}
          style={{ marginRight: 10 }}
          color={darkGreen}
        />
        {text}
      </div>
    )
  );
}

const ExpertRequestPublic = (props) => {
  const loaderProps = useLoaderData();
  const { viewer } = props;

  if (!loaderProps) return <div />;
  const { expertRequest, action, tags } = loaderProps;

  const {
    name,
    description,
    regions,
    sectors,
    focus_areas: focusAreas,
    public_group_name: publicGroupName,
    state,
    questions,
    qualifications,
    group_about: groupAbout,
    job_scope: jobScope,
    opportunity_location: opportunityLocation,
    disclosure,
    project,
    er_type: erType,
  } = expertRequest;

  if (state === 'closed') {
    return (
      <Layout verticalCenter>
        <Body>
          <EmptyMessage
            border={false}
            style={{ padding: '30px 0 15px' }}
            icon={<FAIcon icon="archive" color={sand} size={52} />}
            title="This opportunity has been closed."
            body="Our client has concluded scheduling consultations for this opportunity."
            action={
              viewer && viewer.id ? (
                <Button size="large" href="/dashboard">
                  Go to Dashboard →
                </Button>
              ) : (
                <Button
                  size="large"
                  href={`/signup/expert${queryPart({ t: tags })}`}
                >
                  Sign Up to Receive More Opportunities
                </Button>
              )
            }
          />
        </Body>
      </Layout>
    );
  }

  const groupName =
    disclosure === 'full' && project?.group
      ? project.group.name
      : publicGroupName?.trim();

  return (
    <Layout contentClassName={s.layoutContent}>
      <Body className={s.body}>
        <div className={s.content}>
          <div className={s.details}>
            <h2 className={s.name}>{name}</h2>

            <div style={{ display: 'flex', marginTop: 10 }}>
              <IconText
                icon="map-marker-alt"
                text={opportunityLocation}
                style={{ marginRight: 30 }}
              />
              <IconText icon="briefcase" text={groupName} />
            </div>

            <div className={s.gutter} />

            <div>
              {(groupName || groupAbout) && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client profile"
                >
                  {groupName && `Client at ${groupName}`}

                  <div className={s.groupAbout}>
                    <LongText text={groupAbout} />
                  </div>
                </ConfirmationRow>
              )}

              {!!jobScope && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Job Scope"
                >
                  <LongText text={jobScope} />
                </ConfirmationRow>
              )}

              <ConfirmationRow
                separatorBelow
                separator
                titleClassName={s.confirmationRowTitle}
                title={
                  isOpportunityType(expertRequest.er_type)
                    ? 'Opportunity details'
                    : 'Project details'
                }
              >
                <SectorList
                  sectors={sectors.map((s) => s.name)}
                  style={{ marginTop: 10 }}
                />
                <RegionList
                  regions={regions.map((s) => s.name)}
                  style={{ marginTop: 5 }}
                />
              </ConfirmationRow>

              {focusAreas && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Areas of discussion"
                >
                  {focusAreas.join(', ')}
                </ConfirmationRow>
              )}

              <ConfirmationRow
                separatorBelow
                separator
                titleClassName={s.confirmationRowTitle}
                title="Type of expert client is requesting"
              >
                <LongText text={description} />
              </ConfirmationRow>

              {questions && questions.length > 0 && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client questions"
                >
                  {questions.map((q) => (
                    <div className={s.question} key={q.query}>
                      {q.query}
                    </div>
                  ))}
                </ConfirmationRow>
              )}

              {qualifications && qualifications.length > 0 && (
                <ConfirmationRow
                  separatorBelow
                  separator
                  titleClassName={s.confirmationRowTitle}
                  title="Client desired qualifications"
                >
                  {qualifications.map((q) => (
                    <div className={s.question} key={q.query}>
                      {q.query}
                    </div>
                  ))}
                </ConfirmationRow>
              )}
            </div>

            <MediaQuery maxWidth={SCREEN_MD}>
              {(isMobileVersion) => (
                <div
                  className={cx(s.submit, {
                    [s.sticky]: isMobileVersion && action !== 'refer',
                  })}
                >
                  <div>
                    {`If this opportunity seems like a fit for your experience, click the button below and our research team will be notified.${
                      isOpportunityType(expertRequest.er_type)
                        ? ' Introduction calls are not paid.'
                        : ''
                    }`}
                  </div>

                  <Link
                    to={`${
                      expertRequest.public_html_url
                    }/request_add${queryPart({ t: tags })}`}
                  >
                    <Button
                      size={isMobileVersion ? 'normal' : 'large'}
                      style={{ marginTop: 10 }}
                    >
                      I’m Interested
                    </Button>
                  </Link>
                </div>
              )}
            </MediaQuery>

            <FAQ erType={erType} />
          </div>

          <div>
            <ExplainerBox
              expertRequest={expertRequest}
              viewer={viewer}
              tags={tags}
            />

            <ReferFriend
              expertRequest={expertRequest}
              viewer={viewer}
              action={action}
              tags={tags}
            />
          </div>
        </div>
      </Body>

      <Customers />
    </Layout>
  );
};

const ExpertRequestPublicComponent = connect((state, ownProps) => ({
  viewer: state.viewer,
  expertRequest: (
    state.expertRequests.default.edges.find(
      (p) => p.node.id === ownProps.expertRequestId
    ) || {}
  ).node,
  ...ownProps,
}))(ExpertRequestPublic);

export default ExpertRequestPublicComponent;

import React from 'react';
import Grid from '@mui/material/Grid';
import { formatCreditsText } from '../../../core/util';
import { chargeLabels, isDurationEmpty } from '../../../core/consultation';
import Duration from '../../../core/duration';

function ClientView({
  costs,
  classes: { headerText, valueText } = {},
  hasSuggestedNewDuration,
  initialExpectedDuration,
  displayInitialExpectedDuration,
  initialClientExpertFee,
  displayExpectedDuration,
  clientExpertFee,
  isConfirmed,
  isClientNegotiating,
  isCompleted,
  isViewerRequester,
}) {
  let expertTime = (
    <>
      {displayInitialExpectedDuration} for{' '}
      <b>{formatCreditsText(initialClientExpertFee)}.</b>
    </>
  );

  if (hasSuggestedNewDuration && !isDurationEmpty(initialExpectedDuration)) {
    expertTime = (
      <>
        <p>The initial request was {expertTime}</p>
      </>
    );

    const value = (
      <>
        {`${displayExpectedDuration} for `}
        <b>{formatCreditsText(clientExpertFee)}.</b>
      </>
    );

    expertTime = (
      <>
        {expertTime}
        {isConfirmed || isCompleted ? (
          <>
            {isViewerRequester ? `You ` : `The client `}
            <b>accepted</b>{' '}
          </>
        ) : (
          `The expert has suggested `
        )}
        {value}

        {!isConfirmed && isClientNegotiating && (
          <p>Accept or cancel the consultation below.</p>
        )}
      </>
    );
  }

  return (
    <>
      {costs
        .filter((cost) => cost?.credits)
        .filter((cost) => cost.charge_type !== 'expert_time')
        .map((cost) => (
          <Grid item md={6} sm={12} key={cost.charge_type}>
            <div className={headerText}>{chargeLabels[cost.charge_type]}</div>
            <div className={valueText}>
              {formatCreditsText(Math.abs(cost.credits.cents))}
            </div>
          </Grid>
        ))}
      <Grid item md={6} sm={12}>
        <div className={headerText}>{chargeLabels.expert_time}</div>
        <div className={valueText}>{expertTime}</div>
      </Grid>
    </>
  );
}

function ExpertView({
  classes: { headerText, valueText } = {},
  hasSuggestedNewDuration,
  displayInitialExpectedDuration,
  initialExpertEarnings,
  isViewerExpert,
  displayExpectedDuration,
  expertEarnings,
  isConfirmed,
  isCompleted,
}) {
  let earnings = (
    <>
      <b>{displayInitialExpectedDuration}</b> for{' '}
      <b>${(initialExpertEarnings / 100).toFixed(2)}</b>.
    </>
  );

  if (hasSuggestedNewDuration) {
    earnings = (
      <p>
        The client has set the initial expected duration to{` `}
        {earnings}
      </p>
    );

    const value = (
      <>
        <b>{displayExpectedDuration}</b> for{' '}
        <b>${(expertEarnings / 100).toFixed(2)}</b>.
      </>
    );

    earnings = (
      <>
        {earnings}
        <p>
          {isConfirmed || isCompleted ? (
            <>
              The client has <b>accepted</b>{' '}
            </>
          ) : (
            <>{isViewerExpert ? `You have` : `The expert has`} suggested </>
          )}
          {value}
        </p>
      </>
    );
  }

  return (
    <Grid item md={6} sm={12}>
      <div className={headerText}>Earnings</div>
      <div className={valueText}>{earnings}</div>
    </Grid>
  );
}

export default function CostsAndPayout(props) {
  const {
    expectedDurationHours,
    initialExpectedDuration,
    isViewerExpert,
    isViewerAdmin,
    isClientNegotiating,
    isExpertNegotiating,
  } = props;

  const initialExpectedHours = Duration.parse(initialExpectedDuration).hours();
  const displayInitialExpectedDuration = `${initialExpectedHours} Hour${
    initialExpectedHours > 1 ? 's' : ''
  }`;
  const displayExpectedDuration = `${expectedDurationHours} Hour${
    expectedDurationHours > 1 ? 's' : ''
  }`;
  const hasSuggestedNewDuration =
    displayInitialExpectedDuration !== displayExpectedDuration;

  const newProps = {
    ...props,
    initialExpectedHours,
    displayExpectedDuration,
    displayInitialExpectedDuration,
    hasSuggestedNewDuration,
    isNegotiating: isClientNegotiating || isExpertNegotiating,
  };

  return (
    <>
      {(isViewerExpert || isViewerAdmin) && <ExpertView {...newProps} />}

      {!isViewerExpert && <ClientView {...newProps} />}
    </>
  );
}

import cx from 'classnames';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { inviteExpert, isOpportunityType } from '../../actions/expertRequest';
import { notify } from '../../actions/ui';
import { SCREEN_MD } from '../../core/muiTheme';
import { buildRefUrl } from '../../core/expertRequest';
import { isEmailValid, queryPart } from '../../core/util';
import { useApp } from 'hooks/useAppContext';
import Button from '../Button/Button';
import Form from '../Form';
import { TextField } from '../FormAdapters';
import MediaQuery from '../MediaQuery';
import ShareableLink from '../ShareableLink/ShareableLink';
import s from './ReferFriend.module.scss';

function ReferFriend(props) {
  const { location } = useApp();
  const {
    expertRequest,
    notify,
    reset,
    inviteExpert,
    viewer,
    action,
    handleSubmit,
    tags,
  } = props;

  const handleInviteExpert = useCallback(
    (values) => {
      if (!values.email) {
        notify('Please enter an email address.', 'error');
        return;
      }

      if (!isEmailValid(values.email)) {
        notify('Invalid email address.', 'error');
        return;
      }

      inviteExpert(expertRequest.slug, values.email)
        .then(() => {
          notify('Invite sent!', 'success');
          reset();
        })
        .catch((err) => {
          notify('An error occurred when sending invite.', 'error');
          throw err;
        });
    },
    [expertRequest, notify, reset, inviteExpert]
  );

  const url = buildRefUrl(location, expertRequest, viewer.id, 'linkedin');
  const isSignedIn = viewer && viewer.id;

  const signupQuery = queryPart({ t: tags });
  const objectName = isOpportunityType(expertRequest.er_type)
    ? 'opportunity'
    : 'project';
  const title = `Refer this ${objectName} to a friend`;

  return (
    <MediaQuery maxWidth={SCREEN_MD}>
      {(isMobileVersion) => (
        <div
          className={cx(s.action, {
            [s.sticky]: isMobileVersion && action === 'refer',
          })}
        >
          <div className={s.actionTitle}>{title}</div>

          <p className={s.actionSubTitle}>
            Receive a $100 bonus if they do a call
          </p>

          <div>
            {isSignedIn && (
              <Form
                onSubmit={handleSubmit(handleInviteExpert)}
                style={{ display: 'flex', 'align-items': 'flex-end' }}
              >
                <Field
                  id="inviteExpertEmail"
                  name="email"
                  component={TextField}
                  fullWidth={isMobileVersion}
                  style={{
                    width: 'calc(100% - 110px)',
                    marginRight: 10,
                    textAlign: 'left',
                  }}
                  placeholder="Enter email address"
                />
                <Button type="submit" size="small" style={{ width: 100 }}>
                  Send
                </Button>
              </Form>
            )}
            {isSignedIn ? (
              <ShareableLink
                url={url}
                enableShorten={false}
                inputStyle={{ width: 'calc(100% - 111px)' }}
                buttonStyle={{ width: 100 }}
              />
            ) : (
              <Button
                href={`${expertRequest.public_html_url}/refer/signup${signupQuery}`}
                size="small"
              >
                Log in to refer a friend
              </Button>
            )}
          </div>
        </div>
      )}
    </MediaQuery>
  );
}

ReferFriend = reduxForm({
  form: 'inviteExpert',
})(ReferFriend);

ReferFriend = connect(undefined, {
  inviteExpert,
  notify,
})(ReferFriend);

export default ReferFriend;

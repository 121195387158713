/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date object */
  Date: { input: any; output: any; }
  /** The `DateTime` scalar type represents a DateTime. The DateTime is serialized as an RFC 3339 quoted string */
  DateTime: { input: any; output: any; }
  /** A datetime object */
  Datetime: { input: any; output: any; }
  /** A duration object */
  Duration: { input: any; output: any; }
  Json: { input: any; output: any; }
};

/** Plan of a billing account. */
export enum AccountType {
  /** Advanced plan. */
  Advanced = 'advanced',
  /** Basic plan. */
  Basic = 'basic',
  /** Enterprise plan. */
  Enterprise = 'enterprise'
}

export type AgreementInput = {
  accepted: Scalars['Boolean']['input'];
  policy: Scalars['String']['input'];
  updated_at: Scalars['Datetime']['input'];
};

/** The role of the author while recording a note on a candidate */
export enum AuthorRole {
  /** Note written by a client */
  Client = 'client',
  /** Note written by the expert himself */
  Expert = 'expert',
  /** Note written by a Research Manager */
  Rm = 'rm'
}

/** The state for the billing account. */
export enum BillingAccountState {
  /** Billing account is active */
  Active = 'active',
  /** Billing account is inactive */
  Inactive = 'inactive',
  /** Billing account is on trial mode */
  Trial = 'trial'
}

/** The payment type for the billing account. */
export enum BillingAccountType {
  /** Billing account is charged after purchases */
  Postpaid = 'postpaid',
  /** Billing account is charged before purchases */
  Prepaid = 'prepaid'
}

/** Entity type of billing account. */
export enum BillingEntityType {
  /** Individual. */
  Individual = 'individual',
  /** Organization. */
  Organization = 'organization'
}

export type CandidateClientNoteInput = {
  is_good_match?: InputMaybe<IsGoodMatch>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type CandidateMatchNoteInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  recommended?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A screening query response. */
export type CandidateQueryResponseInput = {
  /** A response to a Yes/No response query. */
  can_answer?: InputMaybe<Scalars['Boolean']['input']>;
  /** The query ID. */
  query_id?: InputMaybe<Scalars['String']['input']>;
  /** The response to a query. */
  text_response?: InputMaybe<Scalars['String']['input']>;
};

/** Expert candidate state. */
export enum CandidateState {
  /** Expert contacted to check interest. */
  Contacted = 'contacted',
  /** Expert interested in request. */
  Interested = 'interested',
  /** Expert is a match to the request. */
  Matched = 'matched',
  /** Expert profile being polished to present to client. */
  Polishing = 'polishing',
  /** Match has been rejected by client. */
  RejectedByClient = 'rejected_by_client',
  /** Match has been rejected by OnFrontiers. */
  RejectedByResearch = 'rejected_by_research',
  /** Expert did not pass the suggestion phase. */
  RejectedSuggestion = 'rejected_suggestion',
  /** Expert auto suggested by the platform. */
  SuggestedByPlatform = 'suggested_by_platform',
  /** Expert suggested by research manager. */
  SuggestedByResearch = 'suggested_by_research',
  /** Expert has been verified for the request. */
  Verified = 'verified',
  /** Expert is being vetted by OnFrontiers. */
  Vetting = 'vetting'
}

/** Conference carrier type. */
export enum CarrierType {
  /** Plivo carrier. */
  Plivo = 'plivo',
  /** Twilio carrier. */
  Twilio = 'twilio',
  /** Zoom carrier. */
  Zoom = 'zoom'
}

/** The origin of the client information. */
export enum ClientSource {
  /** Main public OnFrontiers site. */
  Default = 'default',
  /** OnFrontiers.org site. */
  Org = 'org'
}

/** State of the consultation */
export enum ConsultationState {
  /** The consultation is pending the client's approval of expert review. */
  AwaitingClientAccept = 'awaiting_client_accept',
  /** The consultation is awaiting the client to join. */
  AwaitingClientJoin = 'awaiting_client_join',
  /** The consultation is awaiting the expert to join. */
  AwaitingExpertJoin = 'awaiting_expert_join',
  /** The consultation requester is waiting for an expert review. */
  AwaitingExpertReview = 'awaiting_expert_review',
  /** The consultation is awaiting the client and expert to join. */
  AwaitingJoin = 'awaiting_join',
  /** The consultation has been canceled. */
  Canceled = 'canceled',
  /** The proposed offer from the expert was rejected by the client. */
  ClientRejected = 'client_rejected',
  /** The consultation has been completed. */
  Completed = 'completed',
  /** The consultation has been confirmed. */
  Confirmed = 'confirmed',
  /** The consultation has been denied by the expert. */
  Denied = 'denied',
  /** The consultation has expired. */
  Expired = 'expired',
  /** The consultation is being finalized. */
  Finalizing = 'finalizing',
  /** The consultation is in progress. */
  InProgress = 'in_progress',
  /** The consultation was confirmed but never happened. */
  Incomplete = 'incomplete',
  /** Expert has proposed time and requester is confirming. */
  NegotiatingClientTime = 'negotiating_client_time',
  /** Requester has proposed time and expert is confirming. */
  NegotiatingExpertTime = 'negotiating_expert_time'
}

/** Type of the consultation */
export enum ConsultationType {
  /** Advisory */
  Advisory = 'advisory',
  /** Call */
  Call = 'call',
  /** Consulting Project */
  ConsultingProject = 'consulting_project',
  /** Contract Work */
  ContractWork = 'contract_work',
  /** In Person Consultation */
  InPersonConsultation = 'in_person_consultation',
  /** New Hire */
  NewHire = 'new_hire',
  /** Written Review */
  WrittenReview = 'written_review'
}

/** User actions that originally triggered the consultation update */
export enum ConsultationUpdateTrigger {
  /** Action was triggered from a Consultation Page UI element. */
  ConsultationPage = 'consultationPage',
  /** Action was triggered from an email notification */
  EmailLink = 'emailLink'
}

export type ConsultationsWithInput = {
  /** Consultations in projects from these groups */
  group_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Consultations with me */
  me?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CurrentRestriction {
  /** All matching values should be in the past */
  AllPast = 'all_past',
  /** At least one matching value should be current */
  AnyCurrent = 'any_current',
  /** No restriction, all/any matching value may be current or past */
  None = 'none'
}

/** Confidentiality agreement option. */
export enum DisclosureOption {
  /** Full discloure of name and company. */
  Full = 'full',
  /** Discloure of name only. */
  Name = 'name',
  /** No disclosure of name or company. */
  Private = 'private'
}

export type EducationInput = {
  degree?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  field_of_study?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  school?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};

/** The type of element in a monologue. */
export enum ElementType {
  /** Marks the element as an annotated type, like inaudible, for instance */
  Tag = 'tag',
  /** Marks the element as a transcribed text. */
  Text = 'text'
}

/** The email validation action. */
export enum EmailValidationAction {
  /** Whether the address should be confirmed by the primary address */
  Confirm = 'confirm',
  /** Whether the address should be verified */
  Verify = 'verify'
}

export type EngagementAttachmentInput = {
  description: Scalars['String']['input'];
  file_url: Scalars['String']['input'];
  hide_from_experts?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

/** The type of engagement for the consultation. */
export enum EngagementType {
  /** Traditional call at billable rate. */
  Consultation = 'consultation',
  /** Introductory call with only a booking fee. */
  Opportunity = 'opportunity',
  /** Written response at fixed rate. */
  WrittenResponse = 'written_response'
}

export type ExperienceFieldInput = {
  accepted_values?: InputMaybe<Array<InputMaybe<ExperienceFilterInput>>>;
  current_restriction: CurrentRestriction;
};

export type ExperienceFilterInput = {
  end_date?: InputMaybe<Scalars['Datetime']['input']>;
  start_date?: InputMaybe<Scalars['Datetime']['input']>;
  text: Scalars['String']['input'];
};

export type ExperienceInput = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  linkedin_url?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Expert history filter option. */
export enum ExpertHistory {
  /** Expert completed a consultation at OnFrontiers */
  CompletedConsultation = 'completed_consultation',
  /** Expert was matched by a client */
  Matched = 'matched',
  /** Expert was verified by research */
  Verified = 'verified',
  /** Expert was vetted by research */
  Vetting = 'vetting'
}

export type ExpertRequestQueryInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  response_type?: InputMaybe<QueryResponseType>;
};

/** State of the expert request. */
export enum ExpertRequestState {
  /** Expert Request is inactive. */
  Closed = 'closed',
  /** Expert Request is still ongoing. */
  Open = 'open'
}

/** Type of the expert request. */
export enum ExpertRequestType {
  /** Connect with an Expert by a call. */
  Consultation = 'consultation',
  /** Hire an Expert for a short or long-term consulting project. */
  ConsultingProject = 'consulting_project',
  /** Hire an Expert in a permanent role. */
  NewHire = 'new_hire',
  /** Connect with an Expert by a pro-bono call. */
  ProBonoConsultation = 'pro_bono_consultation',
  /** Hire an Expert for a written review of provided documents. */
  WrittenReview = 'written_review'
}

export type ExpertShowcaseCategoryInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  sections?: InputMaybe<Array<InputMaybe<ExpertShowcaseSectionInput>>>;
};

export type ExpertShowcaseCtaInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ExpertShowcaseProfileInput = {
  badge_text?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  experience?: InputMaybe<Scalars['String']['input']>;
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  marketing_keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ExpertShowcaseSectionInput = {
  cta_label?: InputMaybe<Scalars['String']['input']>;
  cta_url?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  profiles?: InputMaybe<Array<InputMaybe<ExpertShowcaseProfileInput>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Expert's application state */
export enum ExpertState {
  /** Expert is approved and active. */
  Active = 'active',
  /** Expert has applied. */
  Applied = 'applied',
  /** Expert is applying. */
  Applying = 'applying',
  /** User did not pass expert application. */
  Denied = 'denied',
  /** Expert is inactive. */
  Inactive = 'inactive'
}

/** Attachment file type. */
export enum FileType {
  /** CHAT text. */
  Chat = 'CHAT',
  /** M4A Audio. */
  M4A = 'M4A',
  /** MP3 Audio. */
  Mp3 = 'MP3',
  /** MP4 Video. */
  Mp4 = 'MP4',
  /** TRANSCRIPT text. */
  Transcript = 'TRANSCRIPT'
}

export type GroupDomainSignupAgreementInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  policy_code: Scalars['String']['input'];
  policy_label: Scalars['String']['input'];
  policy_url: Scalars['String']['input'];
};

/** The state of a group member. */
export enum GroupMemberState {
  /** The group member is active. */
  Active = 'active',
  /** The group member is not active in the group and has its approval pending. */
  AwaitingApproval = 'awaiting_approval'
}

/** Role of a member of a group. */
export enum GroupRole {
  /** An admin of the group. */
  Admin = 'admin',
  /** A regular member of the group. */
  Member = 'member',
  /** The owner of the group. */
  Owner = 'owner'
}

export type GroupSavedSearchInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** The collection to which a user may be invited. */
export enum InvitationCollectionType {
  /** User invited to become member of a group. */
  Group = 'group',
  /** User invited to become an expert to an internal network. */
  InternalNetworkExperts = 'internal_network_experts',
  /** User invited to become member of a project. */
  Project = 'project'
}

/** The author inclination on accepting the expert as a match for the request on the moment the note was written. This is usually only present for client notes. */
export enum IsGoodMatch {
  /** Candidate is maybe a good match according to author. */
  Maybe = 'maybe',
  /** Candidate is not a good match according to author. */
  No = 'no',
  /** Candidate is a good match according to author. */
  Yes = 'yes'
}

export type LandingPageBlogPostInputType = {
  author?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LandingPageCtaInputType = {
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LandingPageEngageCtaInputType = {
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type LandingPageEngageDetailInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type LandingPageValuePropositionInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Expert language */
export enum LanguageFluency {
  /** Elementary proficiency. */
  Elementary = 'elementary',
  /** Full professional proficiency. */
  FullProfessional = 'full_professional',
  /** Limited working proficiency. */
  LimitedWorking = 'limited_working',
  /** Native or bilingual proficiency. */
  NativeOrBilingual = 'native_or_bilingual',
  /** Professional working proficiency. */
  ProfessionalWorking = 'professional_working'
}

export type LanguageInput = {
  code: Scalars['String']['input'];
  fluency?: InputMaybe<LanguageFluency>;
};

export enum MarketplacePreference {
  /** Match experts regardless of this setting */
  None = 'none',
  /** Match only experts that are part of the marketplace */
  OptedIn = 'opted_in',
  /** Match only experts that are not part of the marketplace */
  OptedOut = 'opted_out'
}

/** Represents a monetary amount */
export type MoneyInput = {
  cents: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

/** The method used to add an expert to the network. */
export enum NetworkExpertAddMethod {
  /** The expert completed a consultation for the network */
  Consultation = 'consultation',
  /** The expert signed up from client's register page */
  DomainSignup = 'domain_signup',
  /** A group shared the expert with the network */
  GroupShare = 'group_share',
  /** The expert (lead) was automatically added to the network by a sync agent (such as csv import or partner integration) */
  Integration = 'integration',
  /** The expert was invited to the network (email invitation) */
  Invitation = 'invitation',
  /** The expert was manually added to the network by an admin */
  Manual = 'manual'
}

/** The state of the connection. */
export enum NetworkExpertConnectionState {
  /** The connection is active. */
  Active = 'active',
  /** The connection is awaiting acceptance from the expert. */
  AwaitingAcceptance = 'awaiting_acceptance',
  /** The connection was rejected by the expert. */
  Rejected = 'rejected',
  /** The connection was removed by the expert. */
  Removed = 'removed'
}

/** The network participation level (shared or member). */
export enum NetworkExpertParticipationLevel {
  /** The expert is a member of the network */
  Member = 'member',
  /** The expert was shared from another network */
  Shared = 'shared'
}

/** The URL type for account URLs. */
export enum PayoutAccountUrlType {
  /** Account onboarding URL */
  AccountOnboarding = 'account_onboarding',
  /** Account update URL */
  AccountUpdate = 'account_update'
}

export type PermissionRequestInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
};

/** How a profile will be merged into the profile database */
export enum ProfileResolution {
  /** Conflict with existing expert profile needs manual merging */
  ConflictExpert = 'conflict_expert',
  /** Conflict with existing lead profile needs manual merging */
  ConflictLead = 'conflict_lead',
  /** The profile contains errors and will not be imported. */
  Discarded = 'discarded',
  /** Conflicts were automatically merged */
  Merged = 'merged',
  /** The profile appears to be a brand new one. No need for merging */
  New = 'new'
}

/** The type of the profile. */
export enum ProfileType {
  /** The profile owner is not yet a user of the platform */
  Lead = 'lead',
  /** The profile belongs to an existing platform user */
  User = 'user'
}

/** Member state. */
export enum ProjectMemberState {
  /** Active member. */
  Active = 'active',
  /** Member awaiting owner's approval. */
  AwaitingApproval = 'awaiting_approval',
  /** Member denied by owner. */
  Denied = 'denied'
}

/** Role of a member of a project. */
export enum ProjectRole {
  /** A recruiting associate of a project. */
  Associate = 'associate',
  /** A research manager of a project. */
  Manager = 'manager',
  /** A regular member of a project. */
  Member = 'member',
  /** The owner of the project. */
  Owner = 'owner'
}

/** Supported response types for candidate queries. */
export enum QueryResponseType {
  /** Candidate to response with a free form text answer. */
  FreeForm = 'free_form',
  /** Candidate to respond with a boolean Yes/No answer. */
  YesNo = 'yes_no'
}

/** Attachment recording type. */
export enum RecordingType {
  /** Only view the active speaker. */
  ActiveSpeaker = 'active_speaker',
  /** Audio recording. */
  AudioOnly = 'audio_only',
  /** Audio transcript. */
  AudioTranscript = 'audio_transcript',
  /** Chat text file. */
  ChatFile = 'chat_file',
  /** View of multiple participants. */
  GalleryView = 'gallery_view',
  /** Only view the shared screen. */
  SharedScreen = 'shared_screen',
  /** View with shared screen and gallery. */
  SharedScreenWithGalleryView = 'shared_screen_with_gallery_view',
  /** View with shared screen and speaker. */
  SharedScreenWithSpeakerView = 'shared_screen_with_speaker_view'
}

export type RenderingArgsInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Whether the user intended to be a client or an expert. */
export enum SignupType {
  /** A client signed up. */
  Client = 'client',
  /** An expert signed up. */
  Expert = 'expert',
  /** An OnFrontiers staff member signed up. */
  Staff = 'staff'
}

/** The state of a transcription order. */
export enum StateEnum {
  /** The transcription order was canceled. No transcription will ever be available for this order. */
  Canceled = 'canceled',
  /** The transcription order is completed and the transcription may be retrieved. */
  Complete = 'complete',
  /** The transcription order has been received and should start being transcribed shortly. */
  Received = 'received',
  /** The transcription order is being transcribed. */
  Transcribing = 'transcribing'
}

/** The state of a transaction. */
export enum TransactionState {
  /** The transaction has been canceled. */
  Canceled = 'canceled',
  /** The transaction has been completed. */
  Completed = 'completed',
  /** The transaction is waiting for confirmation. */
  OnHold = 'on_hold'
}

export type CreditPurchaseFormensureUserBillingAccountMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreditPurchaseFormensureUserBillingAccountMutation = { __typename?: 'Mutation', ensureUserBillingAccount?: { __typename?: 'BillingAccount', id: string } | null };

export type CreditPurchaseFormCreateCheckoutUrlMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  path: Scalars['String']['input'];
}>;


export type CreditPurchaseFormCreateCheckoutUrlMutation = { __typename?: 'Mutation', createCheckoutURL?: string | null };


export const CreditPurchaseFormensureUserBillingAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"creditPurchaseFormensureUserBillingAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ensureUserBillingAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"user_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreditPurchaseFormensureUserBillingAccountMutation, CreditPurchaseFormensureUserBillingAccountMutationVariables>;
export const CreditPurchaseFormCreateCheckoutUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"creditPurchaseFormCreateCheckoutURL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"accountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"priceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"quantity"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"path"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCheckoutURL"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"account_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"accountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"price_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"priceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"quantity"},"value":{"kind":"Variable","name":{"kind":"Name","value":"quantity"}}},{"kind":"Argument","name":{"kind":"Name","value":"path"},"value":{"kind":"Variable","name":{"kind":"Name","value":"path"}}}]}]}}]} as unknown as DocumentNode<CreditPurchaseFormCreateCheckoutUrlMutation, CreditPurchaseFormCreateCheckoutUrlMutationVariables>;
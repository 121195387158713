import { redirect } from 'react-router-dom';
import config from '../../../config';
import {
  createPayoutAccountURLByUserAndType,
  createPayoutLoginURLByUser,
  payoutAccountByUserID,
} from '../../actions/payoutDashboard';
import { APIError } from '../../core/api';

export default {
  subdomain: '*',
  path: '/settings/payout',

  async action({ store }) {
    if (!config.enablePayoutDashboard) {
      return redirect('/404');
    }

    const { viewer } = store.getState();
    const userId = viewer.id;
    let payoutsEnabled = false;

    try {
      const account = await store.dispatch(payoutAccountByUserID(userId));
      payoutsEnabled = account.payouts_enabled;
    } catch (err) {
      if (err.message !== 'GraphQL Error: payout account not found') {
        throw err;
      }
      return redirect('/settings?error=account_not_found');
    }

    let redirectUrl;
    try {
      if (payoutsEnabled) {
        redirectUrl = await store.dispatch(createPayoutLoginURLByUser(userId));
      } else {
        redirectUrl = await store.dispatch(
          createPayoutAccountURLByUserAndType(userId, 'account_onboarding')
        );
      }
    } catch (err) {
      if (!(err instanceof APIError)) {
        throw err;
      }
      redirectUrl = '/settings?error=country_unsupported';
    }

    return redirect(redirectUrl);
  },
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EmptyMessage from '../../components/EmptyMessage';
import Button from '../../components/Button/Button';
import { darkBlue } from '../../core/colors';
import { track } from '../../actions/tracking';

class SearchPromo extends PureComponent {
  render() {
    const { track } = this.props;

    return (
      <div>
        <EmptyMessage
          style={{ maxWidth: 750, padding: 0 }}
          border={false}
          iconName="search"
          iconColor={darkBlue}
          title="Knowledge Network is an Enterprise feature"
          body="Upgrade to an Enterprise account to search your internal knowledge network. Reconnect with Experts, find key phrases within transcripts, and more."
          action={
            <Button
              size="normal"
              onClick={() => {
                track('promo.chat.messaging');
                window.HubSpotConversations?.widget?.open();
              }}
            >
              Start a Chat
            </Button>
          }
        />
      </div>
    );
  }
}

export default connect(undefined, { track })(SearchPromo);

/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { TextField } from '../../FormAdapters';
import { updateProfile } from '../../../actions/profile';
import { notify } from '../../../actions/ui';
import Form from '../../Form';
import { normalizeSpace } from '../../../core/util';

function validate(values = {}) {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'Required';
  }

  if (!values.last_name) {
    errors.last_name = 'Required';
  }

  return errors;
}

class NameForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  handleSubmit = async (values) => {
    const { profileId, updateProfile, onSubmit, notify } = this.props;
    const { first_name, last_name } = values;

    await updateProfile({
      id: profileId,
      name: `${first_name} ${last_name}`,
      ...values,
      first_name: first_name.trim(),
      last_name: last_name.trim(),
    }).catch((err) => {
      if (err && err.message) {
        if (err.message === 'GraphQL Error: invalid first name') {
          throw new SubmissionError({ first_name: 'Invalid name' });
        }
        if (err.message === 'GraphQL Error: invalid last name') {
          throw new SubmissionError({ last_name: 'Invalid name' });
        }
      }
      notify('An error occurred when updating the profile.', 'error');
    });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;

    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container = Form,
      nextUrl,
      handleSubmit,
      user,
      ...other
    } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          component={TextField}
          fullWidth
          name="first_name"
          label="First Name"
          normalize={normalizeSpace}
        />
        <Field
          component={TextField}
          fullWidth
          name="last_name"
          label="Last Name"
          normalize={normalizeSpace}
        />
      </Container>
    );
  }
}

NameForm = reduxForm({
  form: 'expertName',
  validate,
  enableReinitialize: true,
})(NameForm);

NameForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        first_name: profile.first_name,
        last_name: profile.last_name,
      },
    };
  },
  {
    updateProfile,
    notify,
  }
)(NameForm);

export default NameForm;

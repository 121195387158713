import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import FormLabel from '@mui/material/FormLabel';
import LabelledOutline from '../LabelledOutline';
import { RadioGroup, helperText } from '../FormAdapters';

// TODO: Move this to its own components file if we plan to use it in other
// locations
const FreeFormInput = ({
  label,
  helperText,
  required,
  error,
  disabled,
  FormLabelProps,
  inputProps,
  FormHelperTextProps,
  ...other
}) => {
  return (
    <FormControl required={required} error={error} disabled={disabled}>
      {label && <FormLabel {...FormLabelProps}>{label}</FormLabel>}

      <Input {...other} {...inputProps} />

      {helperText && (
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

// TODO: move this to FormAdapters
const WrappedFreeformInput = ({ input, meta, ...rest }) => {
  const [value, setValue] = useState(input.value);

  useEffect(() => {
    if (value !== input.value) setValue(input.value);
  }, [input.value]);

  return (
    <FreeFormInput
      {...rest}
      {...helperText(meta, rest)}
      name={input.name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        input.onChange(e);
        input.onBlur(e);
      }}
    />
  );
};

function Queries({ fields, field, queries, disabled, style }) {
  const FieldComponent = field || Field;
  // patch for react-final-form
  if (fields.get === undefined) {
    fields.get = (index) => fields.value[index];
  }

  const validationFunctions = queries.map((q) => {
    return useCallback(
      (value) => {
        if (
          (value === null || value === undefined || value === '') &&
          q.required
        ) {
          return 'Required';
        }
      },
      [queries]
    );
  });

  return fields
    .map((name, index) => {
      const query = queries.find(
        (q) =>
          q.id === fields.get(index).query_id ||
          // preserve compatibility with both input formats
          q.id === fields.get(index).query?.id
      );

      if (!query) return;

      const last = index === fields.length - 1;

      const rootStyle = style || {};

      if (disabled) {
        rootStyle.cursor = 'default';
      }
      if (!last) {
        rootStyle.marginBottom = '15px';
      }

      let component;
      if (query.response_type === 'yes_no') {
        component = (
          <FieldComponent
            component={RadioGroup}
            validate={validationFunctions[index]}
            FormControlProps={{
              required: query.required,
              disabled,
            }}
            allowNull
            disabled={disabled}
            name={`${name}.can_answer`}
            label={`${index + 1}. ${query.query}`}
            style={{
              flexDirection: 'row',
              fill: 'var(--primary) !important',
            }}
            // Field converts booleans to strings with the RadioGroup component
            // so coerce them back to booleans on input while allowing null
            format={(v) =>
              v === undefined || v === null
                ? null
                : typeof v === 'boolean'
                  ? v
                  : v === 'true'
            }
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        );
      }
      if (query.response_type === 'free_form') {
        component = (
          <FieldComponent
            component={WrappedFreeformInput}
            validate={validationFunctions[index]}
            required={query.required}
            disabled={disabled}
            multiline
            name={`${name}.text_response`}
            label={`${index + 1}. ${query.query}`}
            placeholder="Please limit your response to one or two sentences"
            inputProps={{ maxLength: 255 }}
          />
        );
      }
      return (
        <LabelledOutline
          key={name}
          style={{
            ...rootStyle,
          }}
        >
          {component}
        </LabelledOutline>
      );
    })
    .filter(Boolean);
}

export default Queries;

import { Header } from 'components/Header';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  messagingUnreadCount,
  prefetchFirstChannel,
  updateUnreadCount,
} from '../../actions/messaging';
import { setUserContext } from '../../actions/ui';
import { fetchConflictsCount } from '../../actions/profile';
import { useApp } from 'hooks/useAppContext';
import SelectGroup from '../SelectGroup';
import navItemsBuilder from './navItemsBuilder';
import { useMemo } from 'react';
import { BadgeCount } from './UserMenu';
import { setLoadingProgress } from '../../actions/loading';
import Button from '../Button/Button';

function UserContextOption({ option }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {option.name}
      {option.description && (
        <span
          style={{
            marginLeft: 5,
          }}
        >
          ({option.description})
        </span>
      )}
    </div>
  );
}

function AccessHeader({
  viewer,
  unreadCount,
  userContext,
  userContextOptions,
  conflictsCount,
  SearchComponent,
  loadedChannels,
  setUserContext,
  fetchConflictsCount,
  setLoadingProgress,
  shouldShowNewRequest,
}) {
  const navigate = useNavigate();
  const unsubscribeRef = useRef();
  const { apiWebSocket, store } = useApp();
  const [navItems, setNavItems] = useState([]);

  const handleChangeContext = useCallback((userContext) => {
    setUserContext(userContext);
    if (
      userContext === 'expert' &&
      window.location.pathname === '/expert_requests'
    ) {
      navigate('/dashboard');
    } else {
      navigate(window.location);
    }
    store.dispatch(setLoadingProgress(10));
  }, []);

  const handleGroupContext = (value) => {
    if (!value) return;
    handleChangeContext(value);
  };

  const handleNavigate = (path) => {
    store.dispatch(setLoadingProgress(10));
    navigate(path);
  };

  let userMenuItems = [
    ...(userContextOptions || []).map((option, index, items) => ({
      key: option,
      text: <UserContextOption option={option} />,
      divider: !viewer.admin && index === items.length - 1,
      onClick: () => {
        handleChangeContext(option.value);
      },
    })),
    {
      key: 'dashboard',
      text: 'Dashboard',
      onClick: () => {
        handleNavigate('/dashboard');
      },
    },
    {
      key: 'profile',
      text: 'View my profile',
      onClick: () => {
        handleNavigate(`/profile/${viewer.username}`);
      },
      divider: true,
    },
    {
      key: 'profile-uploader',
      text: 'Profile Uploader',
      onClick: () => {
        handleNavigate(`/profile-uploader`);
      },
    },
    {
      key: 'profile-conflicts',
      text: (
        <div className="flex items-center">
          Profile Conflicts <BadgeCount count={conflictsCount} />
        </div>
      ),
      onClick: () => {
        handleNavigate(`/profile-conflicts`);
      },
      divider: true,
    },
  ];

  if (shouldShowNewRequest) {
    userMenuItems.push({
      key: 'expert-requests',
      text: <span className="text-brand-tertiary">Find Experts</span>,
      onClick: () => {
        handleNavigate('/request_expert');
      },
    });
  }

  const hasGroups = viewer.groups && viewer.groups.length > 0;
  const isAccountEnterprise =
    viewer.groups && viewer.groups.find((g) => g.account_type === 'enterprise');
  useEffect(() => {
    if (!viewer.id) return;

    const items = navItemsBuilder({
      profileUrl: viewer.html_url ? new URL(viewer.html_url).pathname : '/',
      unreadMessages: unreadCount > 0 ? unreadCount : null,
      userContext: userContext,
      isAdmin: viewer.admin,
      hasGroups,
      isAccountEnterprise,
      hasExpertState: !!viewer.expert_state,
      conflictsCount,
      shouldShowNewRequest,
    });
    setNavItems(
      items.map((item) => ({
        ...item,
        onClick: () => handleNavigate(item.to),
      }))
    );
  }, [viewer, userContext, unreadCount, conflictsCount]);

  useEffect(() => {
    if (loadedChannels) {
      unsubscribeRef.current = apiWebSocket.on(
        {
          service: messagingUnreadCount.service,
          queue: messagingUnreadCount.queue,
          resource: viewer.id,
        },
        handleNewUnreadCount
      );
    }

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [loadedChannels]);

  useEffect(() => {
    if (!viewer.id) return;

    prefetchFirstChannel();
    fetchConflictsCount();
  }, [viewer]);

  const handleNewUnreadCount = useCallback((payload) => {
    updateUnreadCount(payload.count);
  }, []);

  if (hasGroups) {
    userMenuItems.splice(userContextOptions?.length + 2, 0, {
      key: 'teams',
      text: 'Manage My Teams',
      to: '/teams',
    });
  }

  if (userContext === 'admin' && viewer.admin) {
    userMenuItems.splice(userContextOptions?.length + 2, 0, {
      key: 'applications',
      text: 'View Applications',
      to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
    });
  }

  if (viewer.admin) {
    userMenuItems.splice(userContextOptions?.length, 0, {
      key: 'context-selector',
      text: (
        <SelectGroup
          autoComplete
          autoFocus
          onChange={handleGroupContext}
          className="m-0 w-full p-0"
          margin="none"
        />
      ),
      handleKeyDown: (e) => {
        e.stopPropagation();
      },
      divider: true,
    });
  }

  viewer.full_name = useMemo(() => {
    const context = userContextOptions?.find((o) => o.value === userContext);

    if (!context) return `${viewer.first_name} ${viewer.last_name}`;

    return (
      context.name + (context.description ? ` (${context.description})` : '')
    );
  }, [viewer, userContext, userContextOptions]);

  return (
    <Header
      userData={viewer}
      isAuthenticated={!!viewer.id}
      SearchComponent={SearchComponent}
      FindExpertsComponent={
        shouldShowNewRequest && (
          <Link to="/request_expert" className="hidden xl:block">
            <Button
              size="small"
              label="Find Experts"
              style={{ minWidth: 115 }}
            />
          </Link>
        )
      }
      navbarItems={navItems}
      userMenuItems={userMenuItems}
      logout={() => navigate('/logout')}
    />
  );
}

export default connect(
  (state) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    userContextOptions: state.ui.userContextOptions,
    unreadCount: state.messaging.unreadCount,
    loadedChannels: state.messaging.loadedChannels,
    conflictsCount: state.profiles.counts.conflicts,
  }),
  {
    prefetchFirstChannel,
    updateUnreadCount,
    setUserContext,
    fetchConflictsCount,
    setLoadingProgress,
  }
)(AccessHeader);

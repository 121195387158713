import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { TextField } from '../../FormAdapters';
import { updateProfile } from '../../../actions/profile';
import Form from '../../Form';

function validate(values = {}) {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required';
  }

  if (!values.summary) {
    errors.summary = 'Required';
  }

  if (String(values.title).length > 120) {
    errors.title = 'Headline may be at most 120 characters long';
  }

  if (String(values.summary).length > 2000) {
    errors.summary = 'Short bio may be at most 2000 characters long';
  }

  return errors;
}

class IntroductionForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  handleSubmit = (values) => {
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container = Form,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          id="introductionHeadline"
          component={TextField}
          fullWidth
          name="title"
          label="Headline"
          placeholder="Keep it brief"
          maxLength={120}
        />
        <Field
          id="introductionSummary"
          component={TextField}
          fullWidth
          multiline
          minRows={3}
          maxRows={10}
          name="summary"
          label="Short Bio"
          placeholder="Tell us briefly what you know, and how this came to be an area of expertise"
          maxLength={2000}
        />
      </Container>
    );
  }
}

IntroductionForm = reduxForm({
  form: 'expertIntroduction',
  validate,
  enableReinitialize: true,
})(IntroductionForm);

IntroductionForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        title: profile.title,
        summary: profile.summary,
      },
    };
  },
  {
    updateProfile,
  }
)(IntroductionForm);

export default IntroductionForm;

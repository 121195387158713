import { Divider as DividerMaterial } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function Divider(props) {
  const { spacing = 20, margin, color, style, height = 1, ...other } = props;

  const defaultStyle = {
    marginTop: spacing,
    marginBottom: spacing,
    marginLeft: margin,
    marginRight: margin,
    height,
    width: '100%',
  };

  if (color) {
    defaultStyle.borderColor = color;
    defaultStyle.backgroundColor = color;
  }

  return <DividerMaterial style={{ ...defaultStyle, ...style }} {...other} />;
}

Divider.propTypes = {
  spacing: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Divider;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MemberRequest from './MemberRequest';
import { updateProjectMember } from '../../actions/project';

class MemberRequests extends Component {
  state = {};

  render() {
    const { projectId, memberRequests, updateProjectMember } = this.props;

    return memberRequests.map((m) => (
      <MemberRequest
        key={m.id}
        user={m.user}
        membersSet="this project"
        onApprove={() =>
          updateProjectMember(projectId, { id: m.id, state: 'active' })
        }
        onDeny={() =>
          updateProjectMember(projectId, { id: m.id, state: 'denied' })
        }
      />
    ));
  }
}

MemberRequests = connect(undefined, {
  updateProjectMember,
})(MemberRequests);

export default MemberRequests;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import logoDark from './logo-black.png';
import logoLight from './logo-white.png';
import { primary } from '../../core/colors';

const useSizes = makeStyles((theme) => ({
  standard: ({ width, height }) => ({
    width: width || '213px',
    height: height || '20px',
    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '17px',
    },
  }),
  big: {
    width: '416px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      width: '180px',
      height: '17px',
    },
  },
  small: {
    width: '180px',
    height: '17px',
  },
}));

function Logo({
  user,
  dark = false,
  size = 'standard',
  style,
  width,
  height,
  ...other
}) {
  let logoUrl = dark ? logoDark : logoLight;
  let logoAlt = 'OnFrontiers';
  const sizeClass = useSizes({ width, height })[size];

  const groupLogo =
    user && user.groups && user.groups.find((g) => !!g.branding_logo_url);

  if (groupLogo) {
    logoUrl = groupLogo.branding_logo_url;
    logoAlt = groupLogo.public_name || 'Powered by OnFrontiers';
  }

  return (
    <img
      src={logoUrl}
      alt={logoAlt}
      className={sizeClass}
      fill={primary}
      style={{
        ...style,
      }}
      {...other}
    />
  );
}

export default Logo;

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { updateUser } from '../../actions/user';
import Article from '../../components/Article';
import Button from '../../components/Button/Button';
import { Checkbox } from '../../components/FormAdapters';
import MediaQuery from '../../components/MediaQuery';
import { SCREEN_SM } from '../../core/muiTheme';
import agreements, { matchAgreement, policies } from '../../core/agreements';
import s from './LegalAcknowledgement.module.scss';

function LegalAcknowledgement(props) {
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(() => {
    const { user, policyKeys } = props;
    return agreements(user.agreements).hasAccepted(...policyKeys);
  });
  const selPolicies = useMemo(() => {
    return (props.policyKeys || []).map((key) => policies[key]);
  }, [props.policyKeys]);

  const { updateUser, user, pages, aggregateAgreementName, note } = props;

  const handleAgree = (_, accepted) => {
    setAccepted(accepted);
  };

  const handleSubmit = async () => {
    if (!accepted()) throw new Error('legal should have been accepted');

    const agreements = user.agreements || [];
    const now = new Date();
    selPolicies.forEach((policy) => {
      let agreement = agreements.find((a) => matchAgreement(a.policy, policy));
      if (!agreement) {
        agreement = {};
        agreements.push(agreement);
      }
      agreement.policy = policy.apiKey;
      agreement.accepted = true;
      agreement.updated_at = now;
    });

    await updateUser({ id: user.id, agreements });
    setAccepted(null);
    navigate(nextUrl);
  };

  if (!selPolicies) {
    throw new Error('no policy to require acceptance');
  }

  const policyPages = selPolicies.map((p) => {
    const page = pages.find((x) => x.id === p.id);
    if (!page) throw new Error(`unable to find /${p.slug} blog entry`);
    return page;
  });

  return (
    <div>
      <MediaQuery maxWidth={SCREEN_SM}>
        {(smallScreen) => (
          <div>
            <div className={s.title}>
              Please take a moment to review and agree to our updated{' '}
              {aggregateAgreementName}.
            </div>
            <div className={s.legalContents}>
              <div key={policyPages[0].id}>
                <h1>{policyPages[0].title_text}</h1>
                <Article html={policyPages[0].content.rendered} />
              </div>
              {policyPages.slice(1).map((page) => (
                <div key={page.id}>
                  <hr />
                  <h1>{page.title_text}</h1>,
                  <Article html={page.content.rendered} />
                </div>
              ))}
            </div>
            {note && <div className={s.note}>{note}</div>}
            <Field
              type="checkbox"
              name="user"
              component={Checkbox}
              checked={accepted}
              label={`I have read and agreed to the above ${aggregateAgreementName}.`}
              labelStyles={{ fontWeight: 'bold' }}
              onChange={(e) => handleAgree(e, !accepted)}
            />
            <Button
              onClick={handleSubmit}
              size={smallScreen ? 'normal' : 'large'}
              type="submit"
              disabled={!accepted}
              style={{ marginTop: 10 }}
            >
              Accept
            </Button>
          </div>
        )}
      </MediaQuery>
    </div>
  );
}

LegalAcknowledgement = reduxForm({
  enableReinitialize: true,
  form: 'acceptedAgreements',
  validate: (values) => {
    const errors = {};
    if (!values.user) {
      errors.user = 'You must accept the privacy policy to proceed';
    }
  },
})(LegalAcknowledgement);

LegalAcknowledgement = connect(
  (state, ownProps) => ({
    user: state.users[ownProps.userId],
    pages: state.legal.pages,
  }),
  {
    updateUser,
  }
)(LegalAcknowledgement);

LegalAcknowledgement = LegalAcknowledgement;

export default LegalAcknowledgement;

import {
  Briefcase,
  Layout,
  MessageSquare,
  Star,
  User,
  UserPlus,
  Users,
} from 'react-feather';
import { BadgeCount } from './UserMenu';

// --- ITEMS TEMPLATE

const itemDashboard = Object.freeze({
  key: 'dashboard',
  text: 'Dashboard',
  to: '/dashboard',
  icon: Layout,
});

const itemProfile = Object.freeze({
  key: 'profile',
  text: 'Profile',
  to: '/profile',
  mobileOnly: true,
  icon: User,
});

const itemMessaging = Object.freeze({
  key: 'messaging',
  text: 'Messages',
  to: '/messaging',
  icon: MessageSquare,
});

const itemCalls = Object.freeze({
  key: 'consultations',
  text: 'Consultations',
  to: '/consultations',
  icon: Briefcase,
});

const itemExpertRequest = Object.freeze({
  key: 'expert_requests',
  text: 'Expert Requests',
  to: '/expert_requests',
  icon: Star,
  id: 'expertRequestsAdmin',
});

const itemMyClients = Object.freeze({
  key: 'my-network',
  text: 'My Clients',
  to: '/network',
  icon: Star,
});

const itemMyNetwork = Object.freeze({
  key: 'my-network',
  text: 'My Network',
  to: '/search',
  icon: Star,
});

const itemBecomeAnExpert = Object.freeze({
  key: 'signup/expert',
  text: 'Become an Expert',
  to: '/signup/expert',
  promo: true,
});

// --- BUILDER

const buildItems = ({
  profileUrl,
  unreadMessages,
  userContext,
  isAdmin,
  hasGroups,
  isAccountEnterprise,
  hasExpertState,
  conflictsCount,
  shouldShowNewRequest = false,
}) => {
  const newItemProfile = {
    ...itemProfile,
    to: profileUrl,
  };

  const newItemMessaging = {
    ...itemMessaging,
    text: <span>Messages {unreadMessages}</span>,
  };

  const subItems = [
    {
      key: 'profile-uploader',
      text: <span className="text-brand-secondary">Profile Uploader</span>,
      to: `/profile-uploader`,
    },
    {
      key: 'profile-conflicts',
      to: `/profile-conflicts`,
      text: (
        <div className="flex items-center text-brand-secondary">
          Profile Conflicts <BadgeCount count={conflictsCount} />
        </div>
      ),
    },
  ];

  if (hasGroups) {
    subItems.unshift({
      key: 'teams',
      text: <span className="text-brand-secondary">Manage My Teams</span>,
      to: '/teams',
    });
  }

  if (userContext === 'admin' && isAdmin) {
    subItems.unshift({
      key: 'applications',
      text: <span className="text-brand-secondary">View Applications</span>,
      to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
    });
  }

  const items = [itemDashboard, newItemProfile, newItemMessaging, itemCalls];

  if (userContext !== 'expert') {
    items.push(itemExpertRequest);
  }

  if (userContext === 'expert') {
    items.push(itemMyClients);
  } else if (!isAdmin && isAccountEnterprise) {
    items.push(itemMyNetwork);
  }

  if (!hasExpertState) {
    items.push(itemBecomeAnExpert);
  }

  items.push({
    key: 'my-account',
    text: 'My Account',
    mobileOnly: true,
    children: subItems,
  });

  if (shouldShowNewRequest) {
    items.push({
      key: 'expert-requests',
      text: <span className="text-brand-tertiary">Find Experts</span>,
      to: '/request_expert',
      mobileOnly: true,
    });
  }

  return items;
};

export default buildItems;

import { IDrawerProps } from '../types';
import MuiDrawer from '@mui/material/Drawer';

const testId = 'of-drawer';

const Drawer = ({
  className,
  children,
  onClose,
  size = 'md',
  ...rest
}: IDrawerProps) => {
  const widths = {
    xs: 430,
    sm: 600,
    md: 920,
    lg: 1200,
  };
  return (
    <MuiDrawer
      anchor="right"
      className={className}
      data-testid={testId}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: widths[size],
        },
      }}
      {...rest}
    >
      {typeof children === 'function' ? children(onClose) : children}
    </MuiDrawer>
  );
};

export type { IDrawerProps };
export { testId as DrawerTestId };
export default Drawer;

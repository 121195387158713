import React from 'react';
import { connect } from 'react-redux';
import LayoutPage from '../Layout/LayoutPage';
import MaterialIcon from '../Icon/MaterialIcon';
import { red500 } from '../../core/colors';
import { track } from '../../actions/tracking';
import s from './InaccessibleArchived.module.scss';
import Button from '../Button/Button';

function InaccessibleArchived({
  selectedTab,
  entity,
  entities,
  trackingCode,
  track,
}) {
  return (
    <LayoutPage showNav selected={selectedTab}>
      <div className={s.root}>
        <MaterialIcon style={{ color: red500 }} size={45} icon="archive" />

        <div className={s.title}>
          You do not have access to this archived {entity}.
        </div>

        <div className={s.contents}>
          Looks like you’re trying to access a {entity} over 90 days old. <br />
          Upgrade to our Advanced or Enterprise account to unlock archived{' '}
          {entities}.
        </div>

        <Button
          style={{ marginLeft: 10, marginTop: 20 }}
          onClick={() => {
            track(trackingCode);
            window.HubSpotConversations?.widget?.open();
          }}
        >
          Start a Chat
        </Button>
      </div>
    </LayoutPage>
  );
}

InaccessibleArchived = connect(undefined, {
  track,
})(InaccessibleArchived);

InaccessibleArchived = InaccessibleArchived;

export default InaccessibleArchived;

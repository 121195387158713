import IState from 'interfaces/state';
import { IUserStore } from 'interfaces/user';
import { IProjectStore } from 'interfaces/project';
import { IProfileStore } from 'interfaces/profile';
import { IProfilesByEmailStore } from 'interfaces/profilesByEmail';
import { IMyCollectionStore } from 'interfaces/myCollection';
import {
  PaginatedLanguageList,
  PaginatedSavedCollectionCollaboratorList,
  ProfileOnboardingTask,
} from 'services/openapi';
import { ICollection, ICollectionProfileList } from 'interfaces/collection';
import { ICollectionInfoStore } from 'interfaces/collectionInfoData';
import { IRawTitle } from 'interfaces/titleRawTerm';
import ICountries from 'interfaces/countries';
import UIStore from 'interfaces/ui';
import { IPaginatedProjectMemberList } from 'interfaces/paginatedProjectMemberList';
import { IPaginatedJobList } from 'interfaces/paginatedJobList';
import { IPaginatedLookUpList } from 'interfaces/paginatedLookupList';
import { SelectOption } from 'components/Select/types';
import { IProfileAwardsStore } from 'interfaces/profileAwards';
import { IProfileCertificationsStore } from 'interfaces/profileCertifications';
import { IProfilePublicationsStore } from 'interfaces/profilePublications';
import { IProjectList } from 'interfaces/paginatedProjectList';
import { IPaginatedMajorList } from 'interfaces/paginatedMajorList';
import { IDegree } from 'interfaces/degree';
import { GooglePlaceResponse } from 'interfaces/cities';
import IAddKnowledge from 'interfaces/addKnowledge';
import IManageExperiences from 'interfaces/manageExperiences';
import IManageProject from 'interfaces/manageProject';
import { IOnboarding } from 'interfaces/onboarding';
import IManageExperience from 'interfaces/manageExperience';
import { IAccrualSummary } from 'interfaces/accrualSummary';
import { ISearch } from 'interfaces/search';

const genericBaseEmptyState = {
  data: { results: undefined },
  isLoading: false,
  error: null,
  hasLoaded: false,
};

export default {
  profile: {
    data: null,
    isLoading: true,
    error: null,
    counts: {
      proposals: 0,
      projects: 0,
    },
  } as IProfileStore,
  profilesByEmail: {
    data: {
      count: 0,
      next: '',
      previous: '',
      results: [],
    },
    isLoading: false,
    error: null,
  } as IProfilesByEmailStore,
  assignmentToDelete: null,
  project: {
    data: null,
  } as IProjectStore,
  projectMembers: { data: null } as IPaginatedProjectMemberList,
  jobs: { data: null } as IPaginatedJobList,
  workHistory: { data: null } as IPaginatedJobList,
  majors: { data: null } as IPaginatedMajorList,
  countries: { data: null, isLoading: false, error: null } as ICountries,
  cities: {
    predictions: [],
    status: '',
    isLoading: false,
  } as GooglePlaceResponse,
  myCollection: {
    data: null,
    isLoading: false,
    error: null,
  } as IMyCollectionStore,
  collectionProfiles: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollectionProfileList,
  collections: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollection,
  collectionInfo: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollectionInfoStore,
  popularCollections: {
    data: null,
    isLoading: false,
    error: null,
  } as ICollection,
  collectionCollaborator: {} as PaginatedSavedCollectionCollaboratorList,
  languages: {} as PaginatedLanguageList,
  lookups: {} as IPaginatedLookUpList,
  addedLookupValues: {} as Record<string, SelectOption[]>,
  user: {
    data: null,
    isLoading: false,
    error: null,
  } as IUserStore,
  degreeTypes: { data: null } as IDegree,
  ui: {
    data: {
      dialog: null,
      dialogConfirmClose: false,
    },
    isLoading: false,
    error: null,
  } as UIStore,
  profileAwards: genericBaseEmptyState as IProfileAwardsStore,
  profileCertifications: genericBaseEmptyState as IProfileCertificationsStore,
  profilePublications: genericBaseEmptyState as IProfilePublicationsStore,
  projectList: {
    query: '',
    data: [],
    isLoading: false,
    error: null,
  } as IProjectList,
  titleRawTerm: genericBaseEmptyState as IRawTitle,
  onboarding: {
    tasks: [] as Array<ProfileOnboardingTask>,
    job: null,
  } as IOnboarding,
  addKnowledge: {
    opened: false,
    selectedKnowledge: null,
    lookupKnowledges: [],
    isLookupKnowledgeLoading: false,
    lookupExperiences: {},
    isLookupExperiencesLoading: true,
    error: null,
  } as IAddKnowledge,
  manageExperience: {
    opened: false,
    jobId: null,
    job: null,
    isJobLoading: false,
    error: null,
    skipAccrualUpdate: false,
  } as IManageExperience,
  manageExperiences: {
    opened: false,
    enableUpload: false,
    excludeJobs: false,
    activeEditSection: null,
    parsedExperiences: {
      profile: [],
      awards: [],
      certifications: [],
      educations: [],
      jobs: [],
      publications: [],
    },
  } as IManageExperiences,
  manageProject: {
    opened: false,
    closeAfterEditRole: false,
    selectedProject: null,
    selectedProjectId: null,
    initialState: null,
    skipAccrualUpdate: false,
    selectedJobId: null,
    data: {},
  } as IManageProject,
  accrualSummary: {
    accrualIsStale: false,
    data: [],
    isLoading: false,
    error: null,
    trackingContext: undefined,
  } as IAccrualSummary,
  search: {
    savedSearchesDrawerIsOpen: false,
    shortlistDrawerIsOpen: false,
    createOrEditDialogIsOpen: false,
    collaboratorsDialogIsOpen: false,
    editId: undefined,
    initialShortlistOnSearchCreation: undefined,
  } as ISearch,
} as IState;

import { IDrawerProps, IDrawerHeaderProps } from './types';
import Root, { DrawerTestId } from './Root';
import Header from './Header';
import Main from './Main';
import Summary from './Summary';
import DetailsList from './DetailsList';
import Section from './Section';

export const Drawer = Object.assign(Root, {
  Header,
  Main,
  Summary,
  DetailsList,
  Section,
});
export type { IDrawerProps, IDrawerHeaderProps };
export { DrawerTestId };

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { faIconClasses, styles } from '../styles';
import { FAIconProps } from '../types';

const useStyles = makeStyles(styles);

export const FAIcon: React.FC<FAIconProps> = ({
  icon,
  size,
  color,
  className,
  style,
  fixedWidth,
  iconSet,
  light,
  onClick,
  ...other
}) => {
  const classes = useStyles();

  const classProps = clsx(
    classes.icon,
    faIconClasses.root,
    iconSet,
    `fa-${icon}`,
    className,
    {
      'fa-fw': fixedWidth,
      [`fa-${size}`]: size,
      fal: light,
    }
  );
  const iconStyle: { color?: string } = {};
  if (color) {
    iconStyle.color = color;
  }

  const combinedStyle = {
    ...iconStyle,
    ...style,
    ...(onClick && { cursor: 'pointer' }),
  };

  return (
    <i
      data-testid="of-fa-icon"
      className={classProps}
      style={combinedStyle}
      onClick={(e) => {
        onClick?.(e);
      }}
      {...other}
    />
  );
};

FAIcon.defaultProps = {
  iconSet: 'fa',
};

export { faIconClasses };
export default FAIcon;

import { IBreadcrumbsProps } from './types';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { ChevronRight } from 'react-feather';

const testId = 'of-breadcrumbs';

const Breadcrumbs = ({ className, ...rest }: IBreadcrumbsProps) => {
  return (
    <MUIBreadcrumbs
      className={className}
      sx={{
        '& .MuiBreadcrumbs-ol': {
          rowGap: '4px',
        },
      }}
      data-testid={testId}
      separator={<ChevronRight className="h-16 w-16" />}
      aria-label="breadcrumbs"
      {...rest}
    />
  );
};

export type { IBreadcrumbsProps };
export { testId as BreadcrumbsTestId };
export default Breadcrumbs;

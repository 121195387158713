import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addRegistrant, getJoinInfo } from '../../actions/call';
import {
  ALREADY_CANCELED,
  ALREADY_CONFIRMED,
  ALREADY_STARTED,
  NOT_ENOUGH_CREDITS,
  OUTDATED_ERROR,
  Trigger,
  deleteConsultation,
  dismissConsultationReview,
  engagementTypes,
  fetchConsultation,
  orderTranscript,
  updateConsultation,
} from '../../actions/consultation';
import { inviteUser } from '../../actions/invitation';
import { hideMessage, notify, openFileDialog } from '../../actions/ui';
import Divider from '../../components/Divider';
import RequestDetails from '../../components/Forms/ExpertRequest/DetailsNew';
import LayoutPage from '../../components/Layout/LayoutPage';
import MediaQuery from '../../components/MediaQuery';
import ProjectBar from '../../components/ProjectBar';
import { SCREEN_SM } from '../../core/muiTheme';
import { formatDateTime } from '../../core/util';
import { useApp } from 'hooks/useAppContext';
import s from './Consultation.module.scss';
import Dialogs from './Dialogs';
import Header from './Header/Header';
import JoinCall from './JoinCall';
import Metrics from './metrics/Metrics';
import CallDetails from './sections/CallDetails';
import ExpertRequestDetails from './sections/ExpertRequestDetails';
import FeedbackDetails from './sections/FeedbackDetails';
import WrittenResponse from './sections/WrittenResponse';
import Transcript from './Transcript';

const userNotAvailable = '(User not available)';

const getExpertName = (c) =>
  c.expert ? c.expert.name : c.expert_name || userNotAvailable;

function getUserName(viewer, consultation) {
  const { expert } = consultation;
  const isViewerExpert = expert && viewer.id === expert.id;
  return isViewerExpert
    ? consultation.requester_name
    : getExpertName(consultation);
}

function Consultation(props) {
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSuggestTime, setOpenSuggestTime] = useState(
    props?.openSuggestTime || false
  );
  const [openCompleteTraining, setOpenCompleteTraining] = useState(
    !!props.openCompleteTraining
  );
  const [openReviewCall, setOpenReviewCall] = useState(!!props.openReviewCall);
  const [openRequestTranscript, setOpenRequestTranscript] = useState(
    !!props.openRequestTranscript
  );
  const [openAttachToExpertRequest, setOpenAttachToExpertRequest] =
    useState(false);
  const [openInviteParticipant, setOpenInviteParticipant] = useState(false);
  const [date, setDate] = useState(
    props.timeChosen !== 'undefined' ? props.timeChosen : undefined
  );
  const [submittingTranscript, setSubmittingTranscript] = useState(false);
  const [joinInfo, setJoinInfo] = useState(undefined);

  const navigate = useNavigate();
  const { store } = useApp();
  const {
    viewer,
    consultation,
    getJoinInfo,
    hideMessage,
    notify,
    call,
    orderTranscript,
    updateConsultation,
    fetchConsultation,
    deleteConsultation,
    userContext,
    section,
    inviteUser,
    addRegistrant,
    dismissConsultationReview,
    initialUsefulnessRating,
    initialMatchExperienceRating,
  } = props;

  const handleUpdateJoinInfo = useCallback(async () => {
    if (
      consultation?.conference?.carrier === 'zoom' &&
      consultation.conference?.id
    ) {
      setJoinInfo(await getJoinInfo(consultation.conference.id));
    }
  }, [consultation, getJoinInfo]);

  const showCompleteTraining = useCallback(
    (emptyDate) => {
      const dateSelected = date || consultation.proposed_times[0];

      setOpenCompleteTraining(true);
      setDate(emptyDate ? undefined : dateSelected);
    },
    [consultation, date]
  );

  const handleConfirm = useCallback(() => {
    setOpenConfirmDialog(false);
    hideMessage();

    if (!date) {
      return notify('You need to select a suggested time.', 'error');
    }

    const userName = getUserName(viewer, consultation);

    updateConsultation({
      id: consultation.id,
      starts_at: date,
      state: 'confirmed',
      trigger: Trigger.consultationPage,
    }).then(
      () => {
        notify(
          `You accepted ${userName}'s suggested call time of ${formatDateTime(
            date,
            viewer.timezone
          )}.`,
          'success'
        );
        handleUpdateJoinInfo();
      },
      (err) => {
        if (err.message === OUTDATED_ERROR) {
          notify('Cannot schedule the event for a past date.', 'error');
          fetchConsultation(consultation.id);
        } else if (err.message === ALREADY_CONFIRMED) {
          notify('Consultation already confirmed.', 'error');
          fetchConsultation(consultation.id);
        } else if (err.message === ALREADY_CANCELED) {
          notify('Consultation already canceled.', 'error');
          fetchConsultation(consultation.id);
        } else {
          notify('An error occurred when accepting the consultation.', 'error');
        }
      }
    );
  }, [
    consultation,
    viewer,
    date,
    hideMessage,
    notify,
    handleUpdateJoinInfo,
    fetchConsultation,
    updateConsultation,
  ]);

  const handleAccept = useCallback(() => {
    const { expert } = consultation;
    const isViewerExpert = expert && viewer.id === expert.id;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;

    if (isViewerExpert) {
      if (!hasCompletedComplianceTraining) {
        setOpenCompleteTraining(true);
      }
      if (consultation.engagement_type === 'opportunity') {
        setOpenAttachToExpertRequest(true);
      }
    }

    return handleConfirm();
  }, [viewer, consultation, handleConfirm]);

  const handleDeny = useCallback(
    (values) => {
      hideMessage();

      setOpenDenyDialog(false);
      setDate(null);

      updateConsultation({
        id: consultation.id,
        state: 'denied',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      }).catch(() => {
        notify('An error occurred when canceling consultation.', 'error');
      });
    },
    [consultation, hideMessage, notify, updateConsultation]
  );

  const handleDelete = useCallback(() => {
    deleteConsultation(consultation.id)
      .then(() => {
        navigate('/consultations');
      })
      .catch((err) => {
        notify('Error when deleting consultation', 'error');
        throw err;
      });
  }, [consultation, deleteConsultation, notify]);

  const handleCancel = useCallback(
    (values) => {
      hideMessage();

      setOpenCancelDialog(false);
      setDate(null);

      updateConsultation({
        id: consultation.id,
        state: 'canceled',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      }).catch((err) => {
        const message =
          err && err.message === ALREADY_STARTED
            ? 'Cannot cancel ongoing consultation.'
            : 'An error occurred when canceling consultation.';
        notify(message, 'error');
      });
    },
    [consultation, hideMessage, notify, updateConsultation]
  );

  const handleInviteParticipant = useCallback(
    async (values) => {
      setOpenInviteParticipant(false);

      const conferenceId = consultation.conference?.id;
      if (!conferenceId) {
        notify('Error inviting participant. No conference', 'error');
        return;
      }

      try {
        await addRegistrant(consultation, {
          conferenceId,
          name: values.name,
          email: values.email,
          invited_by: viewer.name,
        });

        if (values.inviteJoinTeam) {
          const groupId =
            consultation.group?.id ||
            consultation.expert_request?.project?.group?.id;
          if (!groupId) {
            notify('Invited participant but error inviting to group', 'error');
            return;
          }
          await inviteUser({
            email: values.email,
            collectionType: 'group',
            collectionId: groupId,
            role: 'member',
          });
        }
        notify('Successfully invited participant');
      } catch (ex) {
        notify('Error inviting participant', 'error');
        throw ex;
      }
    },
    [consultation, viewer, addRegistrant, inviteUser, notify]
  );

  const handleSuggestTime = useCallback(
    (values) => {
      if (!values || !values.dates) return;

      setOpenSuggestTime(false);
      setDate(null);

      const isExpert = viewer.id === consultation.expert.id;
      const userName = getUserName(viewer, consultation);

      updateConsultation({
        id: consultation.id,
        proposed_times: values.dates.filter(Boolean),
        duration: values.duration,
        state: isExpert ? 'negotiating_client_time' : 'negotiating_expert_time',
        trigger: Trigger.consultationPage,
      })
        .then(() => {
          notify(`Sent time suggestion to ${userName}.`, 'success');
        })
        .catch((err) => {
          const message =
            err && err.message === ALREADY_STARTED
              ? 'Cannot reschedule ongoing consultation.'
              : err && err.message === NOT_ENOUGH_CREDITS
                ? 'Not enough credits to reschedule the consultation.'
                : 'An error occurred when rescheduling consultation.';
          notify(message, 'error');
        });
    },
    [consultation, viewer, notify, updateConsultation]
  );

  const handleRequestTranscript = useCallback(() => {
    hideMessage();
    const { id, transcription_price: transcriptionPrice } = consultation;

    setSubmittingTranscript(true);

    orderTranscript(id, transcriptionPrice)
      .then(() => {
        notify('Transcript requested.', 'success');
        setOpenRequestTranscript(false);
        setSubmittingTranscript(false);
      })
      .catch((e) => {
        setSubmittingTranscript(false);

        if (
          e.message === 'GraphQL Error: maximum amount of credits not provided'
        ) {
          notify(
            'This consultation has an invalid duration and ' +
              'cannot be transcribed at this time. ' +
              'A member of the engineering team has been notified.',
            'error'
          );
          return;
        }

        if (
          e.message ===
          'GraphQL Error: not enough credits to order transcription'
        ) {
          notify('Not enough credits to order transcript', 'error');
          return;
        }

        notify('An error occurred when requesting the transcript.', 'error');
      });
  }, [consultation, hideMessage, notify, orderTranscript]);

  const handleOpenSuggestTime = useCallback(() => {
    const { expert } = consultation;
    const isViewerExpert = expert && viewer.id === expert.id;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;

    if (isViewerExpert && !hasCompletedComplianceTraining) {
      setOpenCompleteTraining(true);
    } else {
      setOpenSuggestTime(true);
    }
  }, [consultation, viewer]);

  useEffect(() => {
    if (openSuggestTime) handleOpenSuggestTime();
    handleUpdateJoinInfo();

    async function fetchRequests() {
      await Promise.all(
        RequestDetails.fetch.map((f) => store.dispatch(f({ viewer })))
      );
    }
    fetchRequests();
  }, [
    openSuggestTime,
    handleUpdateJoinInfo,
    handleOpenSuggestTime,
    store,
    viewer,
  ]);

  const handleOpenTranscript = () => {
    setOpenRequestTranscript(true);
  };

  const dismissReview = () => {
    dismissConsultationReview(consultation.id, true);
    setOpenReviewCall(false);
  };

  const closeDialogs = () => {
    setOpenDenyDialog(false);
    setOpenCompleteTraining(false);
    setOpenConfirmDialog(false);
    setOpenCancelDialog(false);
    setOpenSuggestTime(false);
    setOpenReviewCall(false);
    setOpenRequestTranscript(false);
    setOpenAttachToExpertRequest(false);
    setOpenInviteParticipant(false);
  };

  if (!consultation) return null;

  const {
    id,
    expert_request: expertRequest,
    requester = {},
    expert = {},
    state,
    transcription,
    client_review: clientReview,
    expert_review: expertReview,
    external,
  } = consultation;

  const requesterName = consultation.requester_name;
  const expertName = getExpertName(consultation);

  const isViewerExpert = expert && viewer.id === expert.id;
  const isExpertActive = expert && expert.expert_state === 'active';
  const isViewerRequester = requester && viewer.id === requester.id;
  const isViewerAdmin = viewer.admin && !isViewerExpert && !isViewerRequester;
  const user = isViewerExpert ? requester : expert;

  const isExpired = state === 'expired';
  const isCompleted = state === 'completed';
  const isConfirmed = state === 'confirmed';
  const isCanceled = state === 'canceled';
  const isDenied = state === 'denied';
  const isIncomplete = state === 'incomplete';

  const isWaitingExpertConfirmation = state === 'negotiating_expert_time';
  const isWaitingRequesterConfirmation = state === 'negotiating_client_time';
  const isWaitingConfirmation =
    isWaitingExpertConfirmation || isWaitingRequesterConfirmation;
  const isWaitingViewerConfirmation =
    (isViewerExpert && isWaitingExpertConfirmation) ||
    (!isViewerExpert && isWaitingRequesterConfirmation);

  const isWrittenConsultation =
    consultation.engagement_type === engagementTypes.writtenResponse;
  const showDetails = isConfirmed || isCompleted || isCanceled || isIncomplete;
  const canEdit = isViewerRequester || isViewerExpert || isViewerAdmin;
  const canConfirm = isExpertActive && isWaitingViewerConfirmation && canEdit;
  const canCancel =
    isExpertActive &&
    (isConfirmed || (!isViewerExpert && isWaitingConfirmation)) &&
    canEdit;
  const canDeny = isExpertActive && isViewerExpert && isWaitingConfirmation;
  const canReschedule =
    isExpertActive && (isConfirmed || isExpired || isIncomplete);

  const hasReview = !!(isViewerExpert
    ? clientReview && clientReview.id
    : expertReview && expertReview.id);
  const shouldReview =
    !external && !isWrittenConsultation && isCompleted && !hasReview;

  const baseProps = {
    consultation,
    expertRequest,
    requesterName,
    expertName,
    isViewerExpert,
    isExpertActive,
    isViewerRequester,
    isViewerAdmin,
    user,
    isExpired,
    isCompleted,
    isConfirmed,
    isDenied,
    isIncomplete,
    isWaitingConfirmation,
    isWaitingExpertConfirmation,
    isWaitingRequesterConfirmation,
    isWaitingViewerConfirmation,
    isWrittenConsultation,
    canEdit,
    canConfirm,
    canCancel,
    canDeny,
    canReschedule,
    shouldReview,
    hasReview,
  };

  const showRequest = !!expertRequest;
  const showTranscription =
    !isWrittenConsultation &&
    !isViewerExpert &&
    transcription &&
    transcription.monologues &&
    transcription.monologues.length > 0;
  const showFeedback = hasReview;

  const showMetrics =
    !isWrittenConsultation &&
    consultation.conference &&
    consultation.conference.carrier &&
    viewer.admin &&
    [
      'confirmed',
      'in_progress',
      'finalizing',
      'completed',
      'incomplete',
    ].includes(state);
  const showTabs =
    showRequest || showTranscription || showFeedback || showMetrics;

  return (
    <MediaQuery maxWidth={SCREEN_SM}>
      {(isMobileVersion) => {
        const CallActions = !isWrittenConsultation && (
          <JoinCall consultation={consultation} joinInfo={joinInfo} />
        );

        return (
          <LayoutPage
            showNav
            selected="consultations"
            footerStyle={{
              paddingBottom: isMobileVersion && CallActions ? 110 : 0,
            }}
            showReviewConsultation={!openReviewCall}
          >
            {viewer.admin && expertRequest && (
              <ProjectBar viewer={viewer} project={expertRequest?.project} />
            )}

            <Header
              {...baseProps}
              isMobileVersion={isMobileVersion}
              callActions={CallActions}
              onReviewCall={() => setOpenReviewCall(true)}
              onDelete={consultation.external && handleDelete}
              shouldReview={shouldReview}
            />

            <Divider spacing={30} />

            {showTabs && (
              <Tabs value={section} style={{ marginBottom: 30 }}>
                <Tab
                  onClick={() => navigate(`/consultation/${id}`)}
                  value={
                    isWrittenConsultation ? 'response_details' : 'call_details'
                  }
                  label={
                    isWrittenConsultation ? 'Response Details' : 'Call Details'
                  }
                />
                {showRequest && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/request`)}
                    value="request"
                    label="Request Details"
                  />
                )}
                {showTranscription && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/transcript`)}
                    value="transcript"
                    label="Transcript"
                  />
                )}
                {showFeedback && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/feedback`)}
                    value="feedback"
                    label="Feedback"
                  />
                )}
                {showMetrics && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/metrics`)}
                    value="metrics"
                    label="Metrics"
                  />
                )}
              </Tabs>
            )}

            <div className={s.consultationContent}>
              {section === 'call_details' && (
                <CallDetails
                  {...baseProps}
                  viewer={viewer}
                  userContext={userContext}
                  joinInfo={joinInfo}
                  showDetails={showDetails}
                  isMobileVersion={isMobileVersion}
                  ongoingCall={call.connected}
                  openReviewCall={openReviewCall}
                  onRequestTranscript={handleOpenTranscript}
                  selectedDate={date}
                  onOpenSuggestTime={handleOpenSuggestTime}
                  onDateSelect={setDate}
                  onConfirm={handleAccept}
                  onCancel={() => setOpenCancelDialog(true)}
                  onDeny={() => setOpenDenyDialog(true)}
                  onReschedule={() => setOpenSuggestTime(true)}
                  onInviteParticipant={() => setOpenInviteParticipant(true)}
                  transcription={transcription}
                />
              )}
              {section === 'response_details' && (
                <WrittenResponse
                  {...baseProps}
                  viewer={viewer}
                  isMobileVersion={isMobileVersion}
                  showCompleteTraining={showCompleteTraining}
                />
              )}
              {section === 'request' && (
                <ExpertRequestDetails
                  consultation={consultation}
                  isViewerExpert={isViewerExpert}
                  isMobileVersion={isMobileVersion}
                />
              )}
              {!isViewerExpert && section === 'transcript' && (
                <Transcript
                  transcription={transcription}
                  expertId={expert && expert.id}
                />
              )}
              {showFeedback && section === 'feedback' && (
                <FeedbackDetails
                  clientReview={clientReview}
                  expertReview={expertReview}
                  isViewerExpert={isViewerExpert}
                  isViewerRequester={isViewerRequester}
                  requester={requester}
                  expert={expert}
                />
              )}
              {showMetrics && section === 'metrics' && (
                <Metrics consultation={consultation} />
              )}
            </div>

            {isMobileVersion && CallActions && (
              <div className={s.stickyCallActions}>{CallActions}</div>
            )}

            <Dialogs
              user={user}
              consultation={consultation}
              date={date}
              viewer={viewer}
              isViewerExpert={isViewerExpert}
              onDismissReview={dismissReview}
              onCloseDialog={closeDialogs}
              onDeny={handleDeny}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              onSuggestTime={handleSuggestTime}
              onRequestTranscript={handleRequestTranscript}
              onInviteParticipant={handleInviteParticipant}
              openDenyDialog={openDenyDialog}
              openConfirmDialog={openConfirmDialog}
              openCancelDialog={openCancelDialog}
              openCompleteTraining={openCompleteTraining}
              openReviewCall={openReviewCall}
              openSuggestTime={openSuggestTime}
              openRequestTranscript={openRequestTranscript}
              openInviteParticipant={openInviteParticipant}
              submittingTranscript={submittingTranscript}
              openAttachToExpertRequest={openAttachToExpertRequest}
              initialUsefulnessRating={initialUsefulnessRating}
              initialMatchExperienceRating={initialMatchExperienceRating}
              shouldReview={shouldReview}
              isWrittenConsultation={isWrittenConsultation}
            />
          </LayoutPage>
        );
      }}
    </MediaQuery>
  );
}

export default connect(
  (state, ownProps) => {
    const consultation =
      state.consultations.default &&
      state.consultations.default.edges &&
      state.consultations.default.edges.find(
        (c) => c.node.id === ownProps.consultationId
      );
    return {
      viewer: state.viewer,
      call: state.call,
      userContext: state.ui.userContext,
      consultation: consultation && consultation.node,
    };
  },
  {
    addRegistrant,
    deleteConsultation,
    dismissConsultationReview,
    fetchConsultation,
    hideMessage,
    inviteUser,
    getJoinInfo,
    notify,
    openFileDialog,
    orderTranscript,
    updateConsultation,
  }
)(Consultation);

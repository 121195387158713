import React, { PureComponent } from 'react';
import cx from 'classnames';
import Link from '../Link';
import s from './CountBox.module.scss';

class CountBox extends PureComponent {
  render() {
    const {
      count = 0,
      grayOutZero,
      label,
      warn,
      linkTo,
      onClick,
      className,
      labelClassName,
      selected,
    } = this.props;

    const grayOut = grayOutZero && count === 0;

    const contents = (
      <div
        className={cx(s.root, {
          [className]: !!className,
          [s.grayOut]: grayOut,
          [s.selected]: selected,
          [s.warn]: warn,
        })}
      >
        <div className={s.count}>{count}</div>
        {label && (
          <span className={cx(s.label, { [labelClassName]: !!labelClassName })}>
            {label}
          </span>
        )}
      </div>
    );

    return linkTo || onClick ? (
      <Link href={linkTo || '#'} onClick={onClick}>
        {' '}
        {contents}{' '}
      </Link>
    ) : (
      contents
    );
  }
}

export default CountBox;

export const ERR_OUT_OF_MARKETPLACE =
  'GraphQL Error: expert is not part of the marketplace';

export const MSG_OUT_OF_MARKETPLACE =
  "The team does not match the expert's marketplace preferences";

export const ERR_NOT_ENOUGH_CREDITS =
  'GraphQL Error: not enough credits for consultation';

export const ERR_NO_BILLING_ACCOUNT =
  'GraphQL Error: no billing account associated with group or expert request';

export const ERR_NOT_AVAILABLE_SELF_SERVICE =
  'GraphQL Error: expert must be a request candidate';

export const ERR_REQUESTER_EQUALS_EXPERT =
  'GraphQL Error: requester and expert cannot be the same';

import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider as ReduxProvider } from 'react-redux';
import moment from 'moment-timezone';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import config from '../config';
import PageLoadingBar from './components/PageLoadingBar/PageLoadingBar';
import { useApp } from 'hooks/useAppContext';

const App = ({ children }) => {
  const context = useApp();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <ReduxProvider store={context.store}>
          <PageLoadingBar />
          {React.Children.only(children)}
        </ReduxProvider>
      </LocalizationProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;

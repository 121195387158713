import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import { Button as MaterialButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FieldArray } from 'react-final-form-arrays';
import MediaQuery from '../../../components/MediaQuery';
import LinearProgress from '../../../components/LinearProgress';
import { SCREEN_XS } from '../../../core/muiTheme';
import { notify } from '../../../actions/ui';
import { normalise } from '../../../core/util';

import ProfileTitle from '../../../components/ProfileTitle';
import Button from '../../../components/Button';
import Queries from '../../../components/Queries';

const useStyles = makeStyles({
  paper: {
    padding: 24,
  },
  paperWidthSm: {
    maxWidth: 640,
  },
  paperFullScreen: {
    boxSizing: 'border-box',
  },
  section: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: '0px',
  },
  question: {
    fontSize: '6px',
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: '0px',
  },

  actions: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  done: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },
  disabledCheckbox: {
    '&.Mui-disabled': {
      color: 'rgba(0,100,110,0.5)',
    },
  },
  disabledLabel: {
    '&.Mui-disabled': {
      color: 'black',
    },
  },
});

function ProgressDialog({
  expertRequest,
  candidate,
  editable,
  progressOpen,
  onClose,

  // Final Form
  form,
  submitting,
  handleSubmit,
  values,
}) {
  // Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const s = useStyles();

  const onCloseWithReset = () => {
    form.reset();
    onClose();
  };
  return (
    <Dialog
      open={progressOpen}
      scroll="body"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      classes={{
        paper: s.paper,
        paperWidthSm: s.paperWidthSm,
        paperFullScreen: s.paperFullScreen,
      }}
    >
      <ProfileTitle profile={candidate.profile} />

      <form onSubmit={handleSubmit}>
        {values.questions?.length > 0 && (
          <div className={s.section}>
            <p className={s.sectionTitle}>Able to answer these questions</p>
            <FieldArray
              component={Queries}
              name="questions"
              key="questions"
              queries={expertRequest.questions}
              disabled={!editable}
              field={Field}
            />
          </div>
        )}

        {values.qualifications?.length > 0 && (
          <div className={s.section}>
            <p className={s.sectionTitle}>Qualifications</p>
            <FieldArray
              component={Queries}
              name="qualifications"
              key="qualifications"
              queries={expertRequest.qualifications}
              disabled={!editable}
              field={Field}
            />
          </div>
        )}

        <div className={s.actions}>
          <div>
            <MaterialButton onClick={onCloseWithReset}>
              {editable ? 'Cancel' : 'Close'}
            </MaterialButton>
          </div>

          {editable && (
            <div className={s.done}>
              <Button disabled={submitting} type="submit" size="medium">
                Save
              </Button>
            </div>
          )}
        </div>
      </form>
    </Dialog>
  );
}

function MatchProgress({
  editable,
  onSubmit,
  notify,
  expertRequest,
  candidate,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);

  const handleProgressClick = (event) => {
    event.preventDefault();

    setProgressOpen(true);
  };

  const handleProgressClose = () => {
    setProgressOpen(false);
    // wait for animation to complete
    setTimeout(() => setIsSubmitting(false), 500);
  };

  const handleSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const qualificationResponses = values.qualifications.map((q) => ({
        query_id: q.query.id,
        ...(q.query.response_type === 'yes_no' && { can_answer: q.can_answer }),
        ...(q.query.response_type === 'free_form' && {
          text_response: q.text_response,
        }),
      }));
      const questionAnswers = values.questions.map((q) => ({
        query_id: q.query.id,
        ...(q.query.response_type === 'yes_no' && { can_answer: q.can_answer }),
        ...(q.query.response_type === 'free_form' && {
          text_response: q.text_response,
        }),
      }));
      const candidateValues = {
        id: values.id,
        qualification_responses: qualificationResponses,
        question_answers: questionAnswers,
      };
      await onSubmit(candidateValues);
      notify('Expert answers updated.', 'success');
      handleProgressClose();
    } catch (err) {
      notify('Error when updating expert answers.', 'error');
      setIsSubmitting(false);
      throw err;
    }
  };

  const { questions, qualifications } = expertRequest;

  const {
    qualification_responses: qualificationResponses,
    question_answers: questionResponses,
  } = candidate;

  const linearProgressValue = useMemo(() => {
    // TODO: Max is going to check with RM a better solution for this component
    const validQuestionResponses =
      questionResponses?.filter(
        (a) =>
          (a.can_answer === true || a.text_response !== '') &&
          questions.some((q) => q.id === a.query?.id)
      ) || [];
    const validQualificationResponses =
      qualificationResponses?.filter(
        (a) =>
          (a.can_answer === true || a.text_response !== '') &&
          qualifications.some((q) => q.id === a.query?.id)
      ) || [];

    return normalise(
      validQuestionResponses.length + validQualificationResponses.length,
      questions.length + qualifications.length
    );
  }, [expertRequest, candidate]);

  const totalQuestions = useMemo(() => {
    const unrespondedQuestions =
      questionResponses &&
      questions
        .filter((q) => !questionResponses.some((a) => a.query?.id === q.id))
        .map((q) => ({
          query: q,
          text_reponse: '',
        }));

    return [...questionResponses]
      .filter((r) => r.query)
      .concat(unrespondedQuestions);
  }, [expertRequest, candidate]);

  const totalQualifications = useMemo(() => {
    const unrespondedQualifications =
      qualificationResponses &&
      qualifications
        .filter(
          (q) => !qualificationResponses.some((a) => a.query?.id === q.id)
        )
        .map((q) => ({
          query: q,
          text_reponse: '',
        }));

    return [...qualificationResponses]
      .filter((r) => r.query)
      .concat(unrespondedQualifications);
  }, [expertRequest, candidate]);

  const initialValues = {
    id: candidate.id,
    qualifications: totalQualifications,
    questions: totalQuestions,
  };
  return (
    <div>
      <LinearProgress
        variant="determinate"
        value={linearProgressValue}
        style={{
          width: 100,
          borderRadius: 5,
          height: 8,
          marginRight: 15,
          cursor: 'pointer',
        }}
        onClick={handleProgressClick}
      />
      <MediaQuery maxWidth={SCREEN_XS}>
        {() => (
          <Form
            // Final Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            mutators={{ setFieldData, ...arrayMutators }}
            component={ProgressDialog}
            // Only re-render if these parts of the form state change
            subscription={{
              submitting: true,
              values: true,
            }}
            // ProgressDialog props
            expertRequest={expertRequest}
            candidate={candidate}
            editable={editable}
            progressOpen={progressOpen}
            onClose={handleProgressClose}
          />
        )}
      </MediaQuery>
    </div>
  );
}

export default connect(undefined, {
  notify,
})(MatchProgress);

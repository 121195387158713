import { detect as detectBrowser } from 'detect-browser';
import { FeatureSupport, NetworkWarnings } from './voipCarrier';

function iteratorToArray(iterator) {
  const array = [];
  for (const entry of iterator) {
    array.push(entry);
  }
  return array;
}

// https://www.twilio.com/docs/voice/insights/call-quality-events-twilio-client-sdk#network-warnings
const carrierNetworkWarnings = Object.freeze({
  'high-rtt': NetworkWarnings.highRtt,
  'low-mos': NetworkWarnings.lowMos,
  'high-jitter': NetworkWarnings.highJitter,
  'high-packet-loss': NetworkWarnings.highPacketLoss,
});

const defaultOptions = {
  codecPreferences: ['opus', 'pcmu'],
};

class TwilioCarrier {
  register(events) {
    this._events = events;
  }

  _getDevice() {
    if (this._device) return this._device;

    // twilio not yet available
    const unavailableFn = () => {
      throw new Error('twilio not available');
    };
    return {
      audio: {},
      disconnectAll: unavailableFn,
      activeConnection: unavailableFn,
    };
  }

  // support only enabled for Chrome for now.
  // more information on Twilio support here:
  // https://www.twilio.com/docs/voice/client/javascript/device#audio
  //
  // Twilio's support detection strategies (empty Set on get() method)
  // seem to fail on Edge.
  // We are following Twilio's documentation that states:
  // > Currently Chrome 49+ is the only browser that fully
  // > supports Twilio.Device.audio.
  //
  // We may want to change this implementation on future Twilio JS clients.
  //
  // Other VoipCarrier implementations (such Plivo) may rely on better
  // feature support detection implementations.
  _syncFeatureSupport() {
    let support;
    const browser = detectBrowser();
    if (!browser) {
      support = FeatureSupport.unknown;
    } else if (browser.name === 'chrome') {
      support = FeatureSupport.supported;
    } else {
      support = FeatureSupport.unsupported;
    }

    this._events.onFeatureSupport({
      selectInputDevice: support,
      selectOutputDevice: support,
    });
  }

  // -- METHODS

  inputDeviceOptions() {
    const { availableInputDevices } = this._getDevice().audio;
    if (!availableInputDevices) return null;
    return iteratorToArray(availableInputDevices.values());
  }

  inputDeviceSelected() {
    const { inputDevice } = this._getDevice().audio;
    if (!inputDevice) return null;
    return inputDevice.deviceId;
  }

  inputDeviceSelect(deviceId) {
    const { audio } = this._getDevice();
    if (!audio.setInputDevice) throw new Error('Twilio audio not available');
    return audio.setInputDevice(deviceId);
  }

  outputDeviceOptions() {
    const { availableOutputDevices } = this._getDevice().audio;
    if (!availableOutputDevices) return null;
    return iteratorToArray(availableOutputDevices.values());
  }

  outputDeviceSelected() {
    const { speakerDevices } = this._getDevice().audio;
    if (!speakerDevices) return null;
    const selected = iteratorToArray(speakerDevices.get()).map(
      (d) => d.deviceId
    );
    return selected[0];
  }

  outputDeviceSelect(deviceId) {
    const { speakerDevices } = this._getDevice().audio;
    if (!speakerDevices)
      throw new Error('Twilio audio.speakerDevices not available');
    return speakerDevices.set(deviceId);
  }

  async connect(token) {
    const twilio = await import(
      /* webpackChunkName: "twilio" */
      '@twilio/voice-sdk'
    );
    const device = new twilio.Device(token, defaultOptions);
    this._device = device;

    device.ready(() => {
      device.audio.on('deviceChange', this._handleDeviceChange);
      this._syncFeatureSupport();
      device.connect();
    });

    device.connect(this._handleConnected);

    device.disconnect(() => device.destroy());

    device.error(this._handleError);
  }

  disconnect() {
    this._getDevice().disconnectAll();
  }

  mute(mute) {
    const conn = this._getDevice().activeConnection();
    if (!conn) throw new Error('no twilio connection');
    conn.mute(mute);
  }

  // -- EVENTS

  _handleDeviceChange = () => this._events.onDeviceChange();

  _handleConnected = (connection) => {
    this._events.onConnected(connection.parameters.CallSid);

    connection.on('warning', (warning) => {
      const warningName = carrierNetworkWarnings[warning];
      if (!warningName) return;
      this._events.onWarning(warningName, true);
    });

    connection.on('warning-cleared', (warning) => {
      const warningName = carrierNetworkWarnings[warning];
      if (!warningName) return;
      this._events.onWarning(warningName, false);
    });

    connection.on('disconnect', this._handleDisconnected);

    connection.volume(this._handleVolume);
  };

  _handleDisconnected = () => this._events.onDisconnected();

  _handleError = (err) => this._events.onError(err);

  _handleVolume = (inputVolume, outputVolume) =>
    this._events.onVolume(inputVolume, outputVolume);
}

export default new TwilioCarrier();

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { updateProfile } from '../../../actions/profile';
import Form from '../../Form';
import SelectLocation from '../../SelectLocation';
import SelectSector from '../../SelectSector';
import { parseId } from '../../../core/util';

class Expertise extends PureComponent {
  static propTypes = {
    allCountries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    allSectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    countries: PropTypes.array,
    sectors: PropTypes.array,
    keywords: PropTypes.array,
    handleSubmit: PropTypes.func,
    array: PropTypes.object,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  };

  handleSubmit = (values) => {
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container = Form,
      allCountries,
      allSectors,
      handleSubmit,
      array,
      profile,
      isMobileVersion,
      ...other
    } = this.props;

    function formatter(meta) {
      return function (values) {
        return (
          values &&
          values
            .map((value) => meta.find((c) => c.id.toString() === value))
            .filter(Boolean)
        );
      };
    }

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          component={SelectLocation}
          multiple
          id="regions"
          name="region_ids"
          countries={allCountries}
          label="Select countries and regions"
          format={formatter(allCountries)}
          parse={parseId}
        />
        <Field
          component={SelectSector}
          id="sectors"
          name="sector_ids"
          sectors={allSectors}
          format={formatter(allSectors)}
          parse={parseId}
        />
      </Container>
    );
  }
}

Expertise = reduxForm({
  form: 'expertExpertise',
  enableReinitialize: true,
})(Expertise);

Expertise = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        region_ids: profile.regions.map((r) => r.id),
        sector_ids: profile.sectors.map((s) => s.id),
        keywords: profile.keywords,
      },
      allCountries: state.countries,
      allSectors: state.sectors.all,
    };
  },
  {
    updateProfile,
  }
)(Expertise);

export default Expertise;

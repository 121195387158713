import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { SelectUser } from '../FormAdapters/FormAdapters';
import EditDialog from '../EditDialog';

class AddUserDialog extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    userSource: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  };

  handleSubmit = (values) => {
    const { onRequestAdd, reset, onSubmit } = this.props;
    const { users } = values;
    onRequestAdd(users);
    reset();

    if (onSubmit) {
      onSubmit(users);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      allowEmails,
      handleSubmit,
      array,
      users,
      userSource = [],
      userFilter,
      ...other
    } = this.props;

    return (
      <EditDialog
        saveLabel="Add"
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        disableSubmit={users && users.length === 0}
        onReset={this.handleReset}
      >
        <Field
          component={SelectUser}
          multiple
          name="users"
          suggestionsFilter={userFilter}
          allowEmail={allowEmails}
        />
      </EditDialog>
    );
  }
}

AddUserDialog = reduxForm({
  form: 'addUser',
  initialValues: {
    users: [],
  },
})(AddUserDialog);

const selector = formValueSelector('addUser');
AddUserDialog = connect((state) => ({
  users: selector(state, 'users'),
}))(AddUserDialog);

export default AddUserDialog;

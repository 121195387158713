import React from 'react';
import cx from 'classnames';
import Divider from '../Divider';
import EditIcon from '../EditIcon';
import s from './ConfirmationRow.module.scss';
import { borderColor } from '../../core/colors';

function ConfirmationRow({
  title,
  separator = true,
  separatorBelow,
  separatorStyle,
  enableEdit = true,
  onClick,
  backToLocation,
  children,
  titleClassName,
  bodyClassName,
  className,
  style,
  titleStyle,
  bodyStyle,
}) {
  const rootClass = cx(s.root, { [className]: !!className });
  const titleClass = cx(s.title, { [titleClassName]: !!titleClassName });
  const bodyClass = cx(s.body, { [bodyClassName]: !!bodyClassName });

  return (
    <div className={rootClass} style={style}>
      {separator && !separatorBelow && (
        <Divider style={{ backgroundColor: borderColor, ...separatorStyle }} />
      )}
      <div className={s.row}>
        {title && (
          <h3 className={titleClass} style={titleStyle}>
            {title}
          </h3>
        )}
        {enableEdit && (onClick || backToLocation) && (
          <EditIcon onClick={onClick} backToLocation={backToLocation} />
        )}
      </div>
      <div className={bodyClass} style={bodyStyle}>
        {children}
      </div>
      {separator && separatorBelow && (
        <Divider style={{ backgroundColor: borderColor, ...separatorStyle }} />
      )}
    </div>
  );
}

export default ConfirmationRow;

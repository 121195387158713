import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import runtime from './runtime';
import users from './users';
import profiles from './profiles';
import projects from './projects';
import expertRequests from './expertRequests';
import expertComplianceTraining from './expertComplianceTraining';
import countries from './countries';
import sectors from './sectors';
import search from './search';
import suggestions from './suggestions';
import groups from './groups';
import networks from './networks';
import domains from './domains';
import consultations from './consultations';
import consultationReviews from './consultationReviews';
import billing from './billing';
import blog from './blog';
import ui from './ui';
import legal from './legal';
import messaging from './messaging';
import messageTemplates from './messageTemplates';
import appNotifications from './appNotifications';
import call from './call';
import permissions from './permissions';
import activities from './activities';
import landing from './landing';
import expertShowcase from './expertShowcase';
import urls from './urls';
import tracking from './tracking';
import siteSettings from './siteSettings';
import auth from './auth';
import savedSearches from './savedSearches';
import loading from './loading';
import viewer from './viewer';

const reducer = combineReducers({
  viewer,
  user: (state = {}) => state,
  loading,
  users,
  profiles,
  projects,
  expertRequests,
  runtime,
  expertComplianceTraining,
  form,
  countries,
  sectors,
  search,
  groups,
  networks,
  domains,
  suggestions,
  consultations,
  consultationReviews,
  billing,
  blog,
  ui,
  legal,
  messaging,
  messageTemplates,
  appNotifications,
  call,
  permissions,
  activities,
  landing,
  expertShowcase,
  urls,
  tracking,
  siteSettings,
  auth,
  savedSearches,
});

export default function (state, action) {
  const newState = reducer(state, action);
  newState.viewer = newState.users[newState.users.currentId] || {};
  return newState;
}

import clsx from 'clsx';
import { IDrawerMainProps } from '../types';
import { forwardRef } from 'react';

const DrawerMain = forwardRef<HTMLDivElement, IDrawerMainProps>(
  (
    {
      className,
      paddingClassName = 'p-20',
      bgClassName = 'bg-light-primary',
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <main
        ref={ref}
        className={clsx(
          className,
          paddingClassName,
          bgClassName,
          'flex-grow overflow-y-auto'
        )}
        {...rest}
      >
        {children}
      </main>
    );
  }
);

DrawerMain.displayName = 'DrawerMain';

export default DrawerMain;

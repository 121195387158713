import Button, { ButtonProps } from 'components/Button';
import { PropsWithChildren } from 'react';
import { LinkProps } from 'react-router-dom';

const Item = ({
  children,
  hasNotification,
  icon,
  path,
  onClick,
}: PropsWithChildren<{
  icon: ButtonProps['startIcon'];
  path: LinkProps['to'];
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  hasNotification?: boolean;
}>) => {
  return (
    <li className="relative">
      <Button
        to={path}
        onClick={onClick}
        variant="tertiary"
        size="small"
        startIcon={icon}
        startIconClassName="access-navbar-item-icon"
        className="access-navbar-item py-8 !text-brand-primary hover:!text-brand-tertiary"
      >
        {children}
      </Button>
      {hasNotification ? (
        <span className="access-navbar-item absolute left-20 top-10 h-5 w-5 rounded-sm bg-yellow-500" />
      ) : null}
    </li>
  );
};

export default Item;

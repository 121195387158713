import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { TextField } from '../FormAdapters';
import FAIcon from '../Icon/FAIcon';
import Button from '../Button';
import Form from '../Form';
import {
  setAddress,
  requestEmailValidation,
  removeAddress,
} from '../../actions/address';
import { darkGreen, red500 } from '../../core/colors';
import { notify } from '../../actions/ui';
import { isEmailValid } from '../../core/util';
import s from './EditEmails.module.scss';
import { ErrAddressAlreadyTaken } from '../../core/address';

function validate(values, props) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email';
  } else if (
    props.profile.emails.some(
      (e) => e.address.toLowerCase() === values.email.toLowerCase()
    )
  ) {
    errors.email = 'Email already added';
  }

  return errors;
}

class Email extends PureComponent {
  handleSetPrimary = () => {
    const { address, onSetPrimary } = this.props;
    if (onSetPrimary) {
      onSetPrimary(address);
    }
  };

  handleVerification = () => {
    this.resendValidation('verify').then(() => {
      this.props.notify('Verification email sent.');
    });
  };

  handleConfirmation = () => {
    this.resendValidation('confirm').then(() => {
      this.props.notify('Confirmation email sent.');
    });
  };

  resendValidation = (action) => {
    const { profileId, address, requestEmailValidation } = this.props;
    return requestEmailValidation(profileId, address, action);
  };

  handleRemove = () => {
    const { address, onRemove } = this.props;
    if (onRemove) {
      onRemove(address);
    }
  };

  render() {
    const { address, verified, confirmed, primary, canRemove, userId } =
      this.props;

    return (
      <div className={s.emailRoot}>
        <div className={s.email}>
          <span className={s.address} title={address}>
            {verified && confirmed && userId && (
              <FAIcon
                icon="check-circle"
                color={darkGreen}
                style={{ marginRight: 8 }}
                title="Verified"
              />
            )}
            {address}
          </span>
          <div className={s.badges}>
            {primary && userId && <div className={s.greenMessage}>Default</div>}
            {!verified && userId && (
              <div className={s.grayMessage}>Unverified</div>
            )}
            {verified && !confirmed && userId && (
              <div className={s.grayMessage}>Unconfirmed</div>
            )}
          </div>
        </div>
        <div className={s.actions}>
          {!verified && userId && (
            <div className={s.verificationAction}>
              <div>Verification sent</div>
              <Button
                color="secondary"
                variant="text"
                onClick={this.handleVerification}
                size="small"
              >
                Resend
              </Button>
            </div>
          )}
          {verified && !confirmed && userId && (
            <div className={s.verificationAction}>
              <div>Confirmation sent</div>
              <Button
                color="secondary"
                variant="text"
                onClick={this.handleConfirmation}
                size="small"
              >
                Resend
              </Button>
            </div>
          )}
          {!primary && verified && confirmed && userId && (
            <Button
              color="secondary"
              variant="text"
              onClick={this.handleSetPrimary}
              size="small"
            >
              Make Default
            </Button>
          )}
          {canRemove && (
            <IconButton onClick={this.handleRemove} size="large">
              <FAIcon color={red500} icon="trash-o" size={14} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

Email = connect(undefined, {
  requestEmailValidation,
  notify,
})(Email);

class EditEmails extends PureComponent {
  handleSubmit = (values) => {
    const { profileId, setAddress, reset, notify, showSuccessMessage } =
      this.props;
    return setAddress(profileId, 'email', values.email)
      .then(() => {
        if (showSuccessMessage) notify('Email address added.');
        reset();
      })
      .catch((err) => {
        if (err.message === ErrAddressAlreadyTaken.message) {
          throw new SubmissionError({
            email: 'Email address is already in use.',
          });
        }
        notify('An error occurred when adding the email address.', 'error');
        Promise.reject(err);
      });
  };

  handleSetPrimary = (email) => {
    const { profileId, setAddress } = this.props;
    setAddress(profileId, 'email', email, true);
  };

  handleRemove = (email) => {
    const { profileId, removeAddress } = this.props;
    removeAddress(profileId, 'email', email);
  };

  render() {
    const { userId, profileId, profile, handleSubmit } = this.props;
    const { emails } = profile;

    return (
      <div>
        <div className={s.root}>
          {emails.map((e) => {
            const canRemove = e.primary
              ? emails.filter((e) => e.confirmed && e.accepted).length > 1
              : emails.length > 1;
            return (
              <Email
                key={e.address}
                userId={userId}
                profileId={profileId}
                {...e}
                onSetPrimary={this.handleSetPrimary}
                onRemove={this.handleRemove}
                canRemove={canRemove}
              />
            );
          })}
        </div>

        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className={s.addEmail}>
            <Field
              id="settingsEmailAddress"
              component={TextField}
              type="email"
              name="email"
              label="Add email address"
              placeholder="Email address"
              style={{ width: 300, marginRight: 15 }}
            />
            <Button size="medium" type="submit">
              Add
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

EditEmails = reduxForm({
  form: 'email',
  validate,
})(EditEmails);

export default connect(
  (state, ownProps) => ({
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    setAddress,
    removeAddress,
    notify,
  }
)(EditEmails);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Checkbox } from '../../FormAdapters';
import Form from '../../Form';
import { updateProfile } from '../../../actions/profile';

class PrivacyForm extends PureComponent {
  static propTypes = {
    profileId: PropTypes.string,
    component: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    updateProfile: PropTypes.func,
    reset: PropTypes.func,
    onReset: PropTypes.func,
  };

  handleSubmit = (values) => {
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });
    if (onSubmit) onSubmit(values);
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();
    if (onReset) onReset();
  };

  render() {
    const {
      component: Container = Form,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <Container
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
        {...other}
      >
        <Field
          type="checkbox"
          component={Checkbox}
          name="hide_profile"
          label="Keep my profile hidden"
        />
      </Container>
    );
  }
}

PrivacyForm = reduxForm({
  form: 'expertPrivacy',
  enableReinitialize: true,
})(PrivacyForm);

PrivacyForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        hide_profile: profile.hide_profile,
      },
    };
  },
  {
    updateProfile,
  }
)(PrivacyForm);

export default PrivacyForm;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Dialog from '../Dialog';
import Button from '../Button';
import FAIcon from '../Icon/FAIcon';
import { notify } from '../../actions/ui';
import { submitClientInterest } from '../../actions/user';
import { isEmailValid } from '../../core/util';
import { primary } from '../../core/colors';
import s from './ClientIntake.module.scss';
import { TextField } from '../FormAdapters/FormAdapters';

const fieldSpecs = {
  name: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        label={fieldLabel(config, 'Your Name')}
        InputLabelProps={{
          shrink: true,
        }}
        name="name"
        key="name"
      />
    ),
    validate: requiredValidator,
  },
  title: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        label={fieldLabel(config, 'Your Title')}
        InputLabelProps={{
          shrink: true,
        }}
        name="title"
        key="title"
      />
    ),
    validate: requiredValidator,
  },
  organization: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        label={fieldLabel(config, 'Your Organization')}
        InputLabelProps={{
          shrink: true,
        }}
        name="organization"
        key="organization"
      />
    ),
    validate: requiredValidator,
  },
  email: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        label={fieldLabel(config, 'Your Email')}
        InputLabelProps={{
          shrink: true,
        }}
        name="email"
        key="email"
      />
    ),
    validate: combineValidators(requiredValidator, emailValidator),
  },
  phone: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        label={fieldLabel(config, 'Your Phone')}
        InputLabelProps={{
          shrink: true,
        }}
        name="phone"
        key="phone"
      />
    ),
    validate: requiredValidator,
  },
  use_case: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        multiline
        label={fieldLabel(config, 'How would you envision using OnFrontiers?')}
        InputLabelProps={{
          shrink: true,
        }}
        name="use_case"
        key="use_case"
      />
    ),
    validate: requiredValidator,
  },
  questions: {
    renderComponent: (config) => (
      <Field
        autoFocus={config.first}
        component={TextField}
        fullWidth
        multiline
        label={fieldLabel(config, 'Your biggest questions now')}
        InputLabelProps={{
          shrink: true,
        }}
        name="questions"
        key="questions"
      />
    ),
    validate: requiredValidator,
  },
};

function fieldLabel(config, defaultLabel) {
  const label = config.label || defaultLabel;
  return config.required ? label : `${label} (optional)`;
}

function requiredValidator(config, value) {
  if (!config.required) return null;
  if (!value || !value.trim()) return 'Required';
}

function emailValidator(config, value) {
  if (value && !isEmailValid(value)) {
    return 'Email address must be valid';
  }
}

function sanityMaxLengthValidator(config, value) {
  if (value && value.length > 400) {
    return 'Value too long';
  }
}

function combineValidators(...validators) {
  return (config, value) => {
    for (const validator of validators) {
      const error = validator(config, value);
      if (error) return error;
    }
    return null;
  };
}

function createValidator(configs) {
  return (values) =>
    Object.keys(configs).reduce((acc, field) => {
      const config = configs[field];
      const value = values[field];
      const spec = fieldSpecs[field];
      if (!spec) throw new Error(`Uknown field '${field}'`);
      return {
        ...acc,
        [field]: combineValidators(spec.validate, sanityMaxLengthValidator)(
          config,
          value
        ),
      };
    }, {});
}

class ClientIntake extends PureComponent {
  state = {
    sent: false,
  };

  handleSubmit = async (values) => {
    const { submitClientInterest, notify, source } = this.props;
    try {
      await submitClientInterest({ source, ...values });
      this.setState({ sent: true });
    } catch (err) {
      notify(
        'Sorry, an error occurred when trying to submit the form.',
        'error'
      );
    }
  };

  render() {
    const { handleSubmit, onClose, title, subtitle, fields, source, ...other } =
      this.props;

    if (this.state.sent) {
      return (
        <Dialog {...other}>
          <div className={s.sent}>
            <FAIcon
              iconSet="fal"
              icon="paper-plane"
              color={primary}
              size={28}
            />

            <h2 className={s.sentTitle}>Thank you</h2>

            <div className={s.sentText}>
              We will respond to your inquiry promptly.
            </div>

            <Button onClick={onClose}>Okay</Button>
          </div>
        </Dialog>
      );
    }

    return (
      <Dialog
        {...other}
        saveLabel="Submit"
        cancelLabel="Cancel"
        confirmLabel="Submit"
        onConfirm={handleSubmit(this.handleSubmit)}
        onCancel={onClose}
      >
        <div className={s.title}>{title}</div>
        <div className={s.text}>{subtitle}</div>

        {Object.keys(fields).map((field, index) => {
          const config = { first: index === 0, ...fields[field] };
          const spec = fieldSpecs[field];
          return spec.renderComponent(config);
        })}
      </Dialog>
    );
  }
}

ClientIntake = reduxForm({
  form: 'clientIntake',
  touchOnBlur: false,
})(ClientIntake);

ClientIntake = connect(
  (state, ownProps) => ({
    validate: createValidator(ownProps.fields),
  }),
  {
    submitClientInterest,
    notify,
  }
)(ClientIntake);

export default ClientIntake;

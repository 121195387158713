import {
  default as Container,
  BreadcrumbsTestId,
  IBreadcrumbsProps,
} from './Breadcrumbs';
import { default as Item } from './Item';

export const Breadcrumbs = Object.assign(Container, {
  Item,
});
export { BreadcrumbsTestId };
export type { IBreadcrumbsProps };

import React from 'react';
import MediaQuery from '../MediaQuery';
import MarketingHero from './MarketingHero';
import SplashTitle from '../SplashTitle';
import Testimonial from '../Testimonial';
import { SCREEN_XS } from '../../core/muiTheme';
import s from './SplashHero.module.scss';

function SplashHero({
  titleStyle,
  title,
  text,
  testimonial,
  children,
  ...other
}) {
  return (
    <MediaQuery maxWidth={SCREEN_XS}>
      {(isXs) => (
        <MarketingHero {...other} contentClassName={s.splashBody}>
          <SplashTitle style={titleStyle}>{title}</SplashTitle>

          {text && <div className={s.splashText}>{text}</div>}

          {children}

          <Testimonial
            dark
            pictureSize={isXs ? 35 : 84}
            iconSize={isXs ? 21 : 32}
            quoteStyle={{ fontSize: isXs ? 16 : 20 }}
            contentStyle={{ flexDirection: isXs ? 'column' : 'row' }}
            {...testimonial}
            className={s.testimonial}
          />
        </MarketingHero>
      )}
    </MediaQuery>
  );
}

export default SplashHero;

import React, { Component, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TextField } from '@mui/material';
import Button from '../Button';
import { shortenUrl } from '../../actions/url';
import { notify } from '../../actions/ui';
import { white, primary } from '../../core/colors';

function ShareableLink(props) {
  const [url, setUrl] = useState(props.url);
  const [loading, setLoading] = useState(false);
  const [shortened, setShortened] = useState(false);
  const {
    enableShorten = true,
    inputStyle,
    buttonStyle,
    shortenUrl,
    notify,
  } = props;

  const shorten = useCallback(async () => {
    if (loading) return;

    setLoading(true);

    try {
      const newUrl = await shortenUrl(url);
      setUrl(`https://${newUrl}`);
      setShortened(true);
    } catch (error) {
      notify('Failed to shorten URL', 'error');
      setShortened(false);
    } finally {
      setLoading(false);
    }
  }, [url, shortenUrl, notify]);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <TextField
        label="LinkedIn referral URL"
        value={loading ? 'Shortening...' : url}
        style={{ flex: 1, marginRight: 10, ...inputStyle }}
      />
      <CopyToClipboard text={url}>
        <Button
          size="small"
          backgroundColor={white}
          fontColor={primary}
          variant="inverted"
        >
          Copy Link
        </Button>
      </CopyToClipboard>
      {enableShorten && !shortened && (
        <Button
          size="small"
          disabled={loading}
          onClick={shorten}
          style={{ marginLeft: 10, ...buttonStyle }}
          backgroundColor={white}
          fontColor={primary}
          variant="inverted"
        >
          Shorten
        </Button>
      )}
    </div>
  );
}

export default connect(undefined, {
  shortenUrl,
  notify,
})(ShareableLink);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { TextField } from '../FormAdapters';
import Auth from '../Auth';
import Link from '../Link';
import { signup } from '../../core/login';
import { isEmailValid } from '../../core/util';
import { getTracking } from '../../core/tracking';

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.password || values.password.length < 5) {
    errors.password = 'Password must be at least 5 characters long';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

function Register(props) {
  const {
    domain,
    signup,
    signupType,
    nextUrl,
    altNextUrl,
    handleSubmit,
    signinLink,
    invite,
    children,
    signupLabel,
    signinLabel,
    tags,
  } = props;
  const [errors, setErrors] = React.useState({});

  const submit = async (values) => {
    const tracking = getTracking() || {};
    try {
      await signup(
        {
          subdomain: domain && domain.subdomain,
          email: values.email,
          password: values.password,
          first_name: values.firstName,
          last_name: values.lastName,
          signup_type: signupType,
          landing_url: tracking.landing,
          invite,
          tags,
        },
        nextUrl || undefined
      );
    } catch (e) {
      const errorsReport = {};
      switch (e.message) {
        case 'unauthorized email domain':
          errorsReport.email = renderUnauthorizedEmailDomainError();
          break;
        case 'user with this email already exists':
          errorsReport.email = 'User with this email already exists';
          break;
        case 'invalid first name':
          errorsReport.firstName = 'Invalid name';
          break;
        case 'invalid last name':
          errorsReport.lastName = 'Invalid name';
          break;
        default:
          throw e;
      }
      setErrors(errorsReport);
    }
  };

  contactUs = () => {
    window.HubSpotConversations?.widget?.open();
  };

  const renderUnauthorizedEmailDomainError = () => (
    <div>
      You must have a &quot;{props.domain.email_domain}&quot; email to sign up.{' '}
      <br />
      Please{' '}
      <Link style={{ cursor: 'pointer' }} onClick={contactUs}>
        contact us
      </Link>{' '}
      for help.
    </div>
  );

  return (
    <div>
      {children}
      <Auth
        domain={domain}
        invite={invite}
        signinLink={signinLink}
        signupType={signupType}
        signupLabel={signupLabel}
        signinLabel={signinLabel}
        next={nextUrl}
        altNext={altNextUrl}
        onSubmit={handleSubmit(submit)}
      >
        <Field
          id="registerFirstName"
          name="firstName"
          component={TextField}
          fullWidth
          label="First name"
          errorText={errors.firstName}
        />
        <Field
          id="registerLastName"
          name="lastName"
          component={TextField}
          fullWidth
          label="Last name"
          errorText={errors.lastName}
        />
        <Field
          id="registerEmail"
          name="email"
          component={TextField}
          fullWidth
          autoComplete="email"
          label="Email address"
          errorText={errors.email}
        />
        <Field
          id="registerPassword"
          name="password"
          component={TextField}
          fullWidth
          autoComplete="new-password"
          label="Password"
          type="password"
        />
      </Auth>
    </div>
  );
}

Register.propTypes = {
  domain: PropTypes.object,
  nextUrl: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  signupType: PropTypes.string,
  invite: PropTypes.string,
  tags: PropTypes.array,
};

Register = reduxForm({
  form: 'register',
  validate,
})(Register);

Register = connect(
  (_, ownProps) => ({
    initialValues: {
      email: ownProps.defaultEmail,
    },
  }),
  {
    signup,
  }
)(Register);

export default Register;

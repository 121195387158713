import { gql } from '@apollo/client';
import ActionTypes from '../core/ActionTypes';

const { ACTIVITY__MERGE } = ActionTypes;

export function fetchActivities(objectType, objectId, actions, cursor) {
  return async (dispatch, getState, { graphql }) => {
    const { activities: page } = await graphql.query(
      gql`
        query fetchActivities(
          $cursor: String
          $actions: [String]
          $objectType: String!
          $objectId: String!
        ) {
          activities(
            after: $cursor
            object_type: $objectType
            object_id: $objectId
            actions: $actions
          ) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                user {
                  id
                  name
                  html_url
                }
                object_type
                object_id
                action
                timestamp
                context {
                  ... on RawActivityContext {
                    raw
                  }
                  ... on UserActivityContext {
                    user {
                      name
                      html_url
                    }
                  }
                  ... on NetworkExpertActivityContext {
                    team_note
                    created_by {
                      name
                      html_url
                    }
                    from_group {
                      name
                      html_url
                    }
                    add_method
                    profile {
                      name
                      html_url
                    }
                    network {
                      id
                      name
                    }
                  }
                  ... on ConsultationActivityContext {
                    raw
                    consultation {
                      html_url
                    }
                    requester {
                      name
                      html_url
                    }
                    expert {
                      name
                      html_url
                    }
                    expert_request {
                      name
                      html_url
                    }
                    group {
                      name
                      html_url
                    }
                  }
                  ... on CandidateActivityContext {
                    raw
                    expert {
                      name
                      html_url
                    }
                    expert_request {
                      name
                      html_url
                    }
                    group {
                      name
                      html_url
                    }
                    client {
                      name
                      html_url
                    }
                    research_manager {
                      name
                      html_url
                    }
                  }
                }
              }
            }
          }
        }
      `,
      { objectType, objectId, actions, cursor }
    );

    if (page) {
      Object.assign(page, { objectType, objectId });
      dispatch({ type: ACTIVITY__MERGE, page });
    }
  };
}

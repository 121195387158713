import React from 'react';
import Button from './Button';

const labelStyle = {
  letterSpacing: '-0.5px',
};

const overlayStyle = {
  paddingLeft: 8,
  paddingRight: 8,
};

function RequestConsultationButton(props) {
  const { size = 'large', label = 'Request Call', ...rest } = props;
  return (
    <Button
      size={size}
      label={label}
      {...rest}
      sx={{ ...labelStyle, ...overlayStyle }}
    />
  );
}

export default RequestConsultationButton;

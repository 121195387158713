import React, { memo, useCallback, useEffect, useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MuiAutocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import isEqual from 'lodash.isequal';

const defaultGetOptionValue = (o) => (o && o.value) || '';
const defaultGetOptionLabel = (o) => (o && o.label) || '';
const defaultIsOptionEqualToValue = (o, v) => o.value === v.value;

const defaultFilterOptions = (limit, getOptionLabel) =>
  createFilterOptions({
    limit,
    trim: true,
    stringify: getOptionLabel,
  });

export const Autocomplete = memo(
  ({
    // Autocomplete props
    value,
    multiple,
    options = [],
    onChange,
    rawValueOnChange,
    getOptionValue = defaultGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
    isOptionEqualToValue = defaultIsOptionEqualToValue,
    limit = 10,
    sort = true,
    freeSolo,
    selectOnExactMatchInMultiMode = false,

    // TextInput props
    TextFieldProps = {},
    label,
    placeholder,
    margin,
    native,

    ...other
  }) => {
    const autocompleteRef = React.createRef();
    const [autocompleteWidth, setAutocompleteWidth] = useState(0);

    const resizeAutocomplete = useCallback(() => {
      setAutocompleteWidth(autocompleteRef.current?.clientWidth);
    }, [autocompleteRef, setAutocompleteWidth]);

    useEffect(() => {
      resizeAutocomplete();
      window.addEventListener('resize', resizeAutocomplete);
      return () => window.removeEventListener('resize', resizeAutocomplete);
    }, [resizeAutocomplete]);

    const onChangeFixed = useCallback((event, selected) => {
      const newValue = multiple
        ? selected.map(getOptionValue)
        : getOptionValue(selected) || '';

      // Same format used for Redux
      if (rawValueOnChange) {
        onChange(newValue);
      } else {
        // Use same format as the Select component
        onChange({ target: { value: newValue } });
      }
    }, []);

    // Clear the selection if input is empty and control is in single mode
    // Append(if in multiple mode) or override the selection if exact match
    const onInputChange = useCallback(
      (event, inputValue) => {
        if (!multiple && inputValue === '') {
          return onChangeFixed(event, '');
        }

        if (selectOnExactMatchInMultiMode || !multiple) {
          const val = options.find(
            (c) =>
              (c.name && c.name.toLowerCase() === inputValue.toLowerCase()) ||
              (c.label && c.label.toLowerCase() === inputValue.toLowerCase())
          );

          if (val) {
            if (!multiple || !value.includes(val)) {
              onChangeFixed(event, multiple ? [...value, val] : val);
            }
          }
        }
      },
      [value, onChangeFixed]
    );

    if (sort) {
      options = options.sort((a, b) => {
        const aValue = getOptionLabel(a).trim().toUpperCase();
        const bValue = getOptionLabel(b).trim().toUpperCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });
    }

    // Internally the Autocomplete value must be the selected options in order
    // to work properly, we use isEqual to avoid the need for reference
    // based equality when value is an object
    const filteredValue = multiple
      ? options.filter(
          (o) => value && value.find((v) => isEqual(v, getOptionValue(o)))
        )
      : options.find((o) => isEqual(value, getOptionValue(o))) || null;

    return (
      <MuiAutocomplete
        multiple={multiple}
        options={options}
        onChange={onChangeFixed}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={defaultFilterOptions(limit, getOptionLabel)}
        PopperComponent={(props) => (
          <Popper
            {...props}
            style={{ width: autocompleteWidth }}
            placement="bottom-start"
          />
        )}
        ref={autocompleteRef}
        freeSolo={freeSolo}
        value={freeSolo ? value : filteredValue}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              ...(TextFieldProps.InputProps || {}),
            }}
            inputProps={{
              ...params.inputProps,
              ...(TextFieldProps.inputProps || {}),
            }}
            // When using redux form the following props are not passed directly
            // and instead are placed in to TextFieldProps
            onClick={(e) => e.stopPropagation()}
            label={label || TextFieldProps.label}
            placeholder={placeholder || TextFieldProps.placeholder}
            margin={margin || TextFieldProps.margin}
          />
        )}
        {...other}
        onBlur={null}
      />
    );
  }
);

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
    marginRight: 5,
  },
});

export const Select = memo(
  ({
    // FormControl props
    error,
    disabled,
    fullWidth = true,
    margin,

    // FormControl children props
    FormHelperTextProps,
    label,
    helperText,

    // Custom Label props
    LabelProps,

    // Custom Select props
    menuItemClasses,
    displayCheckbox,
    options,
    includeEmpty,
    getOptionValue = defaultGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
    sort = true,
    native,

    // Material UI Select props
    value,
    multiple,
    onChange,
    ...other
  }) => {
    const classes = useStyles();

    if (sort) {
      options = options.sort((a, b) => {
        const aValue = getOptionLabel(a).trim().toUpperCase();
        const bValue = getOptionLabel(b).trim().toUpperCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });
    }

    if (native) {
      const nativeOnChange = ({ target: { value } }) =>
        value ? onChange([value]) : onChange([]);

      return (
        <MuiSelect
          native
          fullWidth={fullWidth}
          value={value.length ? value[0] : ''}
          onChange={nativeOnChange}
        >
          <option value="" disabled={!includeEmpty} />
          {options.map((op) => (
            <option value={getOptionValue(op)}>{getOptionLabel(op)}</option>
          ))}
        </MuiSelect>
      );
    }

    const menuItems = options.map((o) => {
      const itemValue = getOptionValue(o);
      const itemLabel = getOptionLabel(o);

      const isSelected = multiple
        ? value.some((v) => v === itemValue)
        : value === itemValue;

      return (
        <MenuItem key={itemValue} value={itemValue} classes={menuItemClasses}>
          {displayCheckbox && (
            <Checkbox
              classes={{ root: classes.checkbox }}
              checked={isSelected}
            />
          )}
          {itemLabel}
        </MenuItem>
      );
    });

    if (includeEmpty) {
      menuItems.unshift(<MenuItem key="" value="" />);
    }

    return (
      <FormControl
        margin={margin}
        error={error}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {label && <InputLabel {...LabelProps}>{label}</InputLabel>}
        <MuiSelect
          value={value}
          multiple={multiple}
          onChange={onChange}
          {...other}
        >
          {menuItems}
        </MuiSelect>
        {helperText && (
          <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default memo(
  ({
    autocomplete, // Autocomplete props
    isOptionEqualToValue, // Select props
    includeEmpty,
    useNativeSelectForMobile,
    ...other
  }) => {
    let native = false;

    if (useNativeSelectForMobile) {
      const theme = useTheme();
      native = useMediaQuery(theme.breakpoints.down('md'));
    }

    return autocomplete && !native ? (
      <Autocomplete isOptionEqualToValue={isOptionEqualToValue} {...other} />
    ) : (
      <Select includeEmpty={includeEmpty} native={native} {...other} />
    );
  }
);

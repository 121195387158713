import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Link from '../Link';
import FAIcon from '../Icon/FAIcon';
import { primary, darkBlue } from '../../core/colors';

function initials(user) {
  if (!user) return;

  const { first_name: firstName, last_name: lastName, name } = user;

  let initials = '';

  if (firstName) {
    initials += firstName[0];
  }

  if (lastName) {
    initials += lastName[0];
  }

  if (!initials && name) {
    initials = name[0];
  }

  return initials.toUpperCase();
}

const useStyles = makeStyles(() => ({
  root: {
    borderColor: primary,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
}));

function Picture(props) {
  const {
    user,
    size = 50,
    link = true,
    ring = false,
    openInNewTab = false,
    children,
    className,
    style,
    ...other
  } = props;

  const { picture_url: pictureUrl, html_url: htmlUrl } = user || {};
  const src = props.src || pictureUrl;
  const href = props.href || htmlUrl;
  const shouldRenderLink = href && link;

  const styles = useStyles();
  const avatarClassName = cx(styles.root, {
    [className]: !!className && !shouldRenderLink,
  });
  const customStyle = {
    fontSize: size / 2,
    width: size,
    height: size,
    borderWidth: ring ? size * 0.05 : 0,
    backgroundColor: user ? undefined : darkBlue,
  };

  let avatar = (
    <Avatar
      className={avatarClassName}
      src={src}
      style={{ ...customStyle, ...style }}
      {...other}
    >
      {children || initials(user) || (
        <FAIcon iconSet="fal" icon="user" fixedWidth style={{ margin: 0 }} />
      )}
    </Avatar>
  );

  if (shouldRenderLink) {
    avatar = (
      <Link className={className} to={href} newTab={openInNewTab}>
        {avatar}
      </Link>
    );
  }

  return avatar;
}

// Needed for some material UI logic
Picture.muiName = 'Avatar';

export default Picture;

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomSelect from '../../../components/Select';

const useStyles = makeStyles({
  keyword: {
    overflow: 'hidden',
  },
});

export default function SelectInput({
  selected,
  setSelected,
  placeholder,
  options,
  className,
  primary,
  ChipProps,
  ...rest
}) {
  const s = useStyles();
  const theme = useTheme();
  const native = useMediaQuery(theme.breakpoints.down('md'));

  return native ? (
    <CustomSelect
      useNativeSelectForMobile
      onChange={setSelected}
      value={selected || ''}
      options={options || []}
      {...rest}
    />
  ) : (
    <CustomSelect
      autocomplete
      multiple
      openOnFocus
      className={cx(s.keyword, className)}
      options={options || []}
      getOptionValue={(op) => op}
      isOptionEqualToValue={(o, v) => o === v}
      rawValueOnChange
      onChange={setSelected}
      value={selected}
      TextFieldProps={{
        inputProps: { autoComplete: 'nope', maxLength: 64 },
        style: { fontSize: 14 },
      }}
      ChipProps={{
        ...(primary
          ? {
              // seconday color is darkGreen which is the intended result
              // when primary is true
              color: 'secondary',
            }
          : {}),
        ...ChipProps,
      }}
      input={{ value: selected || '' }}
      {...rest}
    />
  );
}

import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form';
import { setAddress } from '../../actions/address';
import { requestAddExpertRequestCandidate } from '../../actions/expertRequest';
import { notify } from '../../actions/ui';
import Button from '../../components/Button/Button';
import EditDialog from '../../components/EditDialog';
import { TextField } from '../../components/FormAdapters/FormAdapters';
import LayoutPage from '../../components/Layout/LayoutPage';
import MediaQuery from '../../components/MediaQuery';
import PhoneInput from '../../components/PhoneInput';
import Queries from '../../components/Queries';
import ConfirmationRow from '../../components/Wizard/ConfirmationRow';
import { SCREEN_XS } from '../../core/muiTheme';
import { ErrAddressAlreadyTaken } from '../../core/address';
import { borderColor } from '../../core/colors';
import { isPhoneValid } from '../../core/util';
import s from './ExpertRequestAddRequest.module.scss';

class UpdatePhone extends PureComponent {
  handleSubmit = (values) => {
    const { setAddress, viewer, onSubmit, notify } = this.props;

    return setAddress(viewer.profile.id, 'phone', values.phone, true)
      .then(() => {
        onSubmit();
      })
      .catch((err) => {
        if (err.message === ErrAddressAlreadyTaken.message) {
          throw new SubmissionError({
            phone: 'Phone number is already in use.',
          });
        }
        notify('An error occurred when updating the phone.', 'error');
      });
  };

  render() {
    const { open, handleSubmit, onClose, allCountries } = this.props;

    return (
      <EditDialog
        open={open}
        title="What is your preferred contact number?"
        onSubmit={handleSubmit(this.handleSubmit)}
        onClose={onClose}
      >
        <p className={s.updatePhoneText}>
          We may contact you with a few additional questions.
        </p>
        <Field
          id="communicationPhone"
          component={PhoneInput}
          type="tel"
          name="phone"
          showExampleOnError
          allCountries={allCountries}
        />
      </EditDialog>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!isPhoneValid(values.phone)) {
    errors.phone = 'Phone must be valid.';
  }

  return errors;
}

UpdatePhone = reduxForm({
  form: 'updatePhone',
  validate,
})(UpdatePhone);

UpdatePhone = connect(
  (state) => {
    return {
      viewer: state.viewer,
      allCountries: state.countries,
      initialValues: {
        phone: state.viewer.phone,
      },
    };
  },
  {
    setAddress,
    notify,
  }
)(UpdatePhone);

function ExpertRequestAddRequest(props) {
  const navigate = useNavigate();
  const [updatePhoneOpen, setUpdatePhoneOpen] = useState(false);
  const {
    requestAddExpertRequestCandidate,
    notify,
    expertRequest,
    viewer,
    handleSubmit,
  } = props;

  const selfHandleSubmit = (values) => {
    requestAddExpertRequestCandidate(
      {
        expert_request_id: expertRequest.slug,
        ...values,
      },
      'expert'
    )
      .then(() => {
        if (viewer.phone) {
          redirectExpert();
        } else {
          setUpdatePhoneOpen(true);
        }
      })
      .catch((err) => {
        if (err?.message?.includes('A question can only be selected once')) {
          notify(err.message, 'warning');
          return;
        }
        notify('An error occurred when submitting.', 'error');
        throw err;
      });
  };

  const redirectExpert = () => {
    setUpdatePhoneOpen(false);

    if (viewer.expert_state === 'active' || viewer.expert_state === 'applied') {
      navigate('/dashboard');
      notify(
        'Thank you! Our research team will be notified about your interest.'
      );
    } else {
      navigate('/signup/expert');
      notify(
        'Thank you! Please complete your application to increase your chances of being matched.'
      );
    }
  };

  const hasQuestions = expertRequest.questions?.length > 0;
  const hasQualifications = expertRequest.qualifications?.length > 0;

  return (
    <LayoutPage hideSearch>
      {hasQuestions || hasQualifications ? (
        <div className={s.queries}>
          <h4 className={s.name}>{expertRequest.name}</h4>
          {hasQuestions && (
            <>
              <h3 className={s.title}>
                Are you able to discuss these questions?
              </h3>
              <h4 className={s.subTitle}>
                If you are able to discuss these areas without conflict then
                there is an increased likelihood you will be engaged for a
                consultation.
              </h4>

              <ConfirmationRow
                separator={false}
                separatorColor={borderColor}
                bodyClassName={s.confirmationRowBody}
                titleClassName={s.confirmationRowTitle}
              >
                <FieldArray
                  name="question_answers"
                  component={Queries}
                  queries={expertRequest.questions}
                />
              </ConfirmationRow>
            </>
          )}
          {hasQualifications && (
            <>
              <h3 className={s.title}>Do you have these qualifications?</h3>
              <h4 className={s.subTitle}>
                If you have these desired qualifications then there is an
                increased likelihood you will be engaged for a consultation.
              </h4>

              <ConfirmationRow
                separator={false}
                separatorColor={borderColor}
                bodyClassName={s.confirmationRowBody}
                titleClassName={s.confirmationRowTitle}
              >
                <FieldArray
                  name="qualification_responses"
                  component={Queries}
                  queries={expertRequest.qualifications}
                />
              </ConfirmationRow>
            </>
          )}
        </div>
      ) : (
        <h3 className={s.title}>{expertRequest.name}</h3>
      )}

      <div>
        <h3 className={s.title}>Additional relevant work experience</h3>
        <h4 className={s.subTitle}>
          Describe how your work experience qualifies you to discuss this
          subject matter.
        </h4>

        <ConfirmationRow
          separator={false}
          separatorColor={borderColor}
          bodyClassName={s.confirmationRowBody}
          titleClassName={s.confirmationRowTitle}
        >
          <MediaQuery maxWidth={SCREEN_XS}>
            {(isMobileVersion) => (
              <Field
                id="describeExperience"
                component={TextField}
                fullWidth
                multiline
                name="match_experience"
                label="Add Note"
                minRows={isMobileVersion ? 2 : 1}
                maxRows={10}
              />
            )}
          </MediaQuery>
        </ConfirmationRow>
      </div>

      <Button
        size="large"
        style={{ marginTop: 30 }}
        onClick={handleSubmit(selfHandleSubmit)}
      >
        Submit
      </Button>

      <UpdatePhone
        onSubmit={redirectExpert}
        onClose={() => setUpdatePhoneOpen(false)}
        open={updatePhoneOpen}
      />
    </LayoutPage>
  );
}

ExpertRequestAddRequest = ExpertRequestAddRequest;

ExpertRequestAddRequest = reduxForm({
  form: 'expertIntroduction',
})(ExpertRequestAddRequest);

const initTextResponse = { text_response: '' };

ExpertRequestAddRequest = connect(
  (state, ownProps) => ({
    initialValues: {
      match_experience: '',
      question_answers: ownProps.expertRequest.questions
        ? ownProps.expertRequest.questions.map((q) => ({
            ...(q.response_type === 'yes_no' ? {} : initTextResponse),
            query_id: q.id,
          }))
        : [],
      qualification_responses: ownProps.expertRequest.qualifications
        ? ownProps.expertRequest.qualifications.map((q) => ({
            ...(q.response_type === 'yes_no' ? {} : initTextResponse),
            query_id: q.id,
          }))
        : [],
    },
  }),
  {
    requestAddExpertRequestCandidate,
    notify,
    setAddress,
  }
)(ExpertRequestAddRequest);

export default ExpertRequestAddRequest;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { updateProfile } from '../../../actions/profile';
import EditableList from '../../EditableList';
import MediaQuery from '../../MediaQuery';
import { SCREEN_XS } from '../../../core/muiTheme';

class QuestionsForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    component: PropTypes.func,
    reset: PropTypes.func,
    profileId: PropTypes.string,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    updateProfile: PropTypes.func,
  };

  handleSubmit = (values) => {
    const { profileId, onSubmit, updateProfile } = this.props;
    updateProfile({
      id: profileId,
      questions: values.questions.filter((x) => x),
    });
    if (onSubmit) onSubmit(values);
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();
    if (onReset) onReset();
  };

  render() {
    const {
      component: Container,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion) => {
          return (
            <Container
              {...other}
              onSubmit={handleSubmit(this.handleSubmit)}
              onReset={this.handleReset}
            >
              <FieldArray
                name="questions"
                component={EditableList}
                addButtonLabel="Question"
                inputProps={{
                  placeholder:
                    !isMobileVersion &&
                    'example: "What\'s the regulatory environment like for roads and infrastructure in Mexico?"',
                  fullWidth: true,
                }}
              />
            </Container>
          );
        }}
      </MediaQuery>
    );
  }
}

function validate(/* values */) {
  return {};
}

QuestionsForm = reduxForm({
  form: 'expertQuestions',
  validate,
  enableReinitialize: true,
})(QuestionsForm);

QuestionsForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const questions =
      profile.questions && profile.questions.length > 0
        ? profile.questions
        : [''];

    return {
      profile,
      initialValues: {
        questions,
      },
    };
  },
  {
    updateProfile,
  }
)(QuestionsForm);

export default QuestionsForm;

import actionTypes from '../core/ActionTypes';

const { VIEWER__RESET } = actionTypes;

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIEWER__RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

import TextFieldStatic from '@mui/material/TextField';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  addConsultation,
  Trigger,
  updateConsultation,
} from '../../actions/consultation';
import { presignedFilestackURL } from '../../actions/filestack';
import { notify, openFileDialog } from '../../actions/ui';
import { primary } from '../../core/colors';
import Button from '../Button/Button';
import DateTimePicker from '../DateTimePicker';
import Dialog from '../Dialog';
import { TextField } from '../FormAdapters';
import FAIcon from '../Icon/FAIcon';
import MaterialIcon from '../Icon/MaterialIcon';
import MediaPlayer from '../MediaPlayer';
import SelectNameable from '../SelectNameable';
import s from './EditExternalConsultation.module.scss';

const ChooseFile = ({ label, onClick, disabled, meta: { touched, error } }) => (
  <div className={s.upload}>
    <Button size="small" label={label} onClick={onClick} disabled={disabled} />
    {error && touched && <div className={s.error}>{error}</div>}
  </div>
);

function validate(values) {
  const errors = {};

  if (!values.recording_url) {
    errors.recording_url = 'Required';
  }

  if (!values.started_at) {
    errors.started_at = 'Required';
  }

  if (!values.requester_name) {
    errors.requester_name = 'Required';
  }

  if (!values.expert_name) {
    errors.expert_name = 'Required';
  }

  if (!values.group_id && !values.expert_request_id) {
    const errorMessage = 'Either Expert Request or Group is Required';
    errors.expert_request_id = errorMessage;
    errors.group_id = errorMessage;
  }

  return errors;
}

const EditExternalConsultation = (props) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState('');

  const setDuration = (audio) => {
    const duration = audio && audio.duration && `${audio.duration}s`;
    if (props.recordingDuration === duration) {
      props.change('recording_duration', duration);
    }
  };

  const handleSubmitFn = (values) => {
    const {
      adding,
      consultation,
      onSubmit,
      addConsultation,
      updateConsultation,
      notify,
    } = props;

    setSubmitting(true);

    if (adding) {
      addConsultation(values)
        .then((consultation) => {
          setSubmitting(false);
          navigate(`/consultation/${consultation.id}`);
        })
        .catch((err) => {
          setSubmitting(false);
          if (
            err &&
            err.message &&
            err.message.startsWith(
              'GraphQL Error: no billing account associated with group or expert request'
            )
          ) {
            notify('Unable to find billing account for consultation.', 'error');
            return;
          }
          notify('Error when adding external consultation.', 'error');
          throw err;
        });
    } else {
      updateConsultation({
        id: consultation.id,
        trigger: Trigger.consultationPage,
        ...values,
      })
        .then(() => {
          setSubmitting(false);
          return onSubmit();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  const pickFile = async () => {
    const { openFileDialog, change, presignedFilestackURL } = props;
    openFileDialog({
      accept: ['.mp3', '.mp4', '.m4a'],
      maxSize: 2 * 1024 * 1024 * 1024, // 2 GB
    }).then(async (file) => {
      change('recording_filename', file.filename);
      change('recording_url', file.url);
      change('recording_file_size', file.size);

      const recordingUrl = await presignedFilestackURL(file.url);
      setRecordingUrl(recordingUrl);
    });
  };

  const handleReset = () => {
    const { onClose, reset } = props;
    setSubmitting(false);
    reset();
    if (onClose) onClose();
  };

  const {
    viewer,
    adding,
    handleSubmit,
    recordingFilename,
    expertRequests,
    expertRequest,
    consultation,
    initialValues,
    ...other
  } = props;

  const enterpriseExpertRequests =
    expertRequests.edges
      ?.filter(
        ({ node: er }) => er.project?.group?.account_type === 'enterprise'
      )
      .map((e) => e.node) || [];

  const enterpriseGroups =
    viewer.groups?.filter((g) => g.account_type === 'enterprise') || [];
  const expertRequestGroup = expertRequest?.node?.project?.group;
  const singleGroup =
    expertRequestGroup ||
    (enterpriseGroups.length === 1 && enterpriseGroups[0]);

  if (singleGroup) {
    // ER, if selected, will override this single group default
    initialValues.group_id = singleGroup.id;
  }

  const transcribing = !!(
    consultation &&
    !consultation.transcription &&
    consultation.transcription_order
  );
  const transcribed = !!(consultation && consultation.transcription);

  return (
    <Dialog
      onCancel={handleReset}
      onConfirm={handleSubmit(handleSubmitFn)}
      cancelLabel="Cancel"
      confirmLabel={
        submitting
          ? adding
            ? 'Adding...'
            : 'Saving...'
          : adding
            ? 'Add Consultation'
            : 'Save Consultation'
      }
      disableSubmit={submitting || recordingUrl === ''}
      {...other}
      onClose={handleReset}
    >
      <div className={s.header}>
        <MaterialIcon
          icon={adding ? 'file_upload' : 'mode_edit'}
          color={primary}
          size={40}
        />

        <h4 className={s.title}>
          {adding ? 'Add' : 'Edit'} External Consultation
        </h4>

        {adding && (
          <p className={s.text}>
            Upload an audio or video file from a call made outside of
            OnFrontiers to get a full transcript and search through it alongside
            your other calls. Allowed file types MP3, MP4, and M4A.
          </p>
        )}
      </div>

      <Field
        component={ChooseFile}
        label={adding ? 'Choose File' : 'Change File'}
        name="recording_url"
        onClick={pickFile}
        disabled={transcribing || transcribed}
      />

      {recordingUrl && !transcribed && (
        <MediaPlayer enabled src={recordingUrl} onAudioSync={setDuration} />
      )}

      {(recordingFilename || transcribed || transcribing) && (
        <div className={s.uploadInstructions}>
          {recordingFilename && (
            <div className={s.uploaded}>
              <FAIcon icon="check" size={16} /> Uploaded {recordingFilename}
            </div>
          )}
          {transcribing && (
            <div className={s.instruction}>
              Audio files cannot be changed while transcription is in process
            </div>
          )}
          {transcribed && (
            <div className={s.instruction}>
              Audio files cannot be changed after they have been transcribed
            </div>
          )}
        </div>
      )}

      <Field
        component={DateTimePicker}
        name="started_at"
        placeholder="Date"
        maxDate={new Date()}
        dateOnly
        buttonClear={false}
        fullWidth={false}
        style={{ marginLeft: -3, maxWidth: 200, marginTop: 20 }}
        timezone={viewer.timezone}
      />

      <div className={s.users}>
        <Field
          component={TextField}
          fullWidth
          name="requester_name"
          label="Interviewer"
          style={{ marginRight: 5 }}
        />

        <Field
          component={TextField}
          fullWidth
          name="expert_name"
          label="Expert"
          style={{ marginLeft: 5 }}
        />
      </div>

      {enterpriseExpertRequests.length > 0 && (
        <Field
          component={SelectNameable}
          label="Add to Expert Request"
          name="expert_request_id"
          options={enterpriseExpertRequests}
        />
      )}
      {singleGroup ? (
        <TextFieldStatic
          label="Add to Group"
          name="group_id"
          value={singleGroup.name}
          disabled
        />
      ) : (
        <Field
          id="selectGroup"
          component={SelectNameable}
          label="Add to Group"
          name="group_id"
          options={enterpriseGroups}
        />
      )}
    </Dialog>
  );
};

const EditExternalConsultationWithReduxForm = reduxForm({
  validate,
  enableReinitialize: true,
  initialValues: { engagement_type: 'consultation' },
})(EditExternalConsultation);

export default connect(
  (state, ownProps) => {
    const adding = !ownProps.consultation;
    // AC: do not conflict form names
    // When going from consultation list to consultation details the form is not
    // reinitialized for some reason, so the edit dialog shows empty values
    const form = adding
      ? 'addExternalConsultation'
      : 'editExternalConsultation';
    const formValue = formValueSelector(form);
    const props = {
      form,
      adding,
      viewer: state.viewer,
      userContext: state.ui.userContext,
      expertRequests: state.expertRequests.open,
      recordingUrl: formValue(state, 'recording_url'),
      recordingFilename: formValue(state, 'recording_filename'),
      recordingDuration: formValue(state, 'recording_duration'),
      recordingFileSize: formValue(state, 'recording_file_size'),
      expertRequest: state.expertRequests.open?.edges?.find(
        (e) =>
          e.node.id ===
          state.form?.addExternalConsultation?.values?.expert_request_id
      ),
    };
    return props;
  },
  {
    openFileDialog,
    notify,
    addConsultation,
    updateConsultation,
    presignedFilestackURL,
  }
)(EditExternalConsultationWithReduxForm);

import { print, parse } from 'graphql';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const PERMISSION_ERROR_MESSAGE =
  "You don't have permission to perform this action.";
const OTP_ERROR_MESSAGES = {
  otp_required: 'otp required',
  otp_enrollment_required: 'otp enrollment required',
};

function hasError(errors, message) {
  return (errors || []).some((e) => e.message && e.message.startsWith(message));
}

export class TransportError extends Error {
  constructor(message, payload) {
    super(message);
    this.name = 'TransportError';
    this.extra = { payload };
  }
}

export class UnauthorizedError extends Error {
  constructor(message, remainingAttempts) {
    super(message);
    this.name = this.constructor.name;
    this.remainingAttempts = remainingAttempts;
    this.isPermissionError = true;
  }
}

export class APIError extends Error {
  constructor(errors, data, query, variables) {
    const payload = { query, variables };
    const { message } = errors[0];
    super(`GraphQL Error: ${message}`);
    this.rawError = errors;
    this.rawData = data;
    this.query = query;
    this.extra = { payload, errors };
    this.variables = variables;
    this.isPermissionError = hasError(errors, PERMISSION_ERROR_MESSAGE);
  }
}

export function basicAuth(username, password) {
  const token = btoa(`${username}:${password}`);
  return `Basic ${token}`;
}

export function bearerAuth(token) {
  return `Bearer ${token}`;
}

export class GraphQLClient {
  constructor(url, auth, { otp, extraHeaders = {} } = {}) {
    const httpLink = new HttpLink({ uri: `${url}/graphql` });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(auth && { authorization: auth }),
          ...(otp && { 'x-onfrontiers-auth-otp': otp }),
          ...extraHeaders,
        },
      };
    });

    this.url = url;
    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      devtools: {
        enabled: process.env.NODE_ENV === 'development',
      },
    });
  }

  query(query, variables) {
    return this.send(query, variables);
  }

  mutate(query, variables) {
    if (typeof query === 'string' && !query.startsWith('mutation ')) {
      query = `mutation _${query}`;
    }
    return this.send(query, variables);
  }

  async send(query, variables) {
    if (typeof query === 'string') {
      query = parse(query);
    }

    const isMutation = query.definitions.some(
      (def) =>
        def.kind === 'OperationDefinition' && def.operation === 'mutation'
    );

    try {
      const result = isMutation
        ? await this.client.mutate({ mutation: query, variables })
        : await this.client.query({ query, variables });
      return structuredClone(result.data);
    } catch (err) {
      const serverErr = err.networkError;
      let errors = err.graphQLErrors;
      if (serverErr) {
        const result = serverErr.result;
        switch (serverErr.statusCode) {
          case 401:
            throw new UnauthorizedError(
              result?.reason,
              result?.remaining_attempts
            );
          case 422:
            errors = result.errors;
            break;
          default:
            throw new TransportError(err.message);
        }
      }

      if (errors) {
        for (const [key, message] of Object.entries(OTP_ERROR_MESSAGES)) {
          if (hasError(errors, message)) {
            throw new UnauthorizedError(key);
          }
        }

        throw new APIError(
          structuredClone(errors),
          null,
          print(query),
          variables
        );
      }

      throw err;
    }
  }

  fromAuth = (auth, options) => new GraphQLClient(this.url, auth, options);
}

import qs from 'query-string';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import about from '../routes/about';
import admin from '../routes/admin';
import application from '../routes/application';
import becomeAnExpert from '../routes/becomeAnExpert';
import cases from '../routes/cases';
import changePassword from '../routes/changePassword';
import complianceTraining from '../routes/complianceTraining';
import consultation from '../routes/consultation';
import consultations from '../routes/consultations';
import dashboard from '../routes/dashboard';
import expertNetwork from '../routes/expertNetwork';
import expertRequestNew, {
  addMembers as expertRequestAddMembers,
  expertRequestRoot,
} from '../routes/expertRequestNew';
import expertRequestPublic from '../routes/expertRequestPublic';
import expertRequests from '../routes/expertRequests';
import graphiql from '../routes/graphiql';
import healthCheck from '../routes/healthCheck';
import careers from '../routes/jobs';
import landing from '../routes/landing';
import lead from '../routes/lead';
import legal from '../routes/legal';
import legalAck from '../routes/legalAck';
import login, { loginWithSubdomain } from '../routes/login';
import loginAs from '../routes/loginAs';
import logout from '../routes/logout';
import messaging from '../routes/messaging';
import payoutDashboard from '../routes/payoutDashboard';
import platform from '../routes/platform';
import profile from '../routes/profile';
import profileConflicts from '../routes/profileConflicts';
import profileMerge from '../routes/profileMerge';
import profileUploader from '../routes/profileUploader';
import projectDetails from '../routes/projectDetails';
import register from '../routes/register';
import resetPassword from '../routes/resetPassword';
import search from '../routes/search';
import selectDomain from '../routes/selectDomain';
import settings from '../routes/settings';
import successStories from '../routes/successStories';
import team from '../routes/team';
import teamAboutPage from '../routes/teamAboutPage';
import teams from '../routes/teams';
import teamSettings from '../routes/teamSettings';
import validateEmail from '../routes/validateEmail';
import NotFound from './notFound/NotFound';
import {
  agreementsRequired,
  allRequired,
  loginRequired,
  superAdminRequired,
  verifiedEmailRequired,
} from './routesMiddleware';
import { useEffect } from 'react';
import { useApp } from 'hooks/useAppContext';
import { setLoadingProgress } from '../actions/loading';
import { createRouteResolver } from '../core/util';
import config from '../../config';
import { setRuntimeVariable } from '../actions/runtime';
import { trackPageView } from '..';

const TrackingComponent = ({ children }) => {
  const { store } = useApp();
  const location = useLocation();
  const { userContext } = store.getState().ui;

  useEffect(() => {
    store.dispatch(setLoadingProgress(100));
    window.scrollTo(0, 0);
    // Update referrer on push navigation
    store.dispatch(
      setRuntimeVariable({
        name: 'referrer',
        value: `${window.location.origin}${location.pathname}`,
      })
    );

    trackPageView(location.pathname, document.title);
  }, [location.key, userContext]);

  return children;
};

const routeResolver = createRouteResolver(config.baseDomain);

const buildRoute = (store, route, protectionFn, graphqlClient) => {
  if (route.action) {
    route.loader = ({ params, request }) => {
      const url = new URL(request.url);
      const { subdomain } = routeResolver(url.hostname);
      return route.action({
        store,
        request,
        params,
        graphqlClient,
        location: url,
        path: url.pathname,
        subdomain: subdomain === 'www' ? null : subdomain,
        query: mapQueryParams(request.url),
      });
    };
  }

  if (protectionFn) {
    route = protectionFn(route);
  }

  return {
    path: route.path,
    element: route.element && <TrackingComponent children={route.element} />,
    loader: route.loader,
    children:
      route.children &&
      route.children.map((child) => buildRoute(store, child, protectionFn)),
  };
};

const agreementsWithEmailRequired = (route) => {
  return agreementsRequired(verifiedEmailRequired(route));
};

/**
 * Maps query parameters to an object with key value pairs
 * @param {string} url
 * @returns {Object<string, string>}
 */
const mapQueryParams = (url) => {
  return qs.parse(new URL(url).search, { arrayFormat: 'bracket' });
};

export const Routes = ({ store, graphqlClient }) => {
  const router = createBrowserRouter([
    buildRoute(store, about),
    buildRoute(store, becomeAnExpert),
    buildRoute(store, cases),
    buildRoute(store, careers),
    buildRoute(store, expertRequestPublic),
    buildRoute(store, graphiql),
    buildRoute(store, healthCheck),
    buildRoute(store, landing),
    buildRoute(store, legal),
    buildRoute(store, login),
    buildRoute(store, loginWithSubdomain),
    buildRoute(store, platform),
    buildRoute(store, profile),
    buildRoute(store, resetPassword),
    buildRoute(store, selectDomain),
    buildRoute(store, successStories),
    buildRoute(store, teamAboutPage),
    buildRoute(store, logout),
    buildRoute(store, changePassword),
    buildRoute(store, loginAs, loginRequired, graphqlClient),
    buildRoute(store, legalAck, loginRequired),
    buildRoute(store, application, allRequired),
    buildRoute(store, consultation, allRequired),
    buildRoute(store, consultations, allRequired),
    buildRoute(store, dashboard, allRequired),
    buildRoute(store, expertRequestNew, allRequired),
    buildRoute(store, expertRequestRoot, allRequired),
    buildRoute(store, expertRequestAddMembers, allRequired),
    buildRoute(store, lead, allRequired),
    buildRoute(store, messaging, allRequired),
    buildRoute(store, payoutDashboard, allRequired),
    buildRoute(store, profileConflicts, allRequired),
    buildRoute(store, profileUploader, allRequired),
    buildRoute(store, profileMerge, allRequired),
    buildRoute(store, search, allRequired),
    buildRoute(store, settings, allRequired),
    buildRoute(store, teams, allRequired),
    buildRoute(store, teamSettings, allRequired),
    buildRoute(store, expertRequests, agreementsWithEmailRequired),
    buildRoute(store, register, agreementsRequired),
    buildRoute(store, admin, superAdminRequired),
    ...validateEmail.map((route) => buildRoute(store, route, loginRequired)),
    ...expertNetwork.map((route) => buildRoute(store, route, loginRequired)),
    ...projectDetails.map((route) =>
      buildRoute(store, route, agreementsWithEmailRequired)
    ),
    ...team.map((route) => buildRoute(store, route, allRequired)),
    ...complianceTraining.path.map((path) =>
      buildRoute(
        store,
        {
          path,
          element: complianceTraining.element,
          action: complianceTraining.action,
        },
        allRequired
      )
    ),
    {
      path: '*',
      element: (
        <TrackingComponent>
          <NotFound />
        </TrackingComponent>
      ),
      loader: () => {
        document.title = '404 - Not found';
        return null;
      },
    },
  ]);

  return <RouterProvider router={router} />;
};

import React from 'react';
import Dialog from '../Dialog';
import EditEmails from '../EditEmails/EditEmails';
import EditPhones from '../EditPhones/EditPhones';
import AvailableMarketplaceForm from '../Forms/Profile/AvailableMarketPlaceForm';
import EditDialog from '../EditDialog/EditDialog';
import BillRateForm from '../Forms/Profile/BillRateForm';
import AddAdditionalInformationForm from '../Forms/Profile/AddAdditionalInformationForm';
import CommunicationForm from '../Forms/Profile/CommunicationForm';
import ExpertiseForm from '../Forms/Profile/ExpertiseForm';
import LocationForm from '../Forms/Profile/LocationForm';
import NameForm from '../Forms/Profile/NameForm';
import IntroductionForm from '../Forms/Profile/IntroductionForm';
import QuestionsForm from '../Forms/Profile/QuestionsForm';
import ExperiencesForm from '../Forms/Profile/ExperiencesForm';
import EducationForm from '../Forms/Profile/EducationForm';
import LinkedinForm from '../Forms/Profile/LinkedinForm';
import SecurityForm from '../Forms/Profile/Security';
import AvailableSelfServiceForm from './AvailableSelfServiceForm';
import BackgroundCheckForm from './BackgroundCheckForm';
import KeywordsForm from '../Forms/Profile/KeywordsForm';

export default ({ user, profile, editSections, closeDialog }) => {
  return (
    <div>
      <Dialog
        maxWidth="md"
        title="Edit Emails"
        confirmLabel="Done"
        open={!!editSections.emails}
        onCancel={closeDialog}
        onConfirm={closeDialog}
      >
        <EditEmails
          showSuccessMessage={false}
          userId={user && user.id}
          profileId={profile.id}
        />
      </Dialog>

      <Dialog
        maxWidth="md"
        title="Edit Phones"
        confirmLabel="Done"
        open={!!editSections.phones}
        onCancel={closeDialog}
        onConfirm={closeDialog}
      >
        <EditPhones profileId={profile.id} />
      </Dialog>

      <AvailableMarketplaceForm
        component={EditDialog}
        title="Expert Marketplace Status"
        profileId={profile.id}
        open={!!editSections.available_marketplace}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <AvailableSelfServiceForm
        component={EditDialog}
        title="Expert Available Self Service"
        userId={user && user.id}
        open={!!editSections.available_self_service}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <BackgroundCheckForm
        component={EditDialog}
        title="Background Check Complete"
        userId={user && user.id}
        open={!!editSections.background_check}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <BillRateForm
        component={EditDialog}
        title="Edit Rate"
        profileId={profile.id}
        open={!!editSections.rate}
        onSubmit={closeDialog}
        onClose={closeDialog}
        form="expertAvailabilityFromProfile"
      />

      <CommunicationForm
        component={Dialog}
        useForm
        editPhone={!!user}
        title="Edit Communication"
        profileId={profile.id}
        open={!!editSections.communication}
        onClose={closeDialog}
      />

      <LocationForm
        component={EditDialog}
        title="Edit Location"
        profileId={profile.id}
        open={!!editSections.location}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <NameForm
        component={EditDialog}
        maxWidth="sm"
        title="Edit Name"
        profileId={profile.id}
        open={!!editSections.name}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <IntroductionForm
        component={EditDialog}
        title="Edit Headline and Short Bio"
        profileId={profile.id}
        open={!!editSections.headline}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <QuestionsForm
        component={EditDialog}
        maxWidth="md"
        title="Edit Questions"
        profileId={profile.id}
        open={!!editSections.questions}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <ExpertiseForm
        component={EditDialog}
        maxWidth="md"
        title="Edit Expertise"
        profileId={profile.id}
        open={!!editSections.expertise}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <KeywordsForm
        component={EditDialog}
        maxWidth="md"
        title="Edit Keywords"
        userId={user && user.id}
        profileId={profile.id}
        open={!!editSections.keywords}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <LinkedinForm
        component={EditDialog}
        maxWidth="md"
        title="Edit LinkedIn"
        profileId={profile.id}
        open={!!editSections.linkedin}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <ExperiencesForm
        component={EditDialog}
        maxWidth="lg"
        title="Edit Work Experience"
        profileId={profile.id}
        open={!!editSections.experiences}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <EducationForm
        component={EditDialog}
        maxWidth="lg"
        title="Edit Education"
        profileId={profile.id}
        open={!!editSections.education}
        onSubmit={closeDialog}
        onClose={closeDialog}
      />

      <AddAdditionalInformationForm
        component={EditDialog}
        maxWidth="lg"
        title="Add Additional Information"
        profileId={profile.id}
        open={!!editSections.additional_information}
        onSubmit={closeDialog}
        onClose={closeDialog}
        disableSubmitOnEnter
      />

      <Dialog open={!!editSections.security} onClose={closeDialog}>
        <SecurityForm
          maxWidth="lg"
          title="Edit Security"
          userId={user && user.id}
          onDisableOtpAuth={closeDialog}
        />
      </Dialog>
    </div>
  );
};

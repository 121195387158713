import React, { PureComponent } from 'react';
import cx from 'classnames';
import Picture from '../Picture';
import { white, borderColorHex } from '../../core/colors';
import s from './Message.module.scss';

function getBorderStyle(color, position, align) {
  const borderStyle = {};

  if (align === 'top') {
    borderStyle.borderTopColor = color;
  } else if (align === 'bottom') {
    borderStyle.borderBottomColor = color;
  }

  if (position === 'left') {
    borderStyle.borderRightColor = color;
  } else if (position === 'right') {
    borderStyle.borderLeftColor = color;
  } else if (position === 'top') {
    borderStyle.borderBottomColor = color;
  } else if (position === 'bottom') {
    borderStyle.borderTopColor = color;
  }

  return borderStyle;
}

class Message extends PureComponent {
  render() {
    const {
      className,
      style,
      position = 'left',
      align = 'top',
      children,
      borderColor = borderColorHex,
      backgroundColor = white,
      color,
      user,
      pictureSize,
      bubbleClassName,
      hideArrow,
      icon,
      ...other
    } = this.props;

    const bubbleStyle = { borderColor, backgroundColor, color };
    const rootClassNames = cx(
      s.root,
      className,
      s[`${align}Align`],
      s[`${position}Pos`]
    );
    const bubbleClassNames = cx(
      s.bubble,
      s[`${align}Align`],
      s[`${position}Pos`],
      {
        [bubbleClassName]: !!bubbleClassName,
      }
    );
    const userClassNames = cx(
      s.userPicture,
      s[`${align}Align`],
      s[`${position}Pos`]
    );

    return (
      <div className={rootClassNames} style={style} {...other}>
        <Picture size={pictureSize} className={userClassNames} user={user}>
          {icon}
        </Picture>
        <div style={bubbleStyle} className={bubbleClassNames}>
          {!hideArrow && (
            <span
              style={getBorderStyle(borderColor, position, align)}
              className={s.before}
            />
          )}
          {children}
          {!hideArrow && (
            <span
              style={getBorderStyle(backgroundColor, position, align)}
              className={s.after}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Message;

import { createTheme, SimplePaletteColorOptions } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { colors } from 'tailwind/colors';

import Campton from 'styles/vendor/campton/38D5CC_5_0.woff2';

declare module '@mui/material/styles' {
  interface CustomPalette {
    tertiary: SimplePaletteColorOptions;
    danger: SimplePaletteColorOptions;
    blue: SimplePaletteColorOptions;
    deepPurple: ColorPartial;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    newExperience: true;
  }
}

const {
  palette: { augmentColor: ac },
  breakpoints,
} = createTheme();

const createColor = (main: string, light?: string, dark?: string) =>
  ac({ color: { main, light, dark } });

const theme = createTheme({
  palette: {
    primary: createColor(colors.teal[900], '#33D6C5', '#1E9F92'),
    secondary: createColor('#2389C1', '#2DA1E2', '#1E6D9A'),
    tertiary: createColor('#00646e'),
    error: createColor('#E94F74', '#FF5C83', '#B43A57'),
    warning: createColor('#ED6C02', '#E65100', '#FF9800'),
    success: createColor('#1DC068'),
    danger: createColor('#fb3544'),
    blue: createColor('#007bff'),
    info: createColor('#007BFF', '#3395FF', '#0056B2'),
    common: {
      black: '#151616',
      white: '#FFFFFF',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#2c3533',
    },
    deepPurple: {
      '50': '#ede7f6',
      '100': '#d1c4e9',
      '200': '#b39ddb',
      '300': '#9575cd',
      '400': '#7e57c2',
      '500': '#673ab7',
      '600': '#5e35b1',
      '700': '#512da8',
      '800': '#4527a0',
      '900': '#311b92',
    },
    grey: {
      '50': '#FCFCFC',
      '100': '#F7F9FA',
      '200': '#F2F4F4',
      '300': '#E1EDF1',
      '400': '#C8D4DA',
      '500': '#879499',
      '600': '#545D63',
      '700': '#343E45',
      '800': '#151616',
      '900': '#151616',
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans',
    fontSize: 16,
    h1: {
      fontSize: 64,
      fontWeight: 500,
    },
    h2: {
      fontSize: 48,
      fontWeight: 500,
    },
    h3: {
      fontSize: 36,
      fontWeight: 500,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '120%',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.07em',
    },
    overline: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: '0.042em',
      textTransform: 'uppercase',
    },
    button: undefined,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Campton';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Campton'), local('Campton-Regular'), url(${Campton}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          pRegular: 'p',
          pMedium: 'p',
          inputPlaceholder: 'span',
          inputLabel: 'span',
          inputHelpText: 'span',
          button: 'span',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: '14px',
          minWidth: '97px',
          height: '50px',
        },
        contained: {
          color: 'white',
          boxShadow: 'inset 0px -2px 0px rgb(0 0 0 / 20%)',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'medium',
        color: 'success',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        groupedContained: {
          boxShadow: 'inset 0px -2px 0px rgb(0 0 0 / 20%)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          textAlign: 'center',
        },
        tooltip: {
          fontSize: '12px',
          minWidth: '275px',
          textAlign: 'center',
          padding: '13px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.10)',
          borderRadius: '3px',
          position: 'relative',
          fontWeight: 400,
          fontFamily: 'Inter',
          margin: '0px',
          backgroundColor: 'white',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          letterSpacing: '0.02em',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // Disable ripple for jest tests
        disableRipple: !!process.env.JEST_WORKER_ID,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-MenuItem,&.Mui-selected,&.Mui-selected:hover': {
            background: theme.palette.action.selected,
          },
        }),
      },
    },
  },
  breakpoints: {
    values: {
      ...breakpoints.values,
      newExperience: 800,
    },
  },
  zIndex: {
    // In styles/global.ts: Intercom Widget = appBar - 100
  },
});

theme.palette.action.disabledBackground = theme.palette.grey[300];

export default theme;

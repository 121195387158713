import { redirect } from 'react-router-dom';
import { logout } from '../../core/login';
import ActionTypes from '../../core/ActionTypes';
export default {
  path: '/logout',
  async action({ store }) {
    logout();
    await store.dispatch({
      type: ActionTypes.USER__DELETE,
      id: store.getState().viewer.id,
    });
    await store.dispatch({
      type: ActionTypes.VIEWER__RESET,
    });
    return redirect('/login');
  },
};

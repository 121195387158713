import { gql } from '@apollo/client';
import ActionTypes from '../core/ActionTypes';

export function fetchBillingAccount(id) {
  const billingAccountQuery = gql`
    query getBillingAccount($id: String!) {
      billingAccount(id: $id) {
        id
        type
        entity_type
        seat_count
        state
        purchases {
          remaining {
            cents
            currency
          }
          expires_at
        }
        credit_balance {
          cents
          currency
        }
        transactions {
          id
          created_at
          description
          money {
            cents
            currency
          }
          credits {
            cents
            currency
          }
          tracking_code
          state
        }
      }
    }
  `;

  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.send(billingAccountQuery, { id });

    dispatch({
      type: ActionTypes.BILLING__FETCH_ACCOUNT,
      billingAccount: result.billingAccount,
    });
  };
}

export function createBillingAccountURL(id) {
  const billingGroupQuery = gql`
    query getGroup($id: String!) {
      group(id: $id) {
        billing_account {
          id
        }
      }
    }
  `;
  const createPortalURLMutation = gql`
    mutation createPortalURL($accountId: String!, $path: String!) {
      createPortalURL(account_id: $accountId, path: $path)
    }
  `;

  return async (dispatch, getState, { graphql }) => {
    let result = await graphql.send(billingGroupQuery, { id });
    const accountId = result.group.billing_account.id;

    result = await graphql.mutate(createPortalURLMutation, {
      accountId,
      path: `/team/${groupId}/settings`,
    });
    return result.createPortalURL;
  };
}

export function updateSource(groupId, token) {
  return async (dispatch, getState, { graphql }) => {
    if (!groupId) {
      throw new Error('Must specify group to update payment method.');
    }

    const response = await graphql.mutate(
      `
      (
        $groupId: String!
        $token: String!
      ) {
        updateSource(
          group_id: $groupId
          token: $token
        ) {
          id
          state
          transactions {
            id
            created_at
            description
            money { cents, currency }
            credits { cents, currency }
            charge_code
            state
          }
          default_card { brand, last4digits }
          credit_balance { cents, currency }
          credit_expiry
          credit_price {
            credits { cents, currency }
            money { cents, currency }
          }
          permissions
        }
      }
    `,
      { groupId, token }
    );

    if (!response || !response.updateSource) {
      throw new Error('Unable to update card information.');
    }

    dispatch({
      type: ActionTypes.BILLING__FETCH_ACCOUNT,
      billingAccount: response.updateSource,
    });

    return response.updateSource;
  };
}

export function purchaseCredits(accountId, credits, amount) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `
      (
        $accountId: String!
        $credits: MoneyInput!
        $amount: MoneyInput!
      ) {
        purchaseCredits(
          billing_account_id: $accountId
          credits: $credits
          amount: $amount
        ) {
          id
        }
      }
    `,
      { accountId, credits: credits.toGQL(), amount: amount.toGQL() }
    );
  };
}

const pricesQuery = gql`
  query getPrices($accountId: String) {
    prices(account_id: $accountId) {
      id
      credits {
        cents
        currency
      }
      money {
        cents
        currency
      }
    }
  }
`;

export function fetchPrices(accountId) {
  return async (dispatch, getState, { graphql }) => {
    const cachedPrices = getState().billing.prices;
    if (cachedPrices && cachedPrices.length) return cachedPrices;

    const { prices } = await graphql.send(pricesQuery, { accountId });

    dispatch({
      type: ActionTypes.BILLING__FETCH_PRICES,
      prices,
    });

    return prices;
  };
}

import React from 'react';
import Button from '../../components/Button';

function SaveForm({ children, saveLabel = 'Save', onConfirm, ...other }) {
  return (
    <form onSubmit={onConfirm} {...other}>
      {children}
      <Button style={{ marginTop: 40 }} type="submit" size="medium">
        {saveLabel}
      </Button>
    </form>
  );
}

export default SaveForm;

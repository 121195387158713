import { LOGO, ACCESS_ICON, PRODUCT_NAME } from 'config';
import { Link } from 'react-router-dom';
import APP_ROUTES from 'routes/APP_ROUTES';
import { mq } from 'tailwind/screens';
import { useMediaQuery } from 'usehooks-ts';

export const LogoImg = () => {
  const isMobile = useMediaQuery(mq.smd);

  return (
    <Link
      data-testid="of-logo-img"
      className="shrink-0"
      to={APP_ROUTES.dashboard}
    >
      <img
        src={isMobile ? ACCESS_ICON : LOGO}
        alt={PRODUCT_NAME}
        width={isMobile ? 34 : 142}
        height="auto"
      />
    </Link>
  );
};

export default LogoImg;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Divider from '../Divider';
import Picture from '../Picture';
import SelectGroup from '../SelectGroup';
import { setUserContext } from '../../actions/ui';
import { getUserOrGroup } from '../../core/user';
import { capitalize } from '../../core/util';
import { useNavigate } from 'react-router-dom';

const useMenuStyles = makeStyles(() => ({
  paper: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

export function UserContextMenu({ open, anchorEl, onClose, ...other }) {
  const s = useMenuStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      classes={s}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItems {...other} divider={<Divider spacing={0} />} />
    </Popover>
  );
}

export class MobileUserContextMenu extends PureComponent {
  render() {
    return (
      <MenuItems
        {...this.props}
        divider={<Divider spacing={20} margin={16} />}
        fontSize={20}
      />
    );
  }
}

const useItemStyles = makeStyles(() => ({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

function MenuItems({
  setUserContext,
  onChange,
  viewer,
  userContextOptions,
  fontSize,
}) {
  const navigate = useNavigate();
  const s = useItemStyles();

  const handleChange = (userContext) => {
    setUserContext(userContext);
    if (
      userContext === 'expert' &&
      window.location.pathname === '/expert_requests'
    ) {
      navigate('/dashboard');
    } else {
      navigate(window.location);
    }
    if (onChange) onChange(userContext);
  };

  const handleGroupContext = (value) => {
    if (!value) return;
    handleChange(value);
  };

  return (
    <>
      {userContextOptions.map((o) => {
        const user = getUserOrGroup(o.value, viewer) || o;

        return (
          <MenuItem
            key={o.value}
            value={o.value}
            style={{ fontSize }}
            classes={s}
            id={`user${capitalize(o.value)}Selector`}
            onClick={() => handleChange(o.value)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Picture
                size={40}
                style={{ marginRight: 10 }}
                user={user}
                link={false}
              />
              {o.name}
              {o.description && (
                <span
                  style={{
                    marginLeft: 5,
                  }}
                >
                  ({o.description})
                </span>
              )}
            </div>
          </MenuItem>
        );
      })}
      {viewer.admin && (
        <SelectGroup
          autoComplete
          autoFocus
          onChange={handleGroupContext}
          margin={0}
        />
      )}
    </>
  );
}

MenuItems = connect(
  (state) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    userContextOptions: state.ui.userContextOptions,
  }),
  {
    setUserContext,
  }
)(MenuItems);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Checkbox } from '../FormAdapters';
import Form from '../Form';
import { updateUser } from '../../actions/user';

class AvailableSelfServiceForm extends PureComponent {
  handleSubmit = async (values) => {
    const { userId, updateUser, onSubmit } = this.props;
    await updateUser({ id: userId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const { component: Container = Form, handleSubmit, ...other } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          type="checkbox"
          component={Checkbox}
          name="available_self_service"
          label="Available for self service consultations"
        />
      </Container>
    );
  }
}

AvailableSelfServiceForm = reduxForm({
  form: 'expertAvailableSelfService',
})(AvailableSelfServiceForm);

AvailableSelfServiceForm = connect(
  (state, ownProps) => {
    const user = state.users[ownProps.userId] || {};
    return {
      initialValues: {
        available_self_service: user.available_self_service,
      },
    };
  },
  {
    updateUser,
  }
)(AvailableSelfServiceForm);

export default AvailableSelfServiceForm;
